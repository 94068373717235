import {
  ProposalDetailsForm,
  CustomerDetail,
  AddressDetails,
  ProposalDetailsView,
} from "@components/proposal/proposal-details";
import { useGetClientTypeName } from "@hooks";
import { DirectorsDetails } from "./customer-details/director-details/director-details.component";
import { ClientTypes } from "@helpers/enum";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { AddressViewDetail } from "./proposal-details-view/address-view-detail.component";

export const ProposalDetails = () => {
  const { watch } = useFormContext<TProposalForm>();
  const isViewMode = watch("isViewMode");
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);
  const showDirectorDetails = clientTypeName !== ClientTypes.INDIVIDUAL;

  return isViewMode ? (
    <>
      <ProposalDetailsView />
      {showDirectorDetails && <DirectorsDetails />}
      <AddressViewDetail />
    </>
  ) : (
    <>
      <ProposalDetailsForm />
      <CustomerDetail />
      <AddressDetails />
    </>
  );
};
