import { useTheme } from "@mui/material";
import { SettingDialogContent } from "./setting-dialog.style";
import { Box, Button, Dialog, Select, Typography } from "@ntpkunity/controls";
import { Controller, useForm } from "react-hook-form";
import { SORTED_PERMISSIONS_NAMES } from "@helpers/const";
import { useUpdateUmsPermissions } from "@hooks/mutations";
import { useEffect } from "react";
import { Stack } from "@components/stack";
import { useSnackbarContext } from "@contexts/snackbar";
import { useReloadAllPermissions } from "@ntpkunity/controls-ums";
import { getUserSettings } from "@helpers/utils";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { useUmsPermissionsMap, useUmsGroups } from "@hooks/finance-config";
import { getPermissionKey as getRolePermissionKey } from "@hooks";
import { FieldPermissions, getPermissionKey } from "@ntpkunity/controls-common";
import { PermissionsForm } from "@_types";
import { PermissionsRow } from "./permissions-row/permissions-row.component";

export const SettingsDialog = ({ open, onClose, financeType }) => {
  const theme = useTheme();
  const { setSnackbar } = useSnackbarContext();
  const userRoleName = getUserSettings()?.role?.name;
  const queryClient = useQueryClient();
  const permissionsMap = useUmsPermissionsMap();
  const umsRoles = useUmsGroups();

  const formMethods = useForm<PermissionsForm>({
    defaultValues: {
      selectedRoleId: umsRoles.find((role) => role.groupName === userRoleName)
        .groupId,
      permissions: permissionsMap,
    },
  });
  const { control, watch, getValues, reset } = formMethods;

  const selectedRoleId = watch("selectedRoleId");

  useEffect(() => {
    reset({ selectedRoleId, permissions: getValues("permissions") });
  }, [selectedRoleId]);

  const handleSaveSuccess = async () => {
    setSnackbar(
      {
        message: "Settings successfully saved!",
        open: true,
        variant: null,
      },
      10000
    );
    onClose();
    queryClient.invalidateQueries([QUERY_KEYS.GET_PRODUCT_ID]);
    await reloadPermissions();
  };

  const handleSaveError = () => {
    setSnackbar(
      {
        message: "Error occurred while saving settings!",
        open: true,
        variant: "error",
      },
      10000
    );
    onClose();
  };

  const reloadPermissions = useReloadAllPermissions();
  const { mutate: permissionsConfiguration, isLoading: savingPermissions } =
    useUpdateUmsPermissions({
      onSuccess: handleSaveSuccess,
      onError: handleSaveError,
    });

  const handleSubmit = () => {
    const groupId = getValues(`selectedRoleId`);
    const permissions = getValues(
      `permissions.${getRolePermissionKey(financeType, groupId)}`
    );
    const payload = Object.values(permissions)?.map((permission) => ({
      id: permission?.id,
      name: permission?.name,
      default_value: permission?.default_value,
      is_view: permission?.is_view,
      is_enabled: permission?.is_enabled,
      is_mandatory: permission?.is_mandatory,
    }));
    permissionsConfiguration({ payload, groupId: groupId });
  };

  return (
    <Dialog
      theme={theme}
      variant="full-window"
      onCloseDialog={onClose}
      open={open}
      customFooter={
        <Button
          theme={theme}
          primary
          text="Save Changes"
          className="w-m-100"
          disabled={savingPermissions}
          onClick={() => {
            handleSubmit();
          }}
          type="button"
        />
      }
    >
      <SettingDialogContent theme={theme} className="settings-dialog-content">
        <Box theme={theme} className="dialog-head-wrap">
          <Typography theme={theme} variant="h3" component={"h3"}>
            Select Group
          </Typography>
          <Box theme={theme} className="action-area">
            <Box className="dropown-item" theme={theme}>
              <Typography
                variant="body2"
                component={"span"}
                display={"block"}
                className="text-muted"
                theme={theme}
              >
                Roles:
              </Typography>
              <Controller
                name={"selectedRoleId"}
                control={control}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    fullWidth
                    disablePortal={false}
                    placeholder="Select an option..."
                    items={umsRoles.map((role) => ({
                      text: role.groupName,
                      value: role.groupId,
                    }))}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Stack paddingXs={3} paddingMd={3} bgLight>
          <Stack paddingXs={3} paddingMd={3}>
            {SORTED_PERMISSIONS_NAMES?.map((permName, index) => {
              const permKey = getPermissionKey(
                financeType,
                permName as FieldPermissions
              );
              const permission = getValues(
                `permissions.${getRolePermissionKey(
                  financeType,
                  selectedRoleId
                )}`
              )?.[permKey];
              if (!permission) return undefined;
              return (
                <PermissionsRow
                  key={`permission-row-${index}`}
                  formMethods={formMethods}
                  financeType={financeType}
                  permissionName={permName}
                />
              );
            }).filter(Boolean)}
          </Stack>
        </Stack>
      </SettingDialogContent>
    </Dialog>
  );
};
