import {
  EXECUTE_WORKFLOW,
  GET_WORKFLOW_CURRENT_TASK_BY_RUNNING_ID,
} from "@helpers/endpoints";
import { workflowApi } from "./instances";
import { WorkflowTask } from "@_types";

export const executeWorkflow = async (slug: string): Promise<WorkflowTask> => {
  return workflowApi
    .post(EXECUTE_WORKFLOW.replace(":slug", slug))
    .then((response) => response.data);
};

export const getWorkflowTaskByRunningId = async (
  runningId: number
): Promise<WorkflowTask> => {
  return workflowApi
    .get(
      GET_WORKFLOW_CURRENT_TASK_BY_RUNNING_ID.replace(
        ":runningId",
        runningId.toString()
      )
    )
    .then((response) => response.data);
};
