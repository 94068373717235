import { useTheme } from "@mui/material";
import {
  Button,
  Dialog,
  Grid,
  Input,
  Textarea,
  Typography,
} from "@ntpkunity/controls";
import { DownloadQuoteWrap } from "./download-quote-dialog.style";
import { useForm, Controller } from "react-hook-form";
import {
  DownloadQuoteAdditionalParams,
  QuotationDownloadDialogData,
} from "@_types";
import { ClientTypes, TableRowOptions } from "@helpers/enum";

type DownloadQuotationDialogProps = {
  downloadQuotation: (
    params: DownloadQuoteAdditionalParams,
    identifier?: string
  ) => void;
  dialogOpen: boolean;
  closeDialog: () => void;
  dialogData: QuotationDownloadDialogData;
  btnDisabled?: boolean;
};

export const DownloadQuotationDialog = ({
  downloadQuotation,
  dialogOpen,
  closeDialog,
  dialogData,
  btnDisabled = false,
}: DownloadQuotationDialogProps) => {
  const { control, getValues } = useForm<DownloadQuoteAdditionalParams>();

  const variantText =
    dialogData.rowOptions === TableRowOptions.DOWNLOAD ? "Download" : "Email";

  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      title={`${variantText} Quotation`}
      size="sm"
      open={dialogOpen}
      onClose={closeDialog}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={closeDialog}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text={variantText}
            disabled={btnDisabled}
            onClick={() => {
              downloadQuotation(getValues(), dialogData.identifier);
            }}
          />
        </>
      }
    >
      <DownloadQuoteWrap theme={theme} className="download-quote-wrap">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12}>
            <Typography theme={theme} component={"p"} variant="subtitle1">
              Details
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder="Type here..."
                    label="Customer Email"
                    type="text"
                    dynamic={false}
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    placeholder="Type here..."
                    label="Customer Full Name"
                    type="text"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="idNumber"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    placeholder="Type here..."
                    label={
                      dialogData.clientType === ClientTypes.INDIVIDUAL
                        ? "Id Number"
                        : "Company Registration Number"
                    }
                    type="text"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="lenderName"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    placeholder="Type here..."
                    label="Lender Name"
                    type="text"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="comments"
              render={({ field }) => {
                return (
                  <Textarea
                    theme={theme}
                    placeholder="Type here..."
                    label="Comments"
                    fullWidth
                    type="text"
                    id="textAreaId"
                    rows={3}
                    {...field}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </DownloadQuoteWrap>
    </Dialog>
  );
};
