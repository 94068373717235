import { Typography, Box, useTheme } from "@mui/material";
import { QuotationFiltersWrap } from "./new-quotation-header.style";
import { BrokerSpecifcFormFields } from "@_types";
import { Select } from "@ntpkunity/controls";
import { getSelectValues, removeDeletedEntities } from "@helpers/utils";
import { QuotationFormController } from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";
import { useAssetSetups, useSetupsSelector } from "@hooks";

export const NewQuotationFiltersHeader = () => {
  const theme = useTheme();
  const { control } = useFormContext<BrokerSpecifcFormFields>();
  const { concatenations } = useAssetSetups();

  const clientTypes = useSetupsSelector((state) => state.clientTypes);

  return (
    <QuotationFiltersWrap theme={theme} className="quotation-filters-wrap">
      <Box className="filters-area">
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <QuotationFormController
            name="asset.type"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={getSelectValues(concatenations)}
                {...field}
              />
            )}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <QuotationFormController
            name="clientType"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={removeDeletedEntities(undefined, clientTypes.data)?.map(
                  (ct) => ({
                    text: ct.description,
                    value: ct.code,
                  })
                )}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </QuotationFiltersWrap>
  );
};
