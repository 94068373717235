import { useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";
import { useSetupsSelector } from "@hooks";

export const CustomerTypeCell = ({
  customerTypeCode,
}: {
  customerTypeCode: string;
}) => {
  const theme = useTheme();

  const { isLoading: customerTypesLoading, data: customerTypes } =
    useSetupsSelector((state) => state.clientTypes);

  if (customerTypesLoading) {
    return <Skeleton theme={theme} height={14} />;
  }

  const customerTypeName =
    customerTypes.find((ft) => ft.code === customerTypeCode)?.description || "";

  return <>{customerTypeName}</>;
};
