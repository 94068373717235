import { useTheme } from "@mui/material";
import { AssetSelectionControl, Vehicle } from "@ntpkunity/controls-common";
import { useGetCompanyByTenantId, useGetCurrency, useGetDealers } from "@hooks";
import { Box, Dialog } from "@ntpkunity/controls";
import { getUserSettings } from "@helpers/utils";
import {
  FullHeightDialogWrap,
  InventoryControlWrap,
} from "./choose-asset-dialog.style";

const ChooseAssetDialog = ({
  open,
  setOpen,
  close,
  onListViewClick,
}: {
  open: boolean;
  setOpen: () => void;
  close: () => void;
  onListViewClick: (vehicle: Vehicle) => void;
}) => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: currency } = useGetCurrency();
  const { data: dealer } = useGetDealers();

  const theme = useTheme();
  return (
    <FullHeightDialogWrap theme={theme} className="full-height-dialog-wrap">
      <Dialog
        variant="full-window"
        theme={theme}
        onCloseDialog={() => {
          close();
        }}
        open={open}
        disablePortal
        noFooter
      >
        <InventoryControlWrap theme={theme} className="inventory-control-wrap">
          <AssetSelectionControl
            theme={theme}
            dealerCode={dealer?.dealer_code}
            defaultCurrency={currency?.symbol}
            onClick={() => {}}
            onListViewClick={onListViewClick}
            company_Id={companyInfo?.id}
            accessToken={getUserSettings()?.access_token}
            hideLocation={true}
          />
        </InventoryControlWrap>
      </Dialog>
    </FullHeightDialogWrap>
  );
};

export default ChooseAssetDialog;
