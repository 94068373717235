import { GET_UMS_PERMISSIONS, UPDATE_PERMISSIONS } from "@helpers/endpoints";
import userApi from "./instances/ums";
import { UmsPermission } from "@_types/umsPermissions";
import { SaveUmsPermissionsVariables } from "@_types/finance-config";

export const getUmsPermissions = async (product_id: number) => {
  return await userApi
    .get<UmsPermission[]>(`${GET_UMS_PERMISSIONS}/${product_id}`)
    .then((res) => res.data);
};

export const updateUmsPermissions = async (
  body: SaveUmsPermissionsVariables
) => {
  const { groupId, payload } = body;
  return userApi.patch(`${UPDATE_PERMISSIONS}?group_id=${groupId}`, payload);
};
