import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  FilePreview,
  ImageViewer,
  FileDragDrop,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { TInputDef } from "@components/proposal/proposal-details/types";
import { ALLOWED_FILE_TYPES } from "@helpers/const";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import { DialogContentContainer } from "./document-form-dialog.style";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DocumentFormDialogProps } from "./document-form-dialog.props";
import { DocumentType } from "@_types";
import { useSnackbarContext } from "@contexts/snackbar";
import { usePreviewDocs } from "@hooks/mutations/useDownloadDocs";
import { useUploadDocs } from "@hooks/mutations/useUploadDoc";
import { DocumentTypes } from "@helpers/enum";

const isDragEvent = (
  event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>
): event is React.DragEvent<HTMLElement> => {
  return (event as React.DragEvent<HTMLElement>).dataTransfer !== undefined;
};

const getInputDefs = (documentType: string): TInputDef[] => {
  const items = [{ text: documentType, value: documentType }];
  return [
    {
      name: "name",
      formElement: "Input",
      placeHolder: "Document Name",
      label: "Document Name",
      type: "text",
    },
    {
      name: "type",
      formElement: "Select",
      label: "Document Type",
      items: items,
      defaultValue: items[0].value,
    },
  ];
};

const DocumentFormDialog = forwardRef(
  (props: DocumentFormDialogProps, ref: React.ForwardedRef<DocumentType[]>) => {
    const {
      openDialog,
      closeDialog,
      handleSaveDocument,
      isEditMode,
      documentType,
    } = props;
    const theme = useTheme();
    const inputDefs = getInputDefs(documentType);
    const [fileDetails, setFileDetails] = useState<DocumentType[]>(
      isEditMode ? props.filesPayload : []
    );
    const [uploadDocumentInProgress, setUploadDocumentInProgress] =
      useState(false);
    const { mutateAsync: uploadDoc } = useUploadDocs();
    const { mutateAsync: previewURL } = usePreviewDocs();
    const [imageURL, setImageURL] = useState([]);
    const [index, setIndex] = useState(0);
    const { setSnackbar } = useSnackbarContext();
    const [fileUploadError, setFileUploadError] = useState(null);

    useImperativeHandle(ref, () => {
      return fileDetails;
    });

    const removeFile = (key: string) => {
      setFileDetails(fileDetails.filter((file) => file.key !== key));
    };

    const getFilePreviewArray = () => {
      if (!fileDetails?.length) {
        return [];
      } else {
        return fileDetails
          .map((fileDetail) => {
            if (!fileDetail || !fileDetail.file) {
              return null;
            }

            const file = fileDetail.file;
            return {
              key: fileDetail.key,
              name: fileDetail.key,
              size: file.size,
              file: {
                location: fileDetail.previewUrl,
                size: file?.size,
                name: fileDetail.key,
                type: file?.type,
              },
              viewIcon: true,
              preview: "fileIcon",
              isUploading: false,
              error: false,
              uploadingLabel: "Uploading",
              uploadingProgress: 0,
              onRemoveFile: removeFile,
            };
          })
          .filter((filePreview) => filePreview !== null);
      }
    };
    const viewFile = (indexNumber) => {
      if (fileDetails[indexNumber]?.key.includes(".pdf")) {
        previewURL(fileDetails[indexNumber]?.key).then((response) => {
          window.open(response, "_blank");
        });
      } else {
        fileDetails.map((fileDetail) => {
          if (!fileDetail.key.includes(".pdf")) {
            previewURL(fileDetail.key).then((response) => {
              fileDetail.previewUrl = response;
              setImageURL((prevItems) => [...prevItems, response]);
            });
          }
        });

        setIndex(indexNumber);
      }
    };

    const handleClose = () => setImageURL([]);

    const getBase64 = (file: File) => {
      return new Promise<string>((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const result: string = reader.result as string;
          const base64Data = result.split(",")[1]; // Remove the data URL scheme
          resolve(base64Data);
        };
        reader.onerror = (error) => reject(error);
      });
    };

    const handleFileChange = async (
      e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>
    ) => {
      setUploadDocumentInProgress(true);
      setFileUploadError(null);
      let files: File[];
      if (isDragEvent(e)) {
        files = (e.dataTransfer.files || []) as File[];
      } else {
        files = (e.target.files || []) as File[];
      }

      if (files.length) {
        const newFiles = await Promise.all(
          [...files].map(async (file): Promise<DocumentType> => {
            if (
              ALLOWED_FILE_TYPES[DocumentTypes.CREDIT_DOCUMENTS].filter(
                (type) => type == file.type
              ).length > 0
            ) {
              const base64 = await getBase64(file);
              const fileNameParts = file.name.split(".");
              const fileExtension = fileNameParts.pop(); // Get the extension (e.g., 'png')
              const baseFileName = fileNameParts.join("."); // Get the base name without the extension

              const newFileName = `${baseFileName}_${crypto.randomUUID()}.${fileExtension}`;

              const payload = {
                tags: {},
                file_name: newFileName,
                file_data: base64,
                content_type: file.type,
                source: "mobility",
                source_id: crypto.randomUUID(),
              };
              try {
                const response = await uploadDoc(payload);
                return {
                  file: { ...file, size: file.size, name: response.key },
                  base64: null,
                  previewUrl: response.generated_document_url,
                  key: response.key,
                };
              } catch {
                setFileUploadError("Error Occurred while uploading document");
              }
            } else {
              setFileUploadError(
                "Invalid type. Only jpg, jpeg, png and pdf file formats are allowed"
              );
            }
          })
        );
        setUploadDocumentInProgress(false);
        setFileDetails([
          ...fileDetails,
          ...(newFiles.filter((file): file is DocumentType => !!file) || []),
        ]);

        setUploadDocumentInProgress(false);
      }
    };

    const _closeDialog = () => {
      setFileDetails([]);
      closeDialog();
    };

    const _handleSaveDocument = () => {
      handleSaveDocument();
      _closeDialog();
      setSnackbar(
        { open: true, message: "Document Uploaded Successfully" },
        2000
      );
    };

    return (
      <Dialog
        theme={theme}
        size={"sm"}
        open={openDialog}
        onCloseDialog={_closeDialog}
        title={`${isEditMode ? "Upload" : "Add New"} Document`}
        customFooter={
          <>
            <Button
              theme={theme}
              secondary
              text="Cancel"
              type="button"
              onClick={_closeDialog}
            />
            <Button
              theme={theme}
              primary
              disabled={uploadDocumentInProgress}
              text="Save Document"
              type="button"
              onClick={_handleSaveDocument}
            />
          </>
        }
      >
        <DialogContentContainer
          theme={theme}
          className="dialog-content-container"
        >
          <Grid theme={theme} container item spacing={3}>
            {inputDefs.map((item, index) => (
              <Grid theme={theme} item xs={12} key={`${index} - ${item.name}`}>
                <ControlledFormElement {...item} />
              </Grid>
            ))}
            <Grid theme={theme} item xs={12}>
              <FileDragDrop
                theme={theme}
                isLoading={uploadDocumentInProgress}
                onChange={handleFileChange}
                onDrop={handleFileChange}
                hoverLabel={
                  <>
                    Drag and drop file, or{" "}
                    <span className="text-primary">browse</span>
                  </>
                }
                allowMultiple
                error={!!fileUploadError}
              />
              {fileUploadError && (
                <Box theme={theme} mt={2}>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    display={"block"}
                    className="text-danger"
                    textAlign={"left"}
                  >
                    {fileUploadError}
                  </Typography>
                </Box>
              )}
              <FilePreview
                theme={theme}
                files={getFilePreviewArray()}
                onRemoveFile={removeFile}
                preview={"fileIcon"}
                viewIcon={true}
                onClickIcon={viewFile}
              />
              <Box theme={theme} mt={2}>
                <Typography
                  theme={theme}
                  variant="caption"
                  component={"small"}
                  display={"block"}
                  className="text-muted"
                  textAlign={"center"}
                >
                  You can upload multiple files. File can be up to 5MB. You can
                  upload jpg, jpeg, png and pdf file formats.
                </Typography>
              </Box>
            </Grid>

            <ImageViewer
              theme={theme}
              sliderImagesWithVisible={true}
              sliderImages={imageURL}
              isLoading={false}
              visible={imageURL.length > 0}
              onClose={handleClose}
              overlayCheck={true}
              index={index}
              setIndex={setIndex}
            />
          </Grid>
        </DialogContentContainer>
      </Dialog>
    );
  }
);

export default DocumentFormDialog;
