import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Grid, Typography } from "@ntpkunity/controls";
import { AddressViewDetailsList } from "./address-view-detail-list.component";

export const AddressViewDetail = () => {
  const theme = useTheme();

  return (
    <Grid theme={theme} container item spacing={3} alignItems={"stretch"}>
      <Grid theme={theme} item xs={12}>
        <Stack
          paddingXs={3}
          paddingMd={3}
          marginTopMd={2}
          marginTopXs={2}
          className="h-100"
        >
          <Typography
            theme={theme}
            component="h4"
            variant="h4"
            children="Address"
            sx={{ mb: 2 }}
          />
          <AddressViewDetailsList />
        </Stack>
      </Grid>
    </Grid>
  );
};
