import { CSVLink } from "react-csv";
import { Box, Button } from "@ntpkunity/controls";
import {
  QUEUE_TO_STATUS_MAP,
  WORK_QUEUE_EXPORT_HEADERS,
  WORK_QUEUE_FILE_NAME,
} from "@helpers/const";
import { processProposalExportData } from "@helpers/utils";

import { useTheme } from "@mui/material";
import { useGetOrders, useSetupsSelector } from "@hooks";
import {
  TProposalSearchParams,
  TProposalWorkQueueEntry,
  TSortParams,
} from "@_types";
import { OrderStage, SortOrder, WorkQueueTabs } from "@helpers/enum";

const sortInitialState: TSortParams<keyof TProposalSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

const searchParams: TProposalSearchParams = {
  name: "",
  finance_type: "",
  finance_amount: null,
  updated_at: "",
  order_stage: OrderStage.APPLICATION,
  customer_name: "",
  date_submitted: "",
  start_date: "",
  end_date: "",
  introducer_name: "",
  lender_name: "",
  dealer_name: "",
  updated_at_from: "",
  updated_at_to: "",
  statuses: QUEUE_TO_STATUS_MAP[WorkQueueTabs.TRACK_PROPOSALS],
  fuzzy_search: "", // finance_type, status, name, customer_name
  fuzzy_finance_types: [],
};

export const WorkQueueExport = () => {
  const theme = useTheme();

  const financeTypes = useSetupsSelector((setups) => setups.financeTypes.data);
  const {
    isLoading,
    isIdle,
    data: exportData,
  } = useGetOrders<TProposalSearchParams, TProposalWorkQueueEntry>(
    searchParams,
    sortInitialState,
    true
  );

  return (
    <Box theme={theme}>
      <CSVLink
        onClick={(event) => {
          if (isLoading || isIdle || exportData?.results?.length === 0) {
            event.preventDefault();
          }
        }}
        data={processProposalExportData(exportData?.results, financeTypes)}
        headers={WORK_QUEUE_EXPORT_HEADERS}
        filename={WORK_QUEUE_FILE_NAME}
        target="_blank"
      >
        <Button
          disabled={isLoading || isIdle || exportData?.results?.length === 0}
          theme={theme}
          type="button"
          primary
          text="Export Proposals"
        />
      </CSVLink>
    </Box>
  );
};
