import React, { useState } from "react";
import {
  Footer,
  LayoutWithSideNavComponent,
  StepperProgress,
  ComplianceDocument,
  ComplianceAddSignatories,
  ComplianceDocumentPageHeader,
  Snackbar,
  ComplianceDocumentGenerated,
} from "@components";
import { useNavigate } from "react-router-dom";
import { DownloadDocument, OrderStatus, SortOrder } from "@helpers/enum";
import { useTheme } from "@mui/material";
import ComplianceDocumentPageWrap from "./compliance-document-page.style";
import { Button, Icon, PageAlert } from "@ntpkunity/controls";
import { useParams } from "react-router-dom";
import {
  useGetProposal,
  useGetCompanyByTenantId,
  GetProposalData,
  useGetGeneratedDocuments,
  useGetSignatories,
  useGetAllLenders,
  useGetComplianceDocuments,
  useGenerateDocuments,
  useSetupsSelector,
  useGetClientTypeName,
} from "@hooks";
import { useForm, FormProvider } from "react-hook-form";
import { getDownloadDocumentPayload } from "@helpers/utils";
import {
  TSortParams,
  SignatoryResponse,
  TSignatoriesSearchParams,
  Template,
  TSignatoryForm,
} from "@_types";
import { useSnackbarContext } from "@contexts/snackbar";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";

const sortInitialState: TSortParams<keyof TSignatoriesSearchParams> = {
  sort_by: "role",
  sort_order: SortOrder.DESC,
};

type FooterProps = {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  proposal: GetProposalData;
  stepItems: { title: string }[];
  signatories: SignatoryResponse[];
  complianceDocuments: { templates: Template[] }[];
  setGeneratedDocumentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setDocumentsGenerated: React.Dispatch<React.SetStateAction<boolean>>;
};

const ComplianceDocumentPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams<{ proposalId?: string }>();
  const {
    data: proposal,
    isError,
    isSuccess,
  } = useGetProposal(params.proposalId);
  const financeTypes = useSetupsSelector((state) => state.financeTypes.data);
  const selectedFinanceType = financeTypes?.find(
    (type) => type.code === proposal?.proposal?.proposalDetails?.financeType
  );
  const clientType = useGetClientTypeName(
    proposal?.proposal?.customerDetails?.clientType
  );
  const { data: complianceDocuments } = useGetComplianceDocuments(
    clientType,
    selectedFinanceType?.name
  );
  const [searchParams, setSearchParams] = useState<TSignatoriesSearchParams>({
    order_identifier: params.proposalId,
    name: "",
    email: "",
    role: "Signatory",
    position: "",
  });
  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TSignatoriesSearchParams>>(sortInitialState);

  const [generatedDocumentSuccess, setGeneratedDocumentSuccess] =
    useState(false);
  const [documentsGenerated, setDocumentsGenerated] = useState(false);

  const { data: signatories } = useGetSignatories<TSignatoriesSearchParams>(
    searchParams,
    sortParams
  );
  const { data: generatedDocuments, isLoading } = useGetGeneratedDocuments(
    proposal?.proposal?.customerDetails?.identifier,
    params.proposalId
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const formMethods = useForm<TSignatoryForm>();

  const handlePrevButtonClick = () => {
    setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const stepItems = [
    { title: "Compliance Documents" },
    { title: "Add Signatories" },
  ];

  const children = [
    <ComplianceDocument
      complianceDocuments={complianceDocuments}
      customerDetails={proposal}
      key="compliance-document"
    />, // Content for step 1
    <ComplianceAddSignatories
      complianceDocuments={complianceDocuments}
      signatories={signatories}
      key="compliance-add-signatories"
    />, // Content for step 2
  ];

  if (isError) {
    return (
      <PageAlert
        theme={theme}
        icon="DialogAlertIcon"
        title="Oops! Unexpected Error occurred."
        variant="full-page"
        message="We encountered an unexpected error. Try refreshing the page."
        actionArea={
          <>
            <Button
              theme={theme}
              fullWidth
              secondary
              startIcon={<Icon name="IconLeftArrow" />}
              text="Go Back"
              onClick={() => navigate(-1)}
            />
            <Button
              theme={theme}
              fullWidth
              danger
              startIcon={<Icon name="ResetIcon" />}
              text="Refresh Page"
              onClick={() => window.location.reload()}
            />
          </>
        }
      />
    );
  }

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ComplianceDocumentPageWrap theme={theme} className="page-wrap">
        <FormProvider {...formMethods}>
          {isSuccess && !isLoading && (
            <>
              <ComplianceDocumentPageHeader
                proposalName={proposal?.proposal?.proposalDetails?.name}
              />
              <Snackbar />
              {generatedDocuments?.length > 0 ||
              documentsGenerated ||
              proposal.proposal.status === OrderStatus.AWAITING_COMPLIANCE ? (
                <ComplianceDocumentGenerated
                  generatedDocuments={generatedDocuments}
                  complianceDocuments={complianceDocuments}
                  signatories={signatories}
                  generatedDocumentSuccess={generatedDocumentSuccess}
                  documentsGenerated={documentsGenerated}
                  proposal={proposal}
                />
              ) : (
                <>
                  <StepperProgress
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    onPrevButtonClick={handlePrevButtonClick}
                    stepItems={stepItems}
                    stepTitle={stepItems[activeIndex]?.title}
                    nextStepTitle={
                      activeIndex < stepItems.length - 1
                        ? stepItems[activeIndex + 1]?.title
                        : null
                    }
                    actionArea={
                      <>
                        <Button
                          defaultBtn
                          theme={theme}
                          onClick={() => {}}
                          iconText={<Icon name="SaveDiskIcon" />}
                        />
                      </>
                    }
                  >
                    {children}
                  </StepperProgress>
                  <FooterWrapper
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    proposal={proposal}
                    stepItems={stepItems}
                    signatories={signatories}
                    complianceDocuments={complianceDocuments}
                    setGeneratedDocumentSuccess={setGeneratedDocumentSuccess}
                    setDocumentsGenerated={setDocumentsGenerated}
                  />
                </>
              )}
            </>
          )}
        </FormProvider>
      </ComplianceDocumentPageWrap>
    </LayoutWithSideNavComponent>
  );
};

const FooterWrapper = ({
  activeIndex,
  setActiveIndex,
  proposal,
  stepItems,
  signatories,
  complianceDocuments,
  setGeneratedDocumentSuccess,
  setDocumentsGenerated,
}: FooterProps) => {
  const customerType = useGetClientTypeName(
    proposal?.proposal?.customerDetails?.clientType
  );
  const { data: financeTypes } = useSetupsSelector(
    (state) => state.financeTypes
  );
  const financeType = financeTypes?.find(
    (type) => type.code === proposal?.proposal?.proposalDetails?.financeType
  )?.name;

  const { mutateAsync: generateDocuments } = useGenerateDocuments();
  const { setSnackbar } = useSnackbarContext();
  const navigate = useNavigate();
  const params = useParams<{ proposalId?: string }>();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const queryClient = useQueryClient();

  const setups = useSetupsSelector((state) => state);
  const { data: lenders } = useGetAllLenders();
  const signatoriesCount = complianceDocuments?.flatMap((item) =>
    signatories?.filter((signatory) =>
      item.templates.some(
        (template: Template) =>
          signatory?.stakeholder_reference_id?.includes(
            template.template_name
          ) && signatory.order_identifier === params?.proposalId
      )
    )
  )?.length;

  const getActionButtonText = () => {
    switch (activeIndex) {
      case 0:
        return "Next: Add Signatories";
      case 1:
        return "Generate Documents";
      default:
        return "Next";
    }
  };

  // Function to handle the back button click
  const handleBackButtonClick = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1); // Move to previous step
    } else {
      navigate(-1);
    }
  };

  const handleGenerateDocuments = async () => {
    const data = getDownloadDocumentPayload({
      documentType: DownloadDocument.PROPOSAL,
      financialCalculations: proposal.quotation.calculations,
      financialValues: proposal.quotation.formValues,
      proposalValues: proposal.proposal,
      setups: {
        financeTypes: setups.financeTypes.data,
        productTypes: setups.productTypes.data,
        clientTypes: setups.clientTypes.data,
        assetSubTypes: setups.assetSubTypes.data,
        assetCategories: setups.assetCategories.data,
        assetTypes: setups.assetTypes.data,
        assetConditions: setups.assetConditions.data,
        lenders: lenders,
      },
      companyId: companyInfo.id,
    });
    const payload = {
      company_id: companyInfo.id,
      documents: complianceDocuments.flatMap((item) =>
        item.templates.map((template: Template) => ({
          data: {
            ...data,
            reference_number: params?.proposalId,
            customer_reference_id:
              proposal?.proposal?.customerDetails?.identifier,
          },
          template: template?.template_name,
          signatories: signatories
            ?.filter(
              (signatoryFilter) =>
                signatoryFilter.stakeholder_reference_id.includes(
                  template?.template_name
                ) && signatoryFilter.order_identifier === params?.proposalId
            )
            .map((signatory) => signatory.email),
        }))
      ),
    };
    try {
      if (signatoriesCount > 0) {
        setDocumentsGenerated(true);
        await generateDocuments({ payload, customerType, financeType });
        queryClient.invalidateQueries([
          QUERY_KEYS.GET_GENERATED_DOCUMENTS,
          proposal.proposal.customerDetails.identifier,
        ]);
        setGeneratedDocumentSuccess(true);
      } else {
        setSnackbar(
          {
            message:
              "Please add at least one signatory before generating the document.",
            open: true,
            variant: "error",
          },
          10000
        );
      }
    } catch (err) {
      setSnackbar(
        {
          message: "Error occurred while Generating Document",
          open: true,
          variant: "error",
        },
        10000
      );
    }
  };

  const handleActionButtonClick = () => {
    if (activeIndex < stepItems.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      handleGenerateDocuments();
    }
  };

  return (
    <Footer
      actionButtonText={getActionButtonText()}
      actionButtonOnClick={() => handleActionButtonClick()}
      showBackButton
      backButtonOnClick={handleBackButtonClick}
    />
  );
};

export default ComplianceDocumentPage;
