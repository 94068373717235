import {
  ProposalDetailsForm,
  CustomerDetail,
  AddressDetails,
  ProposalDetailsView,
} from "@components/proposal/proposal-details";
import { useGetClientTypeName, useGetCurrency } from "@hooks";
import { DirectorsDetails } from "./customer-details/director-details/director-details.component";
import { ClientTypes } from "@helpers/enum";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { AddressViewDetail } from "./proposal-details-view/address-view-detail.component";
import { useEffect } from "react";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";

export const ProposalDetails = () => {
  const { watch } = useFormContext<TProposalForm>();
  const { watch: quotationWatch, setValue: quotationSetValue } =
    useQuotationFormContext<QuotationForm>();
  const isViewMode = watch("isViewMode");
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);
  const showDirectorDetails = clientTypeName !== ClientTypes.INDIVIDUAL;
  const depositType = quotationWatch("depositType");
  const proposalCurrency = watch("proposalDetails.currency");
  const { data } = useGetCurrency();

  useEffect(() => {
    if (data.symbol !== depositType)
      quotationSetValue("depositType", data.symbol);
    quotationSetValue("currency", proposalCurrency);
  }, [proposalCurrency]);

  return isViewMode ? (
    <>
      <ProposalDetailsView />
      {showDirectorDetails && <DirectorsDetails />}
      <AddressViewDetail />
    </>
  ) : (
    <>
      <ProposalDetailsForm />
      <CustomerDetail />
      <AddressDetails />
    </>
  );
};
