import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import { AddSignatoriesWrap } from "./add-signatories.style";
import SignatoriesList from "./signatories-list.component";

export const AddSignatories = () => {
  const theme = useTheme();
  return (
    <AddSignatoriesWrap theme={theme} className="add-signatories-wrap">
      <Stack paddingMd={3} paddingXs={3} title="Documents">
        <SignatoriesList />
      </Stack>
    </AddSignatoriesWrap>
  );
};
