// *** Metadata *** //

// Workflow: This will wrap all the routes that will need workflows
// This will also manage the access. So consider this component
// will manage all the process related to workflow bindings,
// implementing restrictions on random url accesses

// WorkflowItem: This component will be used to manage the individual
// workflow items. For example a specific part in a journey. This
// component will also be used to manage the tabs functionality. The
// tab functionality will be of two types: one in which the user cannot
// randomly visit tabs in any order like in broker portal and the other
// in which random tab access will be restricted

// WorkflowItemChild: List of these components will be direct children of
// WorkflowItem and StepperProgress will be used to manage the tab content

import { WorkflowTask } from "@_types";
import { useExecuteWorkflow, useGetWorkflowTask } from "@hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { Button, CircleLoader, Icon, PageAlert } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WorkflowContext = createContext<WorkflowTask>(null);

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);
  if (!context)
    throw new Error(
      "useWorkflowContext should only be called in children of Workflows"
    );

  return context;
};

export const Workflows = ({
  children,
  runningId,
}: {
  children: React.ReactNode;
  runningId?: number;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [newWorkflowTask, setNewWorkflowTask] = useState<WorkflowTask>();
  const [workflowExecutionCompleted, setWorkflowExecutionCompleted] =
    useState(false);

  // Mutation for new workflow task
  const {
    mutateAsync: executeWorkflow,
    isLoading: isExecutingWorkflow,
    isError: errorInWorkflowExecution,
  } = useExecuteWorkflow();

  // Query to fetch existing workflow task
  const resumeTask = !!runningId;
  const {
    isLoading: isLoadingWorkflowTask,
    isError: errorInLoadingWorkflowTask,
    data: workflowTask,
  } = useGetWorkflowTask(runningId);

  useEffect(() => {
    if (!runningId) {
      executeWorkflow()
        .then((workflowTask) => {
          setNewWorkflowTask(workflowTask);
          setWorkflowExecutionCompleted(true);
        })
        .catch(() => {});
    }
  }, [runningId]);

  if (
    isExecutingWorkflow ||
    isLoadingWorkflowTask ||
    (!workflowExecutionCompleted && !resumeTask)
  ) {
    return <CircleLoader theme={theme} />;
  }

  if (errorInWorkflowExecution || errorInLoadingWorkflowTask) {
    return (
      <PageAlert
        theme={theme}
        icon="DialogAlertIcon"
        title="Oops! Unexpected Error occurred."
        variant="full-page"
        message="We encountered an unexpected error. Try refreshing the page."
        actionArea={
          <>
            <Button
              theme={theme}
              fullWidth
              secondary
              startIcon={<Icon name="IconLeftArrow" />}
              text="Go Back"
              onClick={() => navigate(-1)}
            />
            <Button
              theme={theme}
              fullWidth
              danger
              startIcon={<Icon name="ResetIcon" />}
              text="Refresh Page"
              onClick={() => window.location.reload()}
            />
          </>
        }
      />
    );
  }

  return (
    <WorkflowContext.Provider
      value={resumeTask ? workflowTask : newWorkflowTask}
    >
      {children}
    </WorkflowContext.Provider>
  );
};
