import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getCreditTiers } from "@services";
import { CreditTier } from "@_types/creditTier";
import { useGetCompanyByTenantId } from "@hooks";

const useGetCreditTiers = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, CreditTier[]>({
    queryKey: [QUERY_KEYS.GET_CREDIT_TIERS, companyId],
    queryFn: () => getCreditTiers(companyId),
    enabled: !!companyId,
  });
};

export default useGetCreditTiers;
