import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import { AddSignatoriesWrap } from "./add-signatories.style";
import SignatoriesList from "./signatories-list.component";
import { SignatoriesAddProps } from "@components/compliance-document/add-signatories/add-signatories.props";

export const ComplianceAddSignatories = ({
  complianceDocuments,
  signatories,
}: SignatoriesAddProps) => {
  const theme = useTheme();
  return (
    <AddSignatoriesWrap theme={theme} className="add-signatories-wrap">
      <Stack paddingMd={3} paddingXs={3} title="Signatories">
        <SignatoriesList
          complianceDocuments={complianceDocuments}
          signatories={signatories}
          isGeneratedDocument={false}
        />
      </Stack>
    </AddSignatoriesWrap>
  );
};
