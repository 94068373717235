import { IThemeBuilder } from "@_types";
import { getThemeBuilderByEmail } from "@services";
import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query";
import { queryKeyFactory } from "@helpers/query";

type UseGetThemeByEmailOptions = Omit<
  UseQueryOptions<string, unknown, IThemeBuilder>,
  "queryKey" | "queryFn"
> & { email: string };

const useGetThemebyEmail = ({
  email,
  ...options
}: UseGetThemeByEmailOptions) => {
  return useQuery({
    queryKey: queryKeyFactory.getThemeByEmail(email),
    queryFn: () => getThemeBuilderByEmail(email),
    ...options,
  });
};

export default useGetThemebyEmail;
