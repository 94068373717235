import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { Typography } from "@ntpkunity/controls";
import {
  PieChart,
  ResponsiveContainer,
  Pie,
  Sector,
  Label,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import { Skeleton } from "@ntpkunity/controls";

import { useTheme } from "@mui/material";
import { ChartBaseData } from "@_types";
import { PieChartWrap } from "./pie-chart.style";
import { PIE_CHART_CONFIG } from "@helpers/const";

const ActiveShape = (props) => {
  return (
    <g>
      <Sector
        cx={props.cx}
        cy={props.cy}
        innerRadius={props.innerRadius}
        outerRadius={props.outerRadius + 5}
        startAngle={props.startAngle}
        endAngle={props.endAngle}
        fill={props.payload.color}
      />
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
}) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius,
          padding: "2px 8px",
        }}
      >
        <Typography theme={theme} component="small" variant="caption">
          {payload[0].name}: {payload[0].value}
        </Typography>
      </div>
    );
  }
  return null;
};

type PieChartControlProps = {
  data: ChartBaseData[];
  radius: number;
  isLoading: boolean;
  donutWidth: "full" | number;
  onClick?: (props: any, index: number, activeIndex) => void;
  centerText?: string | ReactNode;
};

type LegendItem = {
  status: string;
  color: string;
};

type CustomLegendProps = {
  payload: LegendItem[];
  colors: string[];
  onClick: (entry: LegendItem, index: number) => void;
};

const CustomLegend = ({ payload, colors, onClick }: CustomLegendProps) => {
  return (
    <ul className="custom-legend-container">
      {payload.map((entry, index) => {
        return (
          <li
            key={`legend-item-${index}`}
            className="legend-item"
            onClick={() => onClick(entry, index)}
          >
            <span
              className="item-indicator"
              style={{
                backgroundColor: colors[index],
              }}
            />
            <span>{entry.status}</span>
          </li>
        );
      })}
    </ul>
  );
};

export const ProposalPieChart = forwardRef(
  (props: PieChartControlProps, ref: React.ForwardedRef<any>) => {
    const {
      data,
      radius,
      centerText,
      onClick,
      donutWidth = "full",
      isLoading,
    } = props;
    const colors = data.map((item) => item.color);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const onMouseClick = (props: any, index: number) => {
      if (activeIndex !== index) {
        setActiveIndex(index);
        onClick && onClick?.(props, index, true);
      } else {
        setActiveIndex(null);
        onClick && onClick?.(props, index, false);
      }
    };
    useImperativeHandle(ref, () => {
      return {
        updateIndex: (index: number) => {
          setActiveIndex(index);
        },
      };
    });
    const innerRadius = donutWidth === "full" ? 0 : radius - donutWidth;
    const totalCount = data.reduce((prev, curr) => prev + curr.count, 0);
    const hasChartData = totalCount > 0; // For better readability, I added this condition to check if totalCount is greater than 0
    const mappedData = hasChartData ? data : PIE_CHART_CONFIG.DEFAULT_DATA; // use this for render graph in single slice

    const theme = useTheme();
    return (
      <PieChartWrap theme={theme} className="pie-chart-wrap">
        {isLoading ? (
          <Skeleton theme={theme} height={460} />
        ) : (
          <ResponsiveContainer width="100%" height="100%" maxHeight={250}>
            <PieChart
              width={250}
              height={250}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            >
              <Pie
                activeIndex={activeIndex}
                activeShape={
                  hasChartData && ((props) => <ActiveShape {...props} />)
                }
                data={mappedData}
                cx={"50%"}
                cy={125}
                innerRadius={innerRadius}
                outerRadius={radius}
                dataKey="count"
                nameKey="status"
                onClick={hasChartData && onMouseClick}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="widget-main-number fw-sbold"
                          >
                            {totalCount}
                          </tspan>
                          {centerText && (
                            <tspan
                              className="text-muted sub-text"
                              x={viewBox.cx}
                              y={(viewBox.cy || 0) + 24}
                              style={{ maxWidth: 136 }}
                            >
                              {centerText}
                            </tspan>
                          )}
                        </text>
                      );
                    }
                  }}
                />

                {mappedData.map((item, index) => (
                  <Cell key={`pie-cell-${index}`} fill={item.color} />
                ))}
              </Pie>
              {hasChartData && (
                <Tooltip content={<CustomTooltip payload={mappedData} />} />
              )}

              <Legend
                iconType="circle"
                content={() => (
                  <CustomLegend
                    payload={data}
                    colors={colors}
                    onClick={(entry, index) => {
                      if (hasChartData) {
                        onMouseClick(entry, index);
                      }
                    }}
                  />
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </PieChartWrap>
    );
  }
);
