import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getSignatories } from "@services";
import { TSortParams, SignatoryResponse } from "@_types";

const useGetSignatories = <SearchParams extends { [key: string]: any }>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>
): UseQueryResult<SignatoryResponse[]> => {
  return useQuery<SignatoryResponse[]>({
    queryKey: [QUERY_KEYS.SIGNATORIES, searchParams, sortParams],
    queryFn: () => getSignatories(searchParams, sortParams),
  });
};

export default useGetSignatories;
