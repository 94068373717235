import { useTheme } from "@mui/material";
import { FinancialConfigFieldsWrap } from "./config-fields.style";
import { Box, Button, Grid, Select, Typography } from "@ntpkunity/controls";
import { FinancialConfigurationFee, SettingsDialog } from "@components";
import { Controller, useFormContext } from "react-hook-form";
import { useContext, useState } from "react";
import {
  AMORTIZATION_METHODS,
  APR_CALCULATION_METHODS,
  COMMISION_CALCULATION_METHODS,
  INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS,
} from "@helpers/const";
import {
  FLAT_RATE_CALCULATION_BASIS_OPTIONS,
  FlatRateCalculationBasis,
  TAX_TYPES,
  TaxType,
  TRUE_FALSE_VALUES,
} from "@ntpkunity/controls-common";
import { UMSStoreContext } from "@ntpkunity/controls-ums";
import { useGetPermissions } from "@hooks";

export const FinancialConfigurationFields = ({
  financeType,
}: {
  financeType: string;
}) => {
  const theme = useTheme();

  const { control, watch, setValue } = useFormContext();
  const [settingsDialog, setSettingsDialog] = useState(false);
  const flatRateCalculationBasis = watch(
    `${financeType}.flatRateCalculationBasis`
  );
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;
  const { isSuccess: permissionsLoaded } = useGetPermissions(
    productId?.productId
  );
  const isAssetCostTaxType =
    watch(`${financeType}.taxType`) === TaxType.ASSET_COST;
  const isFlatRateOnPayments =
    flatRateCalculationBasis === FlatRateCalculationBasis.NUMBER_OF_PAYMENTS;
  return (
    <FinancialConfigFieldsWrap
      theme={theme}
      className="financial-config-fields-wrap"
    >
      <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.amortizationMethod`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Amortization Method"
                items={AMORTIZATION_METHODS}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.aprCalculationMethod`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="APR Calculation method"
                items={APR_CALCULATION_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.initialCashOutFlowForFlatRate`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Initial Cash Outflow for Flat Rate"
                items={INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.flatRateCalculationBasis`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Flat Rate Calculation Basis"
                items={FLAT_RATE_CALCULATION_BASIS_OPTIONS}
              />
            )}
          />
        </Grid>
        {isFlatRateOnPayments && (
          <Grid theme={theme} item xs={12} md={4}>
            <Controller
              name={`${financeType}.paymentsIncludingBalloon`}
              control={control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  fullWidth
                  label="Payments Including Balloon"
                  items={TRUE_FALSE_VALUES}
                />
              )}
            />
          </Grid>
        )}
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.commissionCalculationMethod`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Commission Calculation Method"
                items={COMMISION_CALCULATION_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.isCommissionPassToCustomer`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Commission Pass to Customers"
                items={TRUE_FALSE_VALUES}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.isVATApplicable`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="VAT Applicable"
                items={TRUE_FALSE_VALUES}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.taxType`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Tax Type"
                items={TAX_TYPES}
                value={value}
                onChange={(e: any) => {
                  if (e.target.value === TaxType.ASSET_COST) {
                    setValue(`${financeType}.calculateCorporateTax`, false);
                  }
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${financeType}.grossProfit`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Calculate Gross Profit "
                items={TRUE_FALSE_VALUES}
              />
            )}
          />
        </Grid>
        {!isAssetCostTaxType && (
          <Grid theme={theme} item xs={12} md={4}>
            <Controller
              name={`${financeType}.calculateCorporateTax`}
              control={control}
              render={({ field }) => (
                <Select
                  theme={theme}
                  fullWidth
                  label="Corporate Tax Saving"
                  items={TRUE_FALSE_VALUES}
                  {...field}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          className="fw-medium text-primary link"
          defaultBtn
          onClick={() => {
            setSettingsDialog(true);
          }}
          disabled={!permissionsLoaded}
          text="Settings"
        />
      </Box>
      {settingsDialog && (
        <SettingsDialog
          open={settingsDialog}
          onClose={() => setSettingsDialog(false)}
          financeType={financeType}
        />
      )}
      <Box theme={theme} className="fee-container">
        <FinancialConfigurationFee financeType={financeType} />
      </Box>
    </FinancialConfigFieldsWrap>
  );
};
