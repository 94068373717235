import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { DirectorTableRowProps } from "./director-table.props";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { TableRowOptions } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
const DirectorTableRow = ({
  index,
  director,
  setOpenDialog,
  setEditDirectorId,
}: DirectorTableRowProps) => {
  const theme = useTheme();
  const {
    directorMethods: { remove },
  } = useProposalFieldArrays();

  const { watch } = useFormContext<TProposalForm>();
  const isViewMode = watch("isViewMode");

  const handleOptionClick = (_, key, value) => {
    if (key === TableRowOptions.VIEW_EDIT) {
      setEditDirectorId(value);
      setOpenDialog(true);
      return;
    }
    if (key === TableRowOptions.DELETE) {
      remove(value);
    }
  };
  return (
    <TableRow className="child-tr">
      <TableCell>{`${director.firstName ?? ""} ${
        director.lastName ?? ""
      }`}</TableCell>
      <TableCell>{director.emailAddress}</TableCell>
      <TableCell>{director.contactNumber}</TableCell>
      <TableCell>{director.address}</TableCell>
      <TableCell>{director.role}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              disabled: isViewMode,
              optionText: "View/Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
              optionValue: director.id,
            },
            {
              disabled: isViewMode,
              optionText: "Delete",
              optionkey: TableRowOptions.DELETE,
              optionValue: index,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DirectorTableRow;
