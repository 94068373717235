import { NewQuotationPageHeader, QuotationTool } from "@components";
import { useTheme } from "@mui/material";
import { PageContent, QuotationPageContentWrap } from "./page-content.styles";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetCompanyByTenantId,
  useGetQuotationByIdentifier,
} from "@hooks/queries";
import { ConfigurationWrapper as ConfigurationContextProvider } from "@components";

import {
  EvaluationExternalParams,
  QuotationFormProvider,
  useCalculationsActionsContext,
} from "@ntpkunity/controls-common";
import { RouterState } from "@pages/proposal/proposal-page";
import { useState } from "react";

export const QuotationPageContent = () => {
  const theme = useTheme();
  const params = useParams<{ quotationId?: string }>();
  const quotationId = params.quotationId;
  const { setCalculationResults, setCorporateTax, setVat } =
    useCalculationsActionsContext();
  const location = useLocation();
  const { data: companyInfo } = useGetCompanyByTenantId();

  const [evaluationParams, setEvaluationParams] =
    useState<EvaluationExternalParams>({
      asset_category: "",
      asset_type: "",
      company_code: companyInfo.company_code,
      customer_type: "",
    });

  const routerState: RouterState = location.state;
  const clonequotationId = routerState?.quotationId;

  const {
    isLoading,
    data: quotationData,
    isError,
  } = useGetQuotationByIdentifier(quotationId || clonequotationId, (data) => {
    setCalculationResults(data.calculations);
    setCorporateTax(data.corporateTaxAmount || 0);
    setVat(data.vatDetails);
  });

  if ((!!quotationId || !!clonequotationId) && (isLoading || isError)) {
    return <></>;
  }

  return (
    <QuotationPageContentWrap
      theme={theme}
      className="quotation-page-content-wrap"
    >
      <ConfigurationContextProvider
        retainFinanceType={quotationData?.formValues.financeType}
      >
        <QuotationFormProvider defaultValues={quotationData?.formValues}>
          <NewQuotationPageHeader
            brokerDefaultValues={quotationData?.brokerSpecificFormValues}
            setEvaluationParams={setEvaluationParams}
          />
          <PageContent theme={theme} className="page-content">
            <QuotationTool evaluationParams={evaluationParams} />
          </PageContent>
        </QuotationFormProvider>
      </ConfigurationContextProvider>
    </QuotationPageContentWrap>
  );
};
