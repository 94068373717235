import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { PreviousCommentsWrap } from "./previous-comments.style";
import {
  Accordion,
  Avatar,
  Box,
  Icon,
  Input,
  Select,
  Typography,
  Button,
} from "@ntpkunity/controls";
import { CommentCategory } from "@helpers/enum";
import { getUserNameInitials, utcToLocalDateTime } from "@helpers/utils";
import { TCommentsDiaryDetails } from "@_types";

export const PreviousComments = ({ comments }) => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [delayedKeyword, setDelayedKeyword] = useState<string>("");
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const commentCategoryOptions = [
    { text: "All", value: "All" },
    ...Object.entries(CommentCategory).map(([key, value]) => ({
      text: value,
      value: key,
    })),
  ];

  const filteredComments =
    selectedCategory === "All"
      ? comments
      : comments?.filter((comment: TCommentsDiaryDetails) => {
          const commentCategory = comment.category?.toLowerCase();
          const selectedCategoryLower =
            CommentCategory[selectedCategory]?.toLowerCase();
          return commentCategory === selectedCategoryLower;
        });

  const searchedComments = filteredComments?.filter(
    (comment: TCommentsDiaryDetails) => {
      const regexPattern = new RegExp(
        delayedKeyword.replace(/\*/g, ".*").toLowerCase()
      );
      return regexPattern.test(comment.comment?.toLowerCase());
    }
  );

  useEffect(() => {
    if (searchedComments) {
      setIsDataLoaded(true);
    }
  }, [searchedComments]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedKeyword(searchKeyword);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKeyword]);

  return (
    <PreviousCommentsWrap theme={theme} className="previous-comments-wrap">
      <Box
        theme={theme}
        className="tab-head"
        display={"flex"}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={2}
      >
        <Box theme={theme} className="custom-dropdown" flexGrow={1}>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component={"span"}
            display={"block"}
          >
            Catagory:
          </Typography>
          <Select
            theme={theme}
            disablePortal={false}
            items={commentCategoryOptions}
            value={selectedCategory}
            onChange={(event) => {
              if (event?.target?.value) {
                setSelectedCategory(event.target.value as string);
              }
            }}
          />
        </Box>
        <Box
          theme={theme}
          flexShrink={0}
          className="action-area"
          width={{ xs: "100%", md: "auto" }}
        >
          <Input
            theme={theme}
            type="text"
            placeholder="Type here..."
            label="Sample keyword"
            fullWidth
            value={searchKeyword}
            onChange={(value) => setSearchKeyword(value)}
            endAdornment={
              <Button
                theme={theme}
                defaultBtn
                onClick={() => {}}
                iconText={<Icon name="SearchIcon" />}
                type="button"
              />
            }
          />
        </Box>
      </Box>

      <Box theme={theme} className="accordion-container" mt={2}>
        {isDataLoaded && (
          <Accordion
            theme={theme}
            items={searchedComments?.map(
              (comment: TCommentsDiaryDetails, index: number) => {
                const commentDateTime = comment.created_at;
                const { dateStr, timeStr } =
                  utcToLocalDateTime(commentDateTime);
                const userNameInitials = getUserNameInitials(
                  comment.updated_by || comment.created_by
                );
                return {
                  title: (
                    <Box theme={theme} className="title-wrap">
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>{userNameInitials}</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          {comment.updated_by || comment.created_by} Added a
                          Comment
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>{dateStr}</span>{" "}
                          <span className="separator">|</span>{" "}
                          <span>{timeStr}</span>
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  subtitle: comment.category,
                  content: (
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="text-muted text-pre-line"
                      display={"block"}
                    >
                      {comment.comment}
                    </Typography>
                  ),
                  key: index,
                  isExpanded: index === 0,
                };
              }
            )}
            shouldCollapse={false}
          />
        )}
      </Box>
    </PreviousCommentsWrap>
  );
};
