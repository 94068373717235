import { Box, Select, Typography } from "@ntpkunity/controls";
import { QuotationFiltersWrap } from "./broker-quotation-form.style";
import { useTheme } from "@mui/material";
import {
  ControllerProps,
  Control,
  UseFormReset,
  UseFormGetValues,
} from "react-hook-form";
import { ComponentType } from "react";
import {
  convertVehicleToCamelCase,
  removeDeletedEntities,
} from "@helpers/utils";
import { BrokerQuotationForm as TBrokerQuotationForm } from "@_types";
import { useSetupsSelector, useAssetSetups, useDialogState } from "@hooks";
import ChooseAssetDialog from "@components/proposal/asset-details/choose-asset-dialog/choose-asset-dialog";
import { Vehicle } from "@ntpkunity/controls-common";
import { AssetSelectionTitle } from "@components/asset-selection/asset-selection-title";

type BrokerQuotationFormProps = {
  controller: ComponentType<ControllerProps<TBrokerQuotationForm>>;
  control: Control<TBrokerQuotationForm>;
  getValues: UseFormGetValues<TBrokerQuotationForm>;
  reset: UseFormReset<TBrokerQuotationForm>;
};

export const BrokerQuotationForm = ({
  controller,
  control,
  getValues,
  reset,
}: BrokerQuotationFormProps) => {
  const Controller = controller;
  const theme = useTheme();
  const {
    dialogOpen: assetDialogOpen,
    openDialog: openAssetDialog,
    closeDialog: closeAssetDialog,
  } = useDialogState();
  const { concatenations } = useAssetSetups();
  const setups = useSetupsSelector((state) => ({
    clientTypes: state.clientTypes,
    creditTiers: state.creditTiers,
  }));
  const clientTypes = setups.clientTypes.data;
  const creditTiers = setups.creditTiers.data;

  const onListViewClick = (vehicle: Vehicle) => {
    reset({
      ...getValues(),
      asset: convertVehicleToCamelCase(vehicle),
      assetCost: vehicle.msrp,
    });
    closeAssetDialog();
  };

  return (
    <QuotationFiltersWrap className="quotation-filters-wrap">
      <Box theme={theme} className="filters-area">
        <Box theme={theme} className="filter-item no-bg">
          <AssetSelectionTitle setOpen={openAssetDialog} />
        </Box>
        <Box theme={theme} className="filter-item">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <Controller
            name="asset.concatenations"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={concatenations}
                {...field}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="filter-item">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <Controller
            name="clientType"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={removeDeletedEntities(undefined, clientTypes)?.map(
                  (ct) => ({
                    text: ct.description,
                    value: ct.code,
                  })
                )}
                {...field}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="filter-item">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Credit Tier:
          </Typography>
          <Controller
            name="creditTier"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                readOnly={!creditTiers?.length}
                theme={theme}
                items={
                  creditTiers?.map((creditTier) => {
                    return {
                      text: creditTier.description,
                      value: creditTier.description,
                    };
                  }) ?? []
                }
                {...field}
              />
            )}
          />
        </Box>
      </Box>
      <ChooseAssetDialog
        open={assetDialogOpen}
        setOpen={openAssetDialog}
        close={closeAssetDialog}
        onListViewClick={onListViewClick}
      />
    </QuotationFiltersWrap>
  );
};
