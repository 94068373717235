import { QUERY_KEYS, queryKeyFactory } from "@helpers/query";
import { getCurrencies } from "@services/index.service";
import { useQuery } from "react-query";
import useGetCompanyByTenantId from "./useGetCompanyProfile";
import { Currency, CurrencyResponse } from "@_types";
import { LOCALES } from "@helpers/const";
import { AmountType } from "@helpers/enum";

export const useGetCurrency = (currencyId?: string) => {
  const { data, isSuccess } = useGetCompanyByTenantId();

  return useQuery<undefined, unknown, CurrencyResponse>({
    queryKey: queryKeyFactory.getCurrency(),
    queryFn: () => getCurrencies(),
    enabled: isSuccess,
    select: (currenciesResponse: Currency[]) =>
      getCurrency(
        currencyId ? currencyId : data.default_currency,
        currenciesResponse
      ),
  });
};

export const getCurrency = (
  currencyId,
  currenciesResponse: Currency[]
): CurrencyResponse => {
  const userCurrency = currenciesResponse?.find(
    (currency: Currency) => currency.id == currencyId
  );
  if (!!userCurrency) {
    return {
      currency: Intl.NumberFormat(LOCALES[userCurrency.code], {
        style: "currency",
        currency: userCurrency.code,
      }),
      symbol: userCurrency.symbol,
      currenciesResponse,
      id: userCurrency.id.toString(),
    };
  }
  return {
    currency: Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }),
    symbol: AmountType.POUND,
    currenciesResponse,
    id: currenciesResponse
      ?.find((currency: Currency) => currency.symbol === AmountType.POUND)
      .id.toString(),
  };
};
