import { BrokerQuotationForm } from "@components";
import { BrokerQuotationForm as TBrokerQuotationForm } from "@_types";
import { useFormContext, Controller } from "react-hook-form";

export const BrokerFields = () => {
  const { control, setValue, getValues, reset } =
    useFormContext<TBrokerQuotationForm>();
  return (
    <BrokerQuotationForm
      controller={Controller}
      control={control}
      getValues={getValues}
      reset={reset}
    />
  );
};
