import { useMutation, UseMutationOptions } from "react-query";
import { submitProposal } from "@services";
import { queryKeyFactory } from "@helpers/query";
import { SubmitProposalPayload } from "@_types";

type UseSubmitProposalOptions = Omit<
  UseMutationOptions<any, unknown, SubmitProposalPayload>,
  "mutationFn" | "mutationKey"
>;

const useSubmitProposal = (options: UseSubmitProposalOptions = {}) => {
  return useMutation({
    mutationFn: submitProposal,
    mutationKey: queryKeyFactory.submitProposal(),
    ...options,
  });
};

export default useSubmitProposal;
