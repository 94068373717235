import {
  LayoutWithSideNavComponent,
  Snackbar,
  Workflows,
  ConfigurationWrapper as ConfigurationContextProvider,
} from "@components";
import { useTheme } from "@mui/material";
import ProposalPageWrap from "./proposal-page.style";
import { Proposal } from "@components/proposal";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  OrderStatus,
  ProposalMode,
  ProposalTabs,
  TableRowOptions,
} from "@helpers/enum";
import {
  useGetAllActiveDealers,
  useGetAllLenders,
  useGetProposal,
} from "@hooks";
import { isDealerRole } from "@helpers/utils";
import { CircleLoader, PageAlert, Button, Icon } from "@ntpkunity/controls";
import { APP_ROUTES, PROPOSAL_STATUS_TO_ROW_OPTION_MAP } from "@helpers/const";
import { useEffect } from "react";

export type RouterState = {
  proposalId?: string; // Clone Proposal
  quotationId?: string; // Clone Quotation
  tab?: ProposalTabs; // Opening specific tab
};

const ProposalPage = () => {
  const theme = useTheme();
  const params = useParams<{ proposalId?: string; mode?: string }>();
  const location = useLocation();

  useGetAllActiveDealers();
  useGetAllLenders();

  const routerState: RouterState = location.state;
  const cloneProposalId = routerState?.proposalId;
  const isEditMode = ProposalMode.EDIT === params.mode;
  const isViewMode = ProposalMode.VIEW === params.mode;
  const isInvalidUrl =
    !!params.mode &&
    ![ProposalMode.VIEW, ProposalMode.EDIT].includes(
      params.mode as ProposalMode
    );

  const {
    data,
    isLoading: isProposalLoading,
    isError: isErrorInProposal,
    refetch: getProposal,
  } = useGetProposal(params.proposalId || cloneProposalId);

  const availableOptions =
    PROPOSAL_STATUS_TO_ROW_OPTION_MAP[data?.proposal?.status] || [];
  const hasAvailableOption = availableOptions.includes(
    params.mode as TableRowOptions
  );

  if (cloneProposalId && data) {
    data.proposal.documents = [];
    data.proposal.status = OrderStatus.DRAFT;
    if (isDealerRole()) {
      data.proposal.proposalDetails.lenderName = undefined;
    }
  }

  useEffect(() => {
    if (params?.proposalId) {
      return () => {
        getProposal();
      };
    }
  }, [params?.proposalId]);

  if (isInvalidUrl) {
    return (
      <ProposalPageAlert
        title="Oops! Invalid URL Access."
        message="The URL you accessed is invalid."
      />
    );
  }

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ProposalPageWrap theme={theme} className="proposal-page-wrap">
        {(isEditMode || isViewMode) && isProposalLoading ? (
          <CircleLoader theme={theme} />
        ) : (isEditMode || isViewMode) && isErrorInProposal ? (
          <ProposalPageAlert
            title="Oops! Unexpected Error occurred."
            message="We encountered an unexpected error. Please refresh the page."
          />
        ) : (
          <Workflows runningId={data?.runningId}>
            <ConfigurationContextProvider
              retainFinanceType={data?.proposal.proposalDetails.financeType}
            >
              {!hasAvailableOption && isEditMode ? (
                <ProposalPageAlert
                  title="Oops! Action not allowed."
                  message="Editing this Proposal is not permitted."
                />
              ) : (
                <Proposal proposalData={data} />
              )}
            </ConfigurationContextProvider>
          </Workflows>
        )}
        <Snackbar />
      </ProposalPageWrap>
    </LayoutWithSideNavComponent>
  );
};

const ProposalPageAlert = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <PageAlert
      theme={theme}
      icon="DialogAlertIcon"
      title={title}
      variant="full-page"
      message={message}
      actionArea={
        <Button
          theme={theme}
          fullWidth
          danger
          startIcon={<Icon name="IconLeftArrow" />}
          text="Go to Homepage"
          onClick={() => {
            navigate(APP_ROUTES.DASHBOARD);
          }}
        />
      }
    />
  );
};

export default ProposalPage;
