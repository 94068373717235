import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const ProposalPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.proposal-page-wrap": {
    ".page-header-wrap": {
      ".action-area": {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    ".page-content": {
      [theme.breakpoints.down("md")]: {
        paddingTop: 178,
      },
    },
    ".full-page-alert-wrap": {
      "&.full-page": {
        minHeight: "calc(100vh - 73px)",
      },
    },
  },
}));

export default ProposalPageWrap;
