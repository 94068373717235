import { useEffect, useState } from "react";
import { ClientTypes } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { useGetClientTypeName } from "@hooks";

const GENERIC_COMPANY_FIELDS = [
  "companyName",
  "creditTier",
  "companyRegNum",
  "tradingAs",
  "natureOfBusiness",
  "yearsInBusiness",
];

const FIELD_NAMES = {
  [ClientTypes.INDIVIDUAL]: [
    "firstName",
    "lastName",
    "idNumber",
    "emailAddress",
    "contactNumber",
    "creditTier",
  ],
  [ClientTypes.PARTNERSHIP]: GENERIC_COMPANY_FIELDS,
  [ClientTypes.LIMITED_COMPANY]: [...GENERIC_COMPANY_FIELDS, "sicCode"],
};

const useCustomerFields = () => {
  const [customerFields, setCustomerFields] = useState<string[]>([
    "customerDetails.clientType",
  ]);

  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientType);

  const updateCustomerFields = (clientType: string) => {
    const isExternalClientType = () =>
      Object.values(ClientTypes).includes(clientTypeName as ClientTypes);

    const generateNames = (fields: string[]) =>
      fields.filter(Boolean).map((fieldName) => `customerDetails.${fieldName}`);

    if (!clientType) {
      return setCustomerFields(["customerDetails.clientType"]);
    }

    if (!isExternalClientType()) {
      return setCustomerFields(
        generateNames(["clientType", ...GENERIC_COMPANY_FIELDS])
      );
    }

    setCustomerFields(
      generateNames([
        "clientType",
        ...(FIELD_NAMES[clientTypeName] ?? GENERIC_COMPANY_FIELDS),
      ])
    );
  };

  useEffect(() => {
    updateCustomerFields(clientType);
  }, [clientType]);

  return customerFields;
};

export default useCustomerFields;
