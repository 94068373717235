import axios from "axios";
import {
  errorResponseInterceptor,
  requestIntercepter,
  successResponseInterceptor,
} from "./interceptors";

const workflowApi = axios.create({ baseURL: process.env.WORKFLOW_API_URL });

workflowApi.interceptors.request.use(requestIntercepter);
workflowApi.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
export default workflowApi;
