import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const SignatoryStatusPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-wrap": {
    ".page-header-wrap": {
      ".action-area": {
        gap: 0,
      },
    },
    ".page-content": {
      [theme.breakpoints.down("md")]: {
        paddingTop: 154,
      },
    },
  },
}));

export default SignatoryStatusPageWrap;
