import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const UserManagementPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    [theme.breakpoints.down("md")]: {
      paddingTop: 104,
      ".custom-drawer": {
        width: "100%",
        ".MuiDrawer-paper": {
          width: "100%",
          height: "calc(100% - 107px)",
          borderRadius: "16px 16px 0 0",
          zIndex: 1201,
          right: "auto",
          left: 0,
          top: 107,
        },
      },
    },
  },
}));

const drawerWidth = 424;

export const UmsDrawerWrap = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ".page-content": {
    ".custom-drawer": {
      ".MuiDrawer-paper": {
        [theme.breakpoints.down("md")]: {
          transform: "translateX(0) translateY(100%) !important",
        },
      },
    },
  },
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth + 32,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      ".page-content": {
        ".custom-drawer": {
          ".MuiDrawer-paper": {
            transform: "translateX(0) translateY(0) !important",
          },
        },
      },
    },
  }),
}));

export default UserManagementPageWrap;
