import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@styles";

export const BROKER_APP_THEME_WRAP = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.global-theme-wrap": {
    ".primary-alert": {
      svg: {
        path: {
          stroke: theme.palette.primary.main,
        },
      },
    },
    ".link": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    ".fw-medium": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".fw-sbold": {
      fontWeight: theme.customVariables.fontWeightSemiBold,
    },
    ".text-dark": {
      color: CustomColors.textDark,
    },
    ".text-muted": {
      color: theme.palette.grey[600],
    },
    ".text-primary": {
      color: theme.palette.primary.main,
    },
    ".text-secondary": {
      color: theme.palette.text.secondary,
    },
    ".text-center": {
      textAlign: "center",
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".text-danger": {
      color: theme.palette.error.main,
    },
    ".decoration-none": {
      textDecoration: "none",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".text-pre-line": {
      whiteSpace: "pre-line",
    },
    ".text-tiny": {
      fontSize: 10,
      lineHeight: "12px",
    },
    ".line-height18": {
      lineHeight: "18px",
    },
    ".w-100": {
      width: "100%",
    },
    ".mw-100": {
      maxWidth: "100%",
    },
    ".h-100": {
      height: '100%'
    },
    ".w-m-100": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    ".dFlex": {
      display: "flex",
    },
    ".cp": {
      cursor: "pointer",
    },
    ".justifyContentBetween": {
      justifyContent: "space-between",
    },
    svg: {
      "&.text-primary": {
        path: {
          stroke: theme.palette.primary.main,
        },
      },
    },
    ".bg-light": {
      ".u-form-group": {
        ".u-input-label": {
          backgroundColor: "#F7F7F7",
        },
        "&.u-date-picker": {
          ".u-form-control": {
            ".MuiInputLabel-formControl": {
              backgroundColor: "#F7F7F7",
            },
          },
        },
      },
    },
    ".btn": {
      padding: "12px 16px",
      "&.with-icon": {
        ".MuiButton-iconSizeMedium": {
          width: 16,
          height: 16,
          svg: {
            width: 16,
            height: 16,
          },
        },
        "&.icon-only": {
          svg: {
            width: 16,
            height: 16,
          },
        },
        "&.btn-default": {
          svg: {
            "&.text-primary": {
              path: {
                stroke: theme.palette.primary.main,
              },
            },
          },
          "&.icon-only": {
            svg: {
              width: 24,
              height: 24,
            },
          },
        },
      },
    },
    ".custom-dropdown": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".u-form-group": {
        height: 20,
        ".u-form-control": {
          "&.u-select": {
            height: 20,
            color: theme.palette.grey[900],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            ".MuiInputBase-input": {
              padding: "0px 28px 0px 0px",
            },
            ".MuiSelect-icon": {
              right: 0,
              path: {
                stroke: theme.palette.grey[900],
              },
            },
            fieldset: {
              border: "none",
            },
          },
        },
      },
    },
    ".collapse-wrap": {
      ".u-accordian-wrap": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        padding: 0,
        minHeight: "auto",
        ".MuiAccordionSummary-content": {
          margin: 0,
          alignItems: "center",
          ".main-title": {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: theme.typography.h4.lineHeight,
            ".title-with-icon": {
              width: 24,
              height: 24,
            },
          },
          ".action-btn": {
            ".btn": {
              "&.icon-only": {
                padding: 0,
              },
            },
          },
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            gap: 16,
            ".action-btn": {
              width: "100%",
            },
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          marginLeft: 16,
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "flex-start",
        },
      },
      ".u-accordian-body": {
        padding: "24px 0px 0 0",
        border: "none",
      },
      "&.list-collapse": {
        ".u-accordion-header": {
          ".main-title": {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: theme.typography.fontWeightRegular,
            display: "flex",
            alignItems: "flex-start",
            gap: 16,
            svg: {
              flexShrink: 0,
            },
          },
          [theme.breakpoints.down("md")]: {
            padding: "0 16px",
          },
        },
        ".u-accordian-body": {
          padding: "16px 0px 0 0",
        },
        ".MuiAccordionSummary-content": {
          [theme.breakpoints.down("md")]: {
            flexWrap: "nowrap",
            alignItems: "flex-start",
            ".action-btn": {
              width: "auto",
            },
          },
        },
        [theme.breakpoints.down("md")]: {
          padding: "24px 8px",
        },
        "&.with-border": {
          ".u-accordian-wrap": {
            borderBottom: "1px solid" + theme.palette.divider,
            paddingBottom: 16,
            marginBottom: 16,
            "&:last-child": {
              border: "none",
              paddingBottom: 0,
              marginBottom: 0,
            },
          },
        },
      },
    },
    ".desktop-disable": {
      [theme.breakpoints.up("md")]: {
        ".u-accordian-wrap": {
          ".u-accordion-header": {
            cursor: "text",
            ".MuiAccordionSummary-expandIconWrapper": {
              display: "none",
            },
          },
          ".MuiCollapse-root.MuiCollapse-vertical ": {
            minHeight: "auto !important",
            height: "auto !important",
            visibility: "visible !important",
          },
        },
      },
    },
    ".stack-mobile": {
      [theme.breakpoints.up("md")]: {
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        ".stack-header": {
          display: "none",
        },
      },
    },
    ".MuiSkeleton-root": {
      transformOrigin: "0 0",
      transform: "scale(1, 1)",
      borderRadius: theme.shape.borderRadius,
    },
    ".MuiDivider-root.divider": {
      borderColor: theme.palette.divider,
      margin: "24px 0",
    },
    [theme.breakpoints.down("md")]: {
      ".text-h4": {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
      },
      ".text-h3": {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight,
      },
      ".text-h2": {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
      },
      ".text-body2": {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
      },
    },
    // Design Systems Updates Styles
    ".u-form-group": {
      ".u-form-control": {
        height: 40,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: "16px",
        "&.textarea": {
          height: "auto",
        },
        "&.u-select": {
          ".MuiInputBase-input": {
            minHeight: "auto",
          },
        },
      },
      ".u-input-label": {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: "16px",
        "&.Mui-focused, &.MuiFormLabel-filled": {
          transform: "translate(14px, -6px) scale(0.85)",
        },
      },
      "&.u-date-picker": {
        ".u-form-control": {
          ".MuiInputBase-formControl": {
            height: 40,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: "16px",
            paddingRight: 24,
            ".MuiInputAdornment-root": {
              ".MuiButtonBase-root": {
                width: 16,
                height: 16,
                padding: 0,
              },
            },
          },
        },
      },
    },
    ".date-range-picker": {
      ".react-datepicker__input-container": {
        input: {
          height: 40,
          fontSize: theme.typography.body2.fontSize,
          lineHeight: "16px",
        },
      },
    },
    ".u-custom-autocomplete": {
      "&:last-child": {
        marginBottom: 0,
      },
      ".MuiAutocomplete-root": {
        ".u-form-group": {
          ".MuiInputBase-formControl": {
            height: 40,
            padding: "0 12px 0px 0",
            fontSize: theme.typography.body2.fontSize,
            lineHeight: "16px",
            "&.MuiInputBase-adornedEnd": {
              ".MuiInputBase-input": {
                padding: "8px 0px 8px 16px",
              },
            },
          },
          ".MuiInputLabel-formControl": {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: "16px",
            "&.Mui-focused": {
              transform: "translate(14px, -6px) scale(0.85)",
            },
          },
        },
        "&.MuiAutocomplete-hasClearIcon": {
          ".u-form-group": {
            ".MuiInputLabel-formControl": {
              transform: "translate(14px, -6px) scale(0.85)",
            },
          },
        },
      },
    },
  },
}));
