import axios from "axios";
import {
  errorResponseInterceptor,
  requestIntercepter,
  successResponseInterceptor,
} from "./interceptors";

const indexAPI = axios.create({ baseURL: process.env.INDEX_BASE_URL });

indexAPI.interceptors.request.use(requestIntercepter);
indexAPI.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
export default indexAPI;
