import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, Button, DataTable } from "@ntpkunity/controls";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  SNACKBAR_MESSAGES,
} from "@helpers/const";
import { DataTableWrap } from "@styles/data-table-wrap";
import { PaginationWrap } from "@styles/pagination-wrap-style";
import {
  mapQuotationData,
  useGetOrders,
  useGetCompanyByTenantId,
  useDialogState,
  useSetupsSelector,
  CustomError,
  useConvertQuotationToProposal,
  useDownloadDocs,
} from "@hooks";
import QuotationTableRow from "./quotation-table-row/quotation-table-row.component";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  TQuotationSearchParams,
  TSortParams,
  TQuotationWorkQueueEntry,
  QuotationDownloadDialogData,
  DownloadQuoteAdditionalParams,
} from "@_types";
import TablePagination from "@mui/material/TablePagination";
import QuotationFilter from "./quotation-filters/quotation-filters.component";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  OrderStage,
  SortOrder,
  SnackbarVariants,
  DownloadDocument,
  Events,
  ProposalMode,
} from "@helpers/enum";
import {
  convertIsoDateStrToddmmyyyy,
  getDownloadDocumentPayload,
} from "@helpers/utils";
import {
  DownloadQuotationDialog,
  MessageRow,
  TableRowSkeleton,
} from "@components";
import { getQuotationByIdentifier } from "@services";

const searchInitialState: TQuotationSearchParams = {
  introducer_name: "",
  name: "",
  finance_type: "",
  finance_amount: null,
  customer_type: "",
  updated_at: "",
  order_stage: OrderStage.QUOTATION,
  page_number: DEFAULT_PAGE_NUMBER,
  page_size: DEFAULT_PAGE_SIZE,
};

export const QuotationTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] =
    useState<TQuotationSearchParams>(searchInitialState);
  const [sortParams, setSortParams] = useState<
    TSortParams<keyof TQuotationSearchParams>
  >({ sort_by: "updated_at", sort_order: SortOrder.DESC });
  const [debouncedSearchParams] = useDebounce(
    useMemo(
      () => ({
        ...searchParams,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
      }),
      [searchParams]
    ),
    500
  );

  const {
    isLoading,
    isError,
    data: quotations,
  } = useGetOrders<TQuotationSearchParams, TQuotationWorkQueueEntry>(
    debouncedSearchParams,
    sortParams
  );
  const { setSnackbar } = useSnackbarContext();
  const { mutateAsync } = useConvertQuotationToProposal();
  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const setups = useSetupsSelector((state) => ({
    financeTypes: state.financeTypes,
    clientTypes: state.clientTypes,
    filteredFinanceTypes: state.getFilteredFinanceTypes().financeTypes || [],
  }));
  const clientTypes = setups.clientTypes.data;
  const financeTypes = setups.financeTypes.data;
  const {
    dialogOpen: downloadDialogOpened,
    closeDialog: closeDownloadDialog,
    openDialog: openDownloadDialog,
    dialogData: downloadDialogData,
  } = useDialogState<QuotationDownloadDialogData>();

  const clientTypeName = clientTypes?.find(
    (ct) => ct.code === downloadDialogData?.clientType
  )?.description;

  const isNotSuccess = isLoading || isError;

  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    if (isError) {
      setSnackbar({
        open: true,
        message: "Error while fetching quotation.",
        variant: "error",
      });
    }
  }, [isError]);

  const convertToProposal = async (identifier: string) => {
    setSnackbar({
      open: true,
      message: "Converting To Proposal",
    });

    try {
      await mutateAsync(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${ProposalMode.EDIT}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error Occurred while converting to Proposal",
        variant: SnackbarVariants.ERROR,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setSearchParams({
      ...searchParams,
      page_number: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams({
      ...searchParams,
      page_number: DEFAULT_PAGE_NUMBER,
      page_size: event.target.value,
    });
  };

  const handleDownloadQuotation = async (
    additionalParams: DownloadQuoteAdditionalParams,
    identifier: string
  ) => {
    try {
      setSnackbar({
        open: true,
        message: "Downloading quotation...",
      });

      const quotationRawData = await getQuotationByIdentifier(identifier);
      const quotationSelectedData = mapQuotationData(quotationRawData);
      const quotationDownloadPayload = getDownloadDocumentPayload({
        documentType: DownloadDocument.QUOTATION,
        additionalParams: {
          ...quotationSelectedData.brokerSpecificFormValues,
          ...additionalParams,
        },
        financialCalculations: quotationSelectedData.calculations,
        financialValues: quotationSelectedData.formValues,
        setups: {
          clientTypes: clientTypes,
          financeTypes: financeTypes,
        },
        companyId: companyInfo.id,
      });

      await downloadDocument({
        payload: quotationDownloadPayload,
        eventName: Events.DOWNLOAD_QUOTATION,
      });
      setSnackbar(
        {
          open: true,
          message: SNACKBAR_MESSAGES.QUOTATION_DOWNLOADED,
        },
        2000
      );
    } catch (err) {
      let errorMessage = SNACKBAR_MESSAGES.UNEXPECTED_ERROR_OCCURRED;
      if (err instanceof CustomError) errorMessage = err.message;

      setSnackbar(
        {
          open: true,
          message: errorMessage,
          variant: "error",
        },
        5000
      );
    }
  };

  return (
    <DataTableWrap theme={theme} className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Quotation Name</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>Finance Amount</TableCell>
              <TableCell>Customer Type</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <QuotationFilter {...commonFilterParams} searchKey="name" />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="finance_type"
                  dropDownList={[
                    {
                      text: "All",
                      value: "all",
                    },
                    ...setups.filteredFinanceTypes.map((item) => ({
                      text: item.name,
                      value: item.code,
                    })),
                  ]}
                />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="finance_amount"
                  type="number"
                />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="customer_type"
                />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="updated_at"
                  placeholder="DD/MM/YYYY"
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {isNotSuccess ? (
              <TableRowSkeleton noOfCells={5} />
            ) : !quotations?.results?.length ? (
              <MessageRow message="No Quotations Found" columnSpan={6} />
            ) : (
              quotations?.results.map((quotation) => (
                <QuotationTableRow
                  key={quotation.identifier}
                  quotation={quotation}
                  convertToProposal={convertToProposal}
                  openDownloadDialog={openDownloadDialog}
                />
              ))
            )}
          </>
        }
      />
      {quotations && (
        <PaginationWrap theme={theme} className="pagination-wrap">
          <TablePagination
            component="div"
            count={quotations.total_results}
            page={parseInt(searchParams.page_number.toString())}
            onPageChange={handleChangePage}
            rowsPerPage={searchParams.page_size}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationWrap>
      )}
      <Button
        theme={theme}
        secondary
        fullWidth
        className="btn-table"
        text="Create New Quotation"
        onClick={() => {
          navigate(APP_ROUTES.QUOTATION);
        }}
      />
      <DownloadQuotationDialog
        dialogOpen={downloadDialogOpened}
        downloadQuotation={handleDownloadQuotation}
        closeDialog={closeDownloadDialog}
        dialogData={{ ...downloadDialogData, clientType: clientTypeName }}
      />
    </DataTableWrap>
  );
};
