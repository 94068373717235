import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const DashboardPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    [theme.breakpoints.down("md")]: {
      paddingTop: 104,
      "&.has-action": {
        paddingTop: 165,
      },
    },
  },
}));

export default DashboardPageWrap;
