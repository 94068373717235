export const GET_QUOTATION_BY_IDENTIFIER =
  "/configure/quick-quotation-by-identifier/:identifier";
export const GET_ORDER_WORKQUEUE = "/order/workqueue";
export const GET_ORDER_STATUS_HISTORY = "/configure/order/status/";
export const QUOTE_CONFIGURATIONS = "quote-config/get-configuration";
export const CONVERT_QUOTATION_TO_PROPOSAL =
  "/configure/quotation-to-order/:identifier";
export const UPDATE_QUOTATION = "/order/quick_quotation";
export const SAVE_UPDATE_PROPOSAL = "/order/proposal";
export const DOCUMENTS_GENERATE_PRESIGNED_URL =
  "/documents/configuration/generate-presigned-url";
export const GET_PROPOSAL_BY_REFERENCE_NUMBER =
  "/order/order_information/:referenceNumber";
export const CALCULATE_QUOTE = "CalculateQuote";
export const DASHBOARD_ANALYTICS = "/order/proposal-dashboard-analytics";
export const UPLOAD_BASE_64 = "/documents/configuration/upload-file-as-base64";
export const CHANGE_PROPOSAL_STATUS = "/order/change-proposal-status";
export const SAVE_CONFIGURATION = "/save-configuration";
export const SAVE_DIARY_COMMENT = "/customer/comment";
export const GET_DIARY_COMMENTS = "/customer/comments";
export const GET_ALL_COUNTRIES = "/countries";
export const GET_COMPANIES = "/company-lookup/search";
export const SEARCH_ADRESS = "/address-lookup/search";
export const THEME_BUILDER = "/theme-builder";
export const COMPANY_PROFILE = "company/by-tenant-id";
export const UPDATE_PERMISSIONS = "/ums/permissions/update-permissions";
export const GET_ASSET_CATEGORIES = "/asset_category";
export const GET_ASSET_TYPES = "/asset_type";
export const GET_ASSET_SUB_TYPES = "/asset_sub_type";
export const GET_ASSET_CONDITIONS = "/asset-condition/active";
export const GET_PRODUCT_TYPES = "product_type";
export const GET_FINANCE_TYPES = "finance_type";
export const GET_CLIENT_TYPES = "customer-type";
export const EXECUTE_WORKFLOW = "execute-workflow/:slug";
export const GET_WORKFLOW_CURRENT_TASK_BY_RUNNING_ID =
  "get-current-task/:runningId";
export const GET_UMS_PERMISSIONS = "/ums/um/all-user-permissions";
export const GET_COMPLIANCE_DOCUMENTS = "/stationery/type";
export const GENERATE_DOCUMENTS = "order/generate-document";
export const SIGNATORIES = "order/stakeholder/";
export const GET_DOCUMENT_TYPES = "document-type";
export const SEND_EVENT_NOTIFICATION = "/order/send-email/:eventName";
export const DMS_GET_DEALERS = "dealer/get-associated-dealers";
export const GET_DEALER_PROFILE = "/dealer/profile/:dealer_code";
export const GET_GENERATED_DOCUMENTS =
  "configure/all-document-packages-by-reference-id";
export const GET_ACTIVE_DEALERS = "dealers/active";
export const GET_LENDERS = "lender";
export const DOWNLOAD_DOCUMENT_BY_EVENT =
  "/order/download_document_by_event/:eventName";
export const SUBMIT_PROPOPSAL = "/order/proposal/submit";
