import {
  FinancialConfigurations,
  LayoutWithSideNavComponent,
  ConfigurationWrapper as ConfigurationContextProvider,
  Snackbar,
} from "@components";
import { useTheme } from "@mui/material";
import ConfigurationsPageWrap from "./financial-configurations.style";

const FinancialConfigurationsPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ConfigurationsPageWrap
        theme={theme}
        className="configurations-page-content-wrap"
      >
        <ConfigurationContextProvider>
          <FinancialConfigurations />
        </ConfigurationContextProvider>
      </ConfigurationsPageWrap>
      <Snackbar />
    </LayoutWithSideNavComponent>
  );
};

export default FinancialConfigurationsPage;
