import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "@router/ProtectedRoutes";
import { APP_ROUTES } from "@helpers";
import {
  DashboardPage,
  ProposalPage,
  WorkQueuePage,
  NewQuotationPage,
  QuoteComparisonPage,
  DiaryPage,
  DocumentPage,
  SendForPayoutPage,
  SignInPage,
  CustomerProposalPage,
  DealerProposalPage,
  SignatoryStatusPage,
  LenderDashboardPage,
  FinancialConfigurationsPage,
  ComplianceDocumentPage,
  SignInPageVirginMoney,
} from "@pages";
import { useEffect } from "react";
import { removeSessionStorageItem } from "@helpers/utils";
import { SessionStorageKeys } from "@helpers/enum";
import ManageUsers from "@pages/user-management/user-management-page";
export const Router = () => {
  useEffect(() => {
    // INFO: SessionStorage persists on page reloads. This effect will
    // remove items from Session Storage on page reloads.
    const deleteSessionStorage = () => {
      removeSessionStorageItem(SessionStorageKeys.QUOTATION_INPUTS);
    };
    window.addEventListener("beforeunload", deleteSessionStorage);
    return () => {
      window.removeEventListener("beforeunload", deleteSessionStorage);
    };
  });

  return (
    <>
      <ProtectedRoute>
        {/* <UMSConfigurationComponent>
                
            </UMSConfigurationComponent> */}
        <Routes>
          <Route path={APP_ROUTES.BASE_URL} element={<DashboardPage />} />
          <Route path={APP_ROUTES.DASHBOARD} element={<DashboardPage />} />
          <Route
            path={APP_ROUTES.QUOTATION}
            element={<NewQuotationPage key="create-quotation" />}
          />
          <Route
            path={`${APP_ROUTES.QUOTATION}/:quotationId`}
            element={<NewQuotationPage key="edit-quotation" />}
          />
          <Route path={APP_ROUTES.PROPOSAL} element={<ProposalPage />} />
          <Route
            path={`${APP_ROUTES.PROPOSAL}/:mode/:proposalId`}
            element={<ProposalPage />}
          />
          <Route
            path={`${APP_ROUTES.WORKQUEUE}/:workQueueTab`}
            element={<WorkQueuePage />}
          />
          <Route path={APP_ROUTES.UNKNOW_URL} element={<DashboardPage />} />
          <Route
            path={`${APP_ROUTES.DIARY}/:proposalId`}
            element={<DiaryPage />}
          />
          <Route
            path={APP_ROUTES.FINANCIAL_CONFIGURATIONS}
            element={<FinancialConfigurationsPage />}
          />
          <Route
            path={APP_ROUTES.QUOTATION_COMPARISON}
            element={<QuoteComparisonPage />}
          />
          <Route path={APP_ROUTES.DOCUMENT} element={<DocumentPage />} />
          <Route
            path={APP_ROUTES.SEND_FOR_PAYOUT}
            element={<SendForPayoutPage />}
          />
          <Route path={APP_ROUTES.SIGN_IN} element={<SignInPage />} />
          <Route
            path={APP_ROUTES.SIGN_IN_VIRGIN_MONEY}
            element={<SignInPageVirginMoney />}
          />
          <Route
            path={APP_ROUTES.DEALER_PROPOSAL}
            element={<DealerProposalPage />}
          />

          <Route
            path={APP_ROUTES.CUSTOMER_PROPOSAL}
            element={<CustomerProposalPage />}
          />
          <Route
            path={APP_ROUTES.SIGNATORY_STATUS}
            element={<SignatoryStatusPage />}
          />
          <Route
            path={APP_ROUTES.LENDER_DASHBOARD}
            element={<LenderDashboardPage />}
          />
          <Route
            path={APP_ROUTES.FINANCIAL_CONFIGURATIONS}
            element={<FinancialConfigurationsPage />}
          />
          <Route
            path={`${APP_ROUTES.COMPLIANCE_DOCUMENT}/:proposalId`}
            element={<ComplianceDocumentPage />}
          />
          <Route path={APP_ROUTES.USER_MANAGEMENT} element={<ManageUsers />} />
        </Routes>
      </ProtectedRoute>
    </>
  );
};
