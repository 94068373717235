import { Typography, Box, useTheme } from "@mui/material";
import { QuotationFiltersWrap } from "./new-quotation-header.style";
import { BrokerQuotationForm, BrokerSpecifcFormFields } from "@_types";
import { Select } from "@ntpkunity/controls";
import {
  convertVehicleToCamelCase,
  getCreditTierItems,
  removeDeletedEntities,
} from "@helpers/utils";
import {
  FIELD_TYPE_MAP,
  FieldPermissions,
  getPermissionKey,
  QuotationFormController,
  useQuotationFormContext,
  Vehicle,
} from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";
import { useAssetSetups, useDialogState, useSetupsSelector } from "@hooks";
import { PermissionFieldValidator } from "@ntpkunity/controls-ums";
import ChooseAssetDialog from "@components/proposal/asset-details/choose-asset-dialog/choose-asset-dialog";
import { AssetSelectionTitle } from "@components/asset-selection/asset-selection-title";

export const NewQuotationFiltersHeader = () => {
  const theme = useTheme();
  const {
    dialogOpen: assetDialogOpen,
    openDialog: openAssetDialog,
    closeDialog: closeAssetDialog,
  } = useDialogState();
  const { control, getValues } = useFormContext<BrokerSpecifcFormFields>();
  const { watch, reset } = useQuotationFormContext<BrokerQuotationForm>();
  const { concatenations } = useAssetSetups();
  const setups = useSetupsSelector((state) => ({
    financeTypes: state.financeTypes,
    clientTypes: state.clientTypes,
    creditTiers: state.creditTiers,
  }));
  const creditTiers = setups.creditTiers.data;
  const clientTypes = setups.clientTypes.data;
  const financeType = watch("financeType");
  const onListViewClick = (vehicle: Vehicle) => {
    reset({
      ...getValues(),
      asset: convertVehicleToCamelCase(vehicle),
      assetCost: vehicle.msrp,
    });
    closeAssetDialog();
  };
  return (
    <QuotationFiltersWrap theme={theme} className="quotation-filters-wrap">
      <Box className="filters-area">
        <Box className="filter-item no-bg">
          <AssetSelectionTitle setOpen={openAssetDialog} />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <QuotationFormController
            name="asset.concatenations"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={concatenations}
                {...field}
              />
            )}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <QuotationFormController
            name="clientType"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={removeDeletedEntities(undefined, clientTypes)?.map(
                  (ct) => ({
                    text: ct.description,
                    value: ct.code,
                  })
                )}
                {...field}
              />
            )}
          />
        </Box>
        <PermissionFieldValidator
          permission={getPermissionKey(
            financeType,
            FieldPermissions.CREDIT_TIER
          )}
          permissionType={FIELD_TYPE_MAP[FieldPermissions.CREDIT_TIER]}
          render={({ permission: { is_enabled } }) => {
            return (
              <Box className="filter-item">
                <Typography
                  variant="body2"
                  component={"span"}
                  display={"block"}
                  className="text-muted"
                >
                  Credit Tier:
                </Typography>
                <QuotationFormController
                  name="creditTier"
                  control={control}
                  render={({ field }) => (
                    <Select
                      disablePortal={false}
                      readOnly={!creditTiers?.length || !is_enabled}
                      theme={theme}
                      items={getCreditTierItems(creditTiers) ?? []}
                      {...field}
                    />
                  )}
                />
              </Box>
            );
          }}
        />
      </Box>
      <ChooseAssetDialog
        open={assetDialogOpen}
        setOpen={openAssetDialog}
        close={closeAssetDialog}
        onListViewClick={onListViewClick}
      />
    </QuotationFiltersWrap>
  );
};
