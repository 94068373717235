import { useState } from "react";
import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Accordion, Box } from "@ntpkunity/controls";
import {
  AssetDetailsTable,
  AddAssetForm,
  EditAssetForm,
} from "@components/proposal/asset-details";
import { AssetDetailsWrap } from "./asset-details.style";
import { TProposalForm } from "@_types/forms";
import { useFormContext } from "react-hook-form";

export const AssetDetails = () => {
  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();
  const isViewMode = watch("isViewMode");

  const [editAssetId, setEditAssetId] = useState<string | null>(null);
  return (
    <AssetDetailsWrap theme={theme} className="asset-details-wrap">
      {!isViewMode &&
        (!!editAssetId ? (
          <EditAssetForm
            editAssetId={editAssetId}
            setEditAssetId={setEditAssetId}
          />
        ) : (
          <AddAssetForm />
        ))}
      <Stack
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
        className="stack-mobile collapse-wrap desktop-disable"
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: <AssetDetailsTable setEditAssetId={setEditAssetId} />,
              title: (
                <Box theme={theme} display={{ xs: "block", md: "none" }}>
                  List of Asset Details
                </Box>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
    </AssetDetailsWrap>
  );
};
