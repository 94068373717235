import { useState } from "react";
import { useTheme } from "@mui/material";
import DiaryPageWrap, { DiaryPageContentWrap } from "./diary-page.style";
import {
  LayoutWithSideNavComponent,
  PageHeader,
  Stack,
  Footer,
  FullWindowDialog,
} from "@components";
import { Box, DuoTab, Grid, Icon } from "@ntpkunity/controls";
import {
  AddComments,
  AuditTrail,
  AuditTrailDialog,
  CommunicationHistory,
  CommunicationHistoryDialog,
  PreviousComments,
  PreviousCommentsDialog,
} from "@components/diary";
import { useNavigate, useParams } from "react-router-dom";
import { TCommentsDiaryDetails } from "@_types";
import { saveDiaryComment } from "@services";
import { useGetDiaryComments } from "@hooks";

const DiaryPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [auditTrailDialog, setAuditTrailDialog] = useState(false);
  const [previousCommentsDialog, setPreviousCommentsDialog] = useState(false);
  const [communicationHistoryDialog, setcommunicationHistoryDialog] =
    useState(false);

  const { proposalId } = useParams<{ proposalId: string }>();
  const { data: comments, refetch } = useGetDiaryComments(proposalId);
  const handleAddComment = async (proposalComment: TCommentsDiaryDetails) => {
    await saveDiaryComment({
      ...proposalComment,
      order_reference_id: proposalId,
    });
    await refetch();
  };

  const sortedComments = comments?.sort(
    (a: TCommentsDiaryDetails, b: TCommentsDiaryDetails) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    }
  );

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader title="Diary" />
      <DiaryPageWrap theme={theme} className="page-content has-footer" pt={2}>
        <Grid theme={theme} container spacing={2} alignItems={"stretch"}>
          <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
            <AddComments onAddComment={handleAddComment} />
            <Stack
              paddingMd={3}
              paddingXs={3}
              marginTopMd={2}
              marginTopXs={2}
              className="tabs-area"
            >
              <DuoTab
                theme={theme}
                varient="underline"
                items={[
                  {
                    title: "Previous Comments",
                    content: <PreviousComments comments={sortedComments} />,
                  },
                  {
                    title: "Communication History",
                    content: <CommunicationHistory />,
                  },
                ]}
                defaultTabIndex={0}
              />
            </Stack>
            <Box theme={theme} display={{ xs: "block", md: "none" }}>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Audit Trail"
                  onClick={() => setAuditTrailDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Previous Comments"
                  onClick={() => setPreviousCommentsDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Communication history"
                  onClick={() => setcommunicationHistoryDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid theme={theme} item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Box theme={theme} display={{ xs: "none", md: "block" }}>
              <AuditTrail />
            </Box>
          </Grid>
        </Grid>
        <Footer showBackButton backButtonOnClick={() => navigate(-1)} />
      </DiaryPageWrap>
      {auditTrailDialog && (
        <AuditTrailDialog
          open={auditTrailDialog}
          onClose={() => setAuditTrailDialog(false)}
        />
      )}
      {previousCommentsDialog && (
        <PreviousCommentsDialog
          open={previousCommentsDialog}
          onClose={() => setPreviousCommentsDialog(false)}
          comments={comments}
        />
      )}
      {communicationHistoryDialog && (
        <CommunicationHistoryDialog
          open={communicationHistoryDialog}
          onClose={() => setcommunicationHistoryDialog(false)}
        />
      )}
      <FullWindowDialog>
        <DiaryPageContentWrap theme={theme} className="diary-content">
          <Grid theme={theme} container spacing={2} alignItems={"stretch"}>
            <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
              <Stack paddingMd={3} paddingXs={3} bgLight>
                <AddComments onAddComment={handleAddComment} />
                <Box theme={theme} display={{ xs: "none", md: "block" }}>
                  <Stack
                    paddingMd={3}
                    paddingXs={3}
                    marginTopMd={3}
                    marginTopXs={3}
                    className="tabs-area"
                  >
                    <DuoTab
                      theme={theme}
                      varient="underline"
                      items={[
                        {
                          title: "Previous Comments",
                          content: <PreviousComments comments={comments} />,
                        },
                        {
                          title: "Communication History",
                          content: <CommunicationHistory />,
                        },
                      ]}
                      defaultTabIndex={0}
                    />
                  </Stack>
                </Box>
                <Box theme={theme} display={{ xs: "block", md: "none" }}>
                  <Box theme={theme} mt={2}>
                    <Stack
                      className="cp"
                      paddingMd={3}
                      paddingXs={3}
                      title="Audit Trail"
                      onClick={() => setAuditTrailDialog(true)}
                      actionArea={<Icon name="IconRightArrow" />}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Stack
                      className="cp"
                      paddingMd={3}
                      paddingXs={3}
                      title="Previous Comments"
                      onClick={() => setPreviousCommentsDialog(true)}
                      actionArea={<Icon name="IconRightArrow" />}
                    />
                  </Box>
                  <Box theme={theme} mt={2}>
                    <Stack
                      className="cp"
                      paddingMd={3}
                      paddingXs={3}
                      title="Communication history"
                      onClick={() => setcommunicationHistoryDialog(true)}
                      actionArea={<Icon name="IconRightArrow" />}
                    />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid theme={theme} item xl={3} lg={4} md={5} sm={12} xs={12}>
              <Box theme={theme} display={{ xs: "none", md: "block" }}>
                <AuditTrail />
              </Box>
            </Grid>
          </Grid>
        </DiaryPageContentWrap>
      </FullWindowDialog>
    </LayoutWithSideNavComponent>
  );
};

export default DiaryPage;
