import { TableCell, TableRow, useTheme } from "@mui/material";
import { DataTable } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { useCalculationsContext } from "@ntpkunity/controls-common";
import { useGetCurrency } from "@hooks";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";

export const Rentals = () => {
  const { watch } = useFormContext<TProposalForm>();
  const proposalCurrency = watch("proposalDetails.currency");
  const { data: currency } = useGetCurrency(proposalCurrency);
  const {
    data: { rentalSummary },
  } = useCalculationsContext();

  const theme = useTheme();
  return (
    <DataTableWrap theme={theme} className="no-min-width">
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>From - To</TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                Amount (W/O VAT)
              </TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          rentalSummary &&
          rentalSummary.length > 0 &&
          rentalSummary.map((rental) => (
            <TableRow className="child-tr" key={`rental-${rental.startTerm}`}>
              <TableCell>
                {rental.startTerm} - {rental.endTerm}
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                {currency?.currency?.format(rental.rentalAmount)}
              </TableCell>
            </TableRow>
          ))
        }
      />
    </DataTableWrap>
  );
};
