import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const WorkqueuePageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    [theme.breakpoints.down("md")]: {
      paddingTop: 161,
    },
  },
}));

export default WorkqueuePageWrap;
