import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getProposalByReferenceNumber } from "@services/dms.service";
import {
  GetProposalResponse,
  TAssetDetails,
  TCustomerDetails,
  TDirector,
  TDocumentDetails,
  TCommentsDetails,
  TProposalDetails,
  TProposalForm,
  CustomerDocuments,
} from "@_types";
import {
  AmountType,
  BALLOON_COLLECTION_ARREAR,
  CalculationResults,
  QuotationForm,
  StepType,
} from "@ntpkunity/controls-common";
import { getOrderStakeholders } from "@helpers/utils";
import { REGEX } from "@helpers/const";

export type GetProposalData = {
  proposal: TProposalForm;
  quotation?: {
    formValues: QuotationForm;
    calculations: CalculationResults;
  };
  runningId?: number;
};

const getFormattedDocuments = (customerDocuments: CustomerDocuments[]) => {
  return customerDocuments.reduce((acc, cur) => {
    let existingDoc = acc.find(
      (doc) => doc.type === cur.document_type && doc.name === cur.document_name
    );

    if (existingDoc) {
      if (cur.document_reference_id) {
        existingDoc.documents.push({
          identifier: cur.identifier,
          previewUrl: cur.document_url,
          key: cur.document_reference_id,
          file: { size: cur.document_size },
        });
      }
    } else {
      acc.push({
        type: cur.document_type,
        name: cur.document_name,
        isMandatory: cur.is_mandatory,
        identifier: cur.document_reference_id ? undefined : cur.identifier,
        documents: cur.document_reference_id
          ? [
              {
                identifier: cur.identifier,
                file: { size: cur.document_size },
                previewUrl: cur.document_url,
                key: cur.document_reference_id,
              },
            ]
          : [],
      });
    }

    return acc;
  }, []);
};

export const useGetProposal = (referenceNumber: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_PROPOSAL, referenceNumber],
    queryFn: async () => getProposalByReferenceNumber(referenceNumber),
    enabled: !!referenceNumber,
    select: mapProposalPayload,
  });
};

export const mapProposalPayload = (
  data: GetProposalResponse
): GetProposalData => {
  const orderStakeholders = getOrderStakeholders(data.order_stakeholders);
  const getAssetCondition = (condition: string[] | null) => {
    return condition && condition.length ? condition[0] : undefined;
  };

  const userName = data.customer_info.email?.split("@")[0];
  const startDate = data.start_date?.split("T")[0];
  const endDate = data.end_date?.split("T")[0];
  const proposalDetails: TProposalDetails = {
    name: data.name,
    dealerId: data.dealer_id,
    brokerId: data.broker_id,
    dealerName: orderStakeholders.dealerName,
    lenderName: orderStakeholders.lenderName,
    brokerName: data.introducer_name,
    financeType: data.finance_type,
    productType: data.product_type,
  };

  const customerInfo = data.customer_info;
  const customerAddress = customerInfo.customer_addresses?.[0];
  const directorDetails: TDirector[] =
    customerInfo.customer_partners?.map((director) => {
      return {
        identifier: director.identifier,
        firstName: director.first_name,
        lastName: director.last_name,
        emailAddress: director.email,
        contactNumber: director.contact_number,
        address: director.address.address_line_1,
        role: director.role,
      };
    }) || [];
  const customerDetails: TCustomerDetails = {
    identifier: customerInfo.identifier,
    clientType: customerInfo.customer_type,
    firstName: customerInfo.first_name,
    lastName: customerInfo.last_name,
    idNumber: customerInfo.id_number,
    emailAddress: REGEX.UUID.test(userName) ? "" : customerInfo.email,
    contactNumber: customerInfo.mobile_number,
    companyName: customerInfo.company_name,
    companyRegNum: customerInfo.company_registration_number,
    tradingAs: customerInfo.trading_as,
    natureOfBusiness: customerInfo.nature_of_business,
    yearsInBusiness: customerInfo.year_business_established,
    sicCode: customerInfo.sic_code,
    directorDetails: directorDetails,
    address: {
      identifier: customerAddress?.identifier,
      addressLine1: customerAddress?.address_line_1,
      addressLine2: customerAddress?.address_line_2,
      city: customerAddress?.city,
      zipCode: customerAddress?.zip_code,
      county: customerAddress?.county,
    },
  };

  let assets: TAssetDetails[] = [];
  const orderAssets = data.order_asset;
  if (orderAssets) {
    if (Array.isArray(orderAssets)) {
      assets = orderAssets.map((asset) => {
        return {
          identifier: asset.identifier,
          category: asset.category,
          type: asset.asset_type,
          subType: asset.asset_sub_type,
          condition: getAssetCondition(asset.condition),
          cost: asset.unit_price,
          rvBalloonAmount: asset.rv_balloon_amount,
          description: asset.description,
          supplierName: asset.supplier_name,
          quantity: asset.quantity,
          totalCost: asset.total,
          totalRvBalloonAmount:
            Number(asset.quantity || 0) * Number(asset.rv_balloon_amount || 0),
          age: asset.age,
        };
      });
    } else {
      // This is for Quotation Converted to Proposal.
      // TODO: Will be fixed once the backend is done
      if (!orderAssets.category) assets = [];
      else
        assets = [
          {
            identifier: orderAssets.identifier,
            category: orderAssets.category,
            type: orderAssets.asset_type,
            subType: orderAssets.asset_sub_type,
            condition: getAssetCondition(orderAssets.condition as any),
            cost: orderAssets.unit_price,
            rvBalloonAmount: orderAssets.rv_balloon_amount,
            description: orderAssets.description,
            supplierName: orderAssets.supplier_name,
            quantity: orderAssets.quantity,
            totalCost: orderAssets.total,
            age: orderAssets.age,
            totalRvBalloonAmount:
              Number(orderAssets.quantity || 0) *
              Number(orderAssets.rv_balloon_amount || 0),
          },
        ];
    }
  }

  const customerDocuments = customerInfo.customer_documents;
  let documents: TDocumentDetails[];
  if (customerDocuments && Array.isArray(customerDocuments)) {
    documents = getFormattedDocuments(customerDocuments);
  }

  let comments: TCommentsDetails[] = [];
  const orderComments = data.order_comments;
  if (orderComments) {
    if (Array.isArray(orderComments)) {
      comments = orderComments.map((comment) => {
        return {
          identifier: comment.identifier,
          orderReferenceId: comment.order_reference_id,
          customerId: comment.customer_id,
          comment: comment.comment,
          category: comment.category,
          createdAt: comment.created_at,
          createdBy: comment.created_by,
          updatedAt: comment.updated_at,
          updatedBy: comment.updated_by,
        };
      });
    }
  }

  const proposalForm = {
    status: data.status,
    proposalDetails,
    customerDetails,
    assets,
    documents,
    orderComments: comments,
    identifier: data.identifier,
  };

  const quotationFormValues: QuotationForm = {
    solveFor: data.calculations_type,
    financeType: data.finance_type,
    assetCost: data.selling_price,
    deposit: data.down_payment,
    depositType: AmountType.POUND,
    commission: data.commission_amount,
    commissionType: data.commission_type,
    nonVatableAmount: data.non_vatable_amount || 0,
    marginPercentage: 0,
    baseRate: 0,
    rate: data.margin,
    rateType: data.rate_type,
    noOfAdvancePayments: data.no_of_advance_payments,
    noOfRegularPayments: data.no_of_regular_payments,
    balloonPayment: data.rv_balloon_value,
    balloonCollection: BALLOON_COLLECTION_ARREAR.find(
      (collection) => collection.text === data.balloon_collection
    )?.value,
    vatType: data.vat_treatment,
    vatAmount: data.deferral_amount,
    vatNumber: data.tax_amount_number || 0,
    vatDeferredType: data.deferred_type,
    paymentFrequency: data.rental_frequency,
    startDate: startDate,
    periodicPaymentAmount: data.estimated_monthly_payment,
    structureRentalsApplied: false,
    structureStepPaymentsApplied: false,
    structureRentals: [],
    stepPayments: {
      applied: false,
      stepInterval: 0,
      stepPercentage: 0,
      stepType: StepType.STEP_UP,
    },
    fees: data.order_fees.map((fee) => {
      return {
        amount: fee.applied_price,
        type: fee.fee_frequency,
        name: fee.fee_name,
        frequency: fee.fee_occurance,
        isEditable: true,
        firstFeePaymentDueAtStartDate: false,
      };
    }),
  };

  const quotationCalculations: CalculationResults = {
    endDate: endDate,
    commissionAmount: data.commission_amount,
    financeAmount: data.finance_amount,
    rates: {
      grossYield: data.meta_data?.gross_yield,
      netYield: data.meta_data?.net_yield,
      flatRateInclCommission: data.meta_data.flat_rate_commission_inc,
      flatRateExclCommission: data.meta_data.flat_rate_commission_exc,
      apr: data.meta_data.apr,
    },
    sumOfAdvanceRentals: data.sum_of_advance_rentals,
    sumOfFees: data.sum_of_fees,
    sumOfPeriodicInterest: data.sum_of_periodic_interest,
    totalPayables: data.total_payables,
    sumOfRentals: data.sum_of_rentals,
    assetCost: data.selling_price,
    depositAmount: data.down_payment,
    rentalSummary: (
      data.order_payments?.map((payment) => {
        return {
          startTerm: payment.payment_number_from,
          endTerm: payment.payment_number_to,
          rentalAmount: payment.amount,
          rentalType: payment.payment_type,
        };
      }) || []
    ).sort((a, b) => {
      return a.startTerm - b.startTerm;
    }),
    repaymentPlan: [],
  };

  return {
    proposal: proposalForm,
    quotation: {
      formValues: quotationFormValues,
      calculations: quotationCalculations,
    },
    runningId: data.running_id,
  };
};
