import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getDmsDealers } from "@services";
import { DealerProfile } from "@_types";
import { UseQueryOptions } from "react-query";
import { isDealerRole } from "@helpers/utils";
import { AxiosError } from "axios";
import { EntityType } from "@helpers/enum";

type UseGetDealersOptions = Omit<
  UseQueryOptions<undefined, AxiosError, DealerProfile | undefined>,
  "queryKey" | "queryFn" | "enabled"
>;

const useGetDealers = (options: UseGetDealersOptions = {}) => {
  const _isDealerRole = isDealerRole();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_DMS_DEALER, EntityType.DEALER],
    queryFn: () => getDmsDealers(EntityType.DEALER),
    select: (data) => {
      return data?.[0];
    },
    enabled: _isDealerRole,
    ...options,
  });
};

export default useGetDealers;
