import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Pagination,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";

export const DocumentsTable = ({ statuses }) => {
  const theme = useTheme();
  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell>
                  Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Email
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Signatory Status
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {statuses &&
                statuses.map((status) => (
                  <TableRow key={status.color}>
                    <TableCell>Sample Broker Name</TableCell>
                    <TableCell>sample@sample.com</TableCell>
                    <TableCell>
                      <span
                        className={`status-indicator ${status.color}`}
                      ></span>
                      {status.status}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          }
        />
        <Pagination theme={theme} />
      </DataTableWrap>
    </>
  );
};
