import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ComparisonPageContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    ".quotation-tool-container": {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      paddingRight: 24,
      display: "flex",
      alignItems: "stretch",
      ".MuiGrid-container": {
        ".MuiGrid-item": {
          "&:first-child": {
            ".comparison-box": {
              "&:before": {
                display: "none",
              },
            },
          },
        },
      },
      ".quotation-tool-wrap": {
        ".quotation-box": {
          "&.fields-wrap": {
            height: "calc(100vh - 280px)",
            [theme.breakpoints.down("md")]: {
              height: "auto",
            },
            ".box-footer": {
              display: "none",
            },
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        backgroundColor: "transparent",
        paddingRight: 0,
        flexDirection: "column",
      },
    },
    ".comparison-box": {
      transform: "translateZ(0)",
      marginTop: -16,
      position: "relative",
      ".u-toast": {
        width: "calc(100% - 56px)",
        ".MuiSnackbarContent-root": {
          width: "100%",
        },
      },
      "&:before": {
        display: "block",
        content: '" "',
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.divider,
        width: 1,
        height: "calc(100% - 48px)",
        position: "absolute",
        top: 24,
        left: 4,
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      ".divider": {
        borderTop: "1px solid" + theme.palette.grey[100],
      },
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.common.white,
        marginTop: 0,
        borderRadius: theme.shape.borderRadius,
      },
    },
    ".add-new": {
      height: "calc(100vh - 254px)",
      paddingTop: 24,
      paddingLeft: 24,
      ".btn": {
        "&.btn-secondary": {
          border: "1px dashed #808080",
          svg: {
            path: {
              stroke: "#808080",
            },
          },
        },
        "&.with-icon": {
          "&.icon-only": {
            height: "100%",
            padding: 0,
            width: 40,
            [theme.breakpoints.down("md")]: {
              padding: 12,
              width: "100%",
            },
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        height: "auto",
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 287,
    },
  },
}));

const ComparisonPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.comparison-page-content-wrap": {
    ".full-page-alert-wrap": {
      "&.full-page": {
        minHeight: "calc(100vh - 73px)",
      },
    },
  },
}));

export default ComparisonPageWrap;
