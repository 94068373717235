import axios from "axios";
import {
  errorResponseInterceptor,
  requestIntercepter,
  successResponseInterceptor,
} from "./interceptors";

const userApi = axios.create({ baseURL: process.env.UMS_BASE_URL });

userApi.interceptors.request.use(requestIntercepter);
userApi.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
export default userApi;
