import { Button, CircleLoader, Icon, PageAlert } from "@ntpkunity/controls";
import {
  LayoutWithSideNavComponent,
  ComparisonTool,
  Snackbar,
  ConfigurationWrapper as ConfigurationContextProvider,
  QuotationComparisonPageHeader,
} from "@components";
import { useTheme } from "@mui/material";
import ComparisonPageWrap from "./quote-comparison.style";
import { useState } from "react";
import { EvaluationExternalParams } from "@ntpkunity/controls-common";
import {
  useGetCompanyByTenantId,
  useGetDealers,
  useGetComparison,
} from "@hooks";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ERROR_MESSAGES } from "@helpers/const";

const QuoteComparisonPage = () => {
  const theme = useTheme();
  const params = useParams<{ comparisonId?: string }>();
  const comparisonId = params.comparisonId;
  const isEditMode = !!comparisonId;

  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: dealerAssociation } = useGetDealers();
  const [evaluationParams, setEvaluationParams] =
    useState<EvaluationExternalParams>({
      asset_category: "",
      asset_type: "",
      company_code: companyInfo.company_code,
      customer_type: "",
      dealer_code: dealerAssociation?.dealer_code || null,
      credit_rating: "",
    });

  const {
    data: comparisonData,
    isLoading: comparisonIsLoading,
    isError: isErrorInComparison,
    error: comparisonError,
  } = useGetComparison({
    referenceNumber: comparisonId,
    enabled: isEditMode,
  });

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ComparisonPageWrap
        theme={theme}
        className="comparison-page-content-wrap"
      >
        <ConfigurationContextProvider>
          {comparisonIsLoading ? (
            <CircleLoader theme={theme} />
          ) : isErrorInComparison ? (
            <ComparisonPageAlert error={comparisonError} />
          ) : (
            <>
              <ComparisonTool
                renderHeader={({
                  selectedQuotationRef,
                  disableBtns,
                  quotationRefs,
                }) => (
                  <QuotationComparisonPageHeader
                    selectedQuotationRef={selectedQuotationRef}
                    quotationRefs={quotationRefs}
                    disableBtns={disableBtns}
                    setEvaluationParams={setEvaluationParams}
                    defaultFormValues={
                      comparisonData?.[0].brokerSpecificFormValues
                    }
                  />
                )}
                evaluationParams={evaluationParams}
                comparisonData={comparisonData}
              />
              <Snackbar />
            </>
          )}
        </ConfigurationContextProvider>
      </ComparisonPageWrap>
    </LayoutWithSideNavComponent>
  );
};

export default QuoteComparisonPage;

const ComparisonPageAlert = ({ error }: { error: AxiosError }) => {
  const is404 = error.status === 404;
  const theme = useTheme();
  const navigate = useNavigate();

  const errorTitle = is404
    ? ERROR_MESSAGES.NOT_FOUND_404
    : ERROR_MESSAGES.UNEXPECTED_ERROR;
  const errorMessage = is404
    ? (error.response.data as any).detail
    : "We encountered an unexpected error. Try refreshing the page.";

  return (
    <PageAlert
      theme={theme}
      icon="DialogInfoIcon"
      title={errorTitle}
      variant="full-page"
      message={errorMessage}
      actionArea={
        <>
          <Button
            theme={theme}
            fullWidth
            secondary
            startIcon={<Icon name="IconLeftArrow" />}
            text="Go Back"
            onClick={() => navigate(-1)}
          />
          {!errorMessage && (
            <Button
              theme={theme}
              fullWidth
              danger
              startIcon={<Icon name="ResetIcon" />}
              text="Refresh Page"
              onClick={() => window.location.reload()}
            />
          )}
        </>
      }
    />
  );
};
