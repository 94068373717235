import axios from "axios";
import {
  requestIntercepter,
  errorResponseInterceptor,
  successResponseInterceptor,
} from "./interceptors";

const dmsAPI = axios.create({ baseURL: process.env.REACT_APP_DMS_URL });

dmsAPI.interceptors.request.use(requestIntercepter);
dmsAPI.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
export default dmsAPI;
