import { useMutation, UseMutationOptions } from "react-query";
import { saveFinanceConfigurations } from "@services/index.service";
import { updateUmsPermissions } from "@services/ums.service";
import { queryKeyFactory } from "@helpers/query";
import {
  SaveFinanceConfigReq,
  SaveUmsPermissionsVariables,
} from "@_types/index";

type SaveFinanceConfigOptions = Omit<
  UseMutationOptions<any, unknown, SaveFinanceConfigReq>,
  "mutationFn" | "mutationKey"
>;

export const useSaveFinancialConfigurations = (
  options?: SaveFinanceConfigOptions
) => {
  return useMutation({
    mutationKey: queryKeyFactory.saveFinanceConfig(),
    mutationFn: saveFinanceConfigurations,
    ...(options || {}),
  });
};

type UpdateUmsPermissionsOptions = Omit<
  UseMutationOptions<any, unknown, SaveUmsPermissionsVariables>,
  "mutationKey" | "mutationFn"
>;

export const useUpdateUmsPermissions = (
  options?: UpdateUmsPermissionsOptions
) => {
  return useMutation({
    mutationKey: queryKeyFactory.saveUmsPermissions(),
    mutationFn: updateUmsPermissions,
    ...(options || {}),
  });
};
