import { Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import {
  DashboardPage,
  ProposalPage,
  WorkQueuePage,
  QuotationPage,
  QuoteComparisonPage,
  DiaryPage,
  DocumentPage,
  SendForPayoutPage,
  SignInPage,
  CustomerProposalPage,
  SignatoryStatusPage,
  LenderDashboardPage,
  FinancialConfigurationsPage,
  ComplianceDocumentPage,
  SignInPageVirginMoney,
  ManageUsersPage,
} from "@pages";
import { useEffect } from "react";
import { removeSessionStorageItem } from "@helpers/utils";
import { SessionStorageKeys } from "@helpers/enum";
import { Validate } from "@ntpkunity/controls-ums";
import { UserPermissions } from "@helpers/enum";

export const Router = () => {
  useEffect(() => {
    // INFO: SessionStorage persists on page reloads. This effect will
    // remove items from Session Storage on page reloads.
    const deleteSessionStorage = () => {
      removeSessionStorageItem(SessionStorageKeys.QUOTATION_INPUTS);
    };
    window.addEventListener("beforeunload", deleteSessionStorage);
    return () => {
      window.removeEventListener("beforeunload", deleteSessionStorage);
    };
  });

  return (
    <>
      <Routes>
        <Route path={APP_ROUTES.BASE_URL} element={<DashboardPage />} />
        <Route path={APP_ROUTES.DASHBOARD} element={<DashboardPage />} />
        {/* 
          --------------------------
          ---- QUOTATION ROUTES ---- 
          --------------------------
        */}
        <Route
          path={APP_ROUTES.QUOTATION}
          element={<QuotationPage key="create-quotation" />}
        />
        <Route
          path={`${APP_ROUTES.QUOTATION}/:quotationId`}
          element={<QuotationPage key="edit-quotation" />}
        />
        <Route
          path={APP_ROUTES.QUOTATION_COMPARISON}
          element={<QuoteComparisonPage />}
        />
        {/* 
          ---------------------------
          ----- PROPOSAL ROUTES ----- 
          ---------------------------
        */}
        <Route path={APP_ROUTES.PROPOSAL} element={<ProposalPage />} />
        <Route
          path={`${APP_ROUTES.PROPOSAL}/:mode/:proposalId`}
          element={<ProposalPage />}
        />
        <Route
          path={`${APP_ROUTES.DIARY}/:proposalId`}
          element={<DiaryPage />}
        />
        <Route
          path={`${APP_ROUTES.COMPLIANCE_DOCUMENT}/:proposalId`}
          element={<ComplianceDocumentPage />}
        />
        {/* 
          ----------------------------
          ----- WORKQUEUE ROUTES ----- 
          ----------------------------
        */}
        <Route
          path={`${APP_ROUTES.WORKQUEUE}/:workQueueTab`}
          element={<WorkQueuePage />}
        />
        {/* 
          --------------------------------
          ----- CONFIGURATION ROUTES ----- 
          --------------------------------
        */}
        <Route
          path={APP_ROUTES.FINANCIAL_CONFIGURATIONS}
          element={
            <Validate
              permission={UserPermissions.ADMIN_OPTION_CONFIGURATIONS}
              url={APP_ROUTES.BASE_URL}
            >
              <FinancialConfigurationsPage />
            </Validate>
          }
        />
        <Route
          path={APP_ROUTES.USER_MANAGEMENT}
          element={
            <Validate
              permission={UserPermissions.USER_MANAGEMENT}
              url={APP_ROUTES.BASE_URL}
            >
              <ManageUsersPage />
            </Validate>
          }
        />
        {/* 
          -------------------------
          ----- STATIC ROUTES ----- 
          -------------------------
        */}
        <Route
          path={APP_ROUTES.LENDER_DASHBOARD}
          element={
            <Validate
              permission={UserPermissions.LENDER_DASHBOARD}
              url={APP_ROUTES.BASE_URL}
            >
              <LenderDashboardPage />
            </Validate>
          }
        />
        <Route path={APP_ROUTES.DOCUMENT} element={<DocumentPage />} />
        <Route
          path={APP_ROUTES.SEND_FOR_PAYOUT}
          element={<SendForPayoutPage />}
        />
        <Route path={APP_ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route
          path={APP_ROUTES.SIGN_IN_VIRGIN_MONEY}
          element={<SignInPageVirginMoney />}
        />
        <Route
          path={APP_ROUTES.CUSTOMER_PROPOSAL}
          element={<CustomerProposalPage />}
        />
        <Route
          path={APP_ROUTES.SIGNATORY_STATUS}
          element={<SignatoryStatusPage />}
        />

        <Route path={APP_ROUTES.UNKNOWN_URL} element={<DashboardPage />} />
      </Routes>
    </>
  );
};
