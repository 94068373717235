import {
  NewQuotation,
  useQuotationFormContext,
  ActionButtons,
  QuotationForm,
  useCalculationsActionsContext,
  QuotationControlMode,
  EvaluationExternalParams,
  ExternalDataContextProvider,
} from "@ntpkunity/controls-common";
import {
  SnackbarContextProvider,
  useSnackbarContext,
} from "@contexts/snackbar";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { SessionStorageKeys } from "@helpers/enum";
import { isDealerRole, setSessionStorageItem } from "@helpers/utils";
import { useDialogState, useSetupsSelector } from "@hooks";
import { FullWindowDialog, ComparisonTool, Snackbar } from "@components";

type QuotationToolProps = {
  useComparisonDialog?: boolean;
  evaluationParams: EvaluationExternalParams;
};

export const QuotationTool = ({
  useComparisonDialog = false,
  evaluationParams,
}: QuotationToolProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbarContext();
  const { reset, getValues, watch } = useQuotationFormContext<QuotationForm>();
  const _financeType = getValues()?.financeType;
  const isViewMode = watch("isViewMode");

  const { setCalculationResults, setCorporateTax, setVat } =
    useCalculationsActionsContext();
  const { financeTypes } = useSetupsSelector((state) =>
    state.getFilteredFinanceTypes(_financeType)
  );

  const {
    openDialog: openComparisonDialog,
    closeDialog: closeComparisonDialog,
    dialogOpen: comparisonDialogOpened,
  } = useDialogState();
  const _isDealerRole = isDealerRole();

  const handleComparisonClick = () => {
    setSessionStorageItem<QuotationForm>(
      SessionStorageKeys.QUOTATION_INPUTS,
      getValues()
    );
    if (useComparisonDialog) {
      openComparisonDialog();
      return;
    }
    navigate(APP_ROUTES.QUOTATION_COMPARISON);
  };

  return (
    <>
      <ExternalDataContextProvider
        financeTypes={financeTypes}
        evaluationParams={evaluationParams}
      >
        <NewQuotation
          mode={
            _isDealerRole
              ? QuotationControlMode.DEALER
              : QuotationControlMode.BROKER
          }
          theme={theme}
          resultsActionArea={() => (
            <ActionButtons
              theme={theme}
              isViewMode={isViewMode}
              onCalculateError={(error) => {
                if (typeof error === "string") {
                  return setSnackbar({
                    open: true,
                    message: error,
                    variant: "error",
                  });
                }
                const errorMessage = error.response.data?.Message;
                setSnackbar({
                  open: true,
                  message:
                    errorMessage ||
                    "Error Occurred while Calculating Quotation",
                  variant: "error",
                });
              }}
              onCalculateSuccess={() => {
                reset(getValues());
                setSnackbar(
                  { open: true, message: "Quotation Calculated Successfully" },
                  2000
                );
              }}
              handleComparisonClick={handleComparisonClick}
            />
          )}
        />
      </ExternalDataContextProvider>
      <FullWindowDialog
        open={comparisonDialogOpened}
        onClose={closeComparisonDialog}
      >
        <SnackbarContextProvider>
          <ComparisonTool
            useDialogMode
            saveSelectionCallback={({ selectedQuotationRef }) =>
              async () => {
                const refHandle = selectedQuotationRef.ref.current;
                reset(refHandle.getQuotationFormContext().getValues());
                const calculationsContext = refHandle.getCalculationsContext();
                setCalculationResults(calculationsContext.data);
                setCorporateTax(calculationsContext.corporateTax?.taxAmount);
                setVat(calculationsContext.vat?.details);
                closeComparisonDialog();
              }}
            evaluationParams={evaluationParams}
          />
          <Snackbar />
        </SnackbarContextProvider>
      </FullWindowDialog>
    </>
  );
};
