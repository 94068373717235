import { Grid } from "@ntpkunity/controls";
import { ProposalFieldView } from "./proposal-field-view.component";
import { TproposalBasicDetail } from "../types";
import { useTheme } from "@mui/material";

export const ProposalDetailsList = ({
  details,
}: {
  details: TproposalBasicDetail[];
}) => {
  const theme = useTheme();
  return (
    <Grid container item columnSpacing={{ xs: 4, md: 8 }} theme={theme}>
      {details.map((field, index) => (
        <ProposalFieldView
          key={index}
          label={field.label}
          value={field.value}
        />
      ))}
    </Grid>
  );
};
