import axios from "axios";
import {
  requestIntercepter,
  successResponseInterceptor,
  errorResponseInterceptor,
} from "./interceptors";

export const flexReportingAPI = axios.create({
  baseURL: process.env.FLEX_REPORTING_BASE_URL,
});

flexReportingAPI.interceptors.request.use(requestIntercepter);
flexReportingAPI.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
