import { Footer, useWorkflowContext } from "@components";
import { useSnackbarContext } from "@contexts/snackbar";
import { OrderStatus, ProposalMode } from "@helpers/enum";
import {
  useGetAllActiveDealers,
  useSubmitProposal,
  useGetSaveProposalPayload,
  useGetAllLenders,
} from "@hooks";
import { useParams } from "react-router-dom";
import { ProposalFooterProps } from "./proposal-footer.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { isNextStatusAllowed } from "@helpers/utils";
import { isDealerRole } from "@helpers/utils";

const ProposalFooter = ({
  activeIndex,
  onNextButtonClick,
  onPrevButtonClick,
  handlePostSubmission,
  getNextButtonText,
}: ProposalFooterProps) => {
  const params = useParams<{ proposalId?: string; mode?: string }>();
  const isEditMode = params.proposalId;

  const { getValues, setValue, watch } = useFormContext<TProposalForm>();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );
  const { mutateAsync: submitProposal } = useSubmitProposal();
  const { setSnackbar } = useSnackbarContext();
  const {
    meta_data: { tabs },
  } = useWorkflowContext();

  const { data: lenders = [] } = useGetAllLenders();
  const { data: dealers = [] } = useGetAllActiveDealers();
  const currentStatus = watch("status");
  const mode = params.mode;
  const isViewMode = mode === ProposalMode.VIEW;
  const selectedLender = watch("proposalDetails.lenderName");
  const selectedDealer = watch("proposalDetails.dealerName");
  const _isDealerRole = isDealerRole();

  const lenderData = lenders.find((lender) => lender.name === selectedLender);
  const dealerData = dealers.find(
    (dealer) => dealer.dealer_name === selectedDealer
  );

  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex + 1 === tabs.length;

  const handleSubmitProposal = async () => {
    const missingMandatoryDocs = getValues().documents?.filter(
      (doc) => doc.isMandatory && doc.documents?.length == 0
    );
    if (missingMandatoryDocs?.length > 0) {
      setSnackbar({
        open: true,
        message: "Mandatory Documents are Missing",
        variant: "error",
      });
      return;
    }
    setSnackbar({ open: true, message: "Submitting Proposal" });
    const payload = getPayload(
      _isDealerRole ? OrderStatus.NEW : OrderStatus.SUBMITTED,
      lenderData,
      dealerData
    );

    if (!validateProposalPayload(payload)) return;

    try {
      const proposal = await submitProposal({
        payload: payload,
        identifier: params.proposalId,
      });
      setValue("identifier", proposal.identifier);
      setSnackbar(
        { open: true, message: "Proposal Submitted Successfully" },
        2000
      );
      handlePostSubmission();
    } catch (err) {
      setSnackbar({
        open: true,
        message: "There was an error while submitting proposal",
      });
    }
  };

  const handleNextButton = () =>
    isLastStep ? handleSubmitProposal() : onNextButtonClick();

  const nextStatusNewOrSubmitted = isNextStatusAllowed(
    currentStatus,
    isDealerRole() ? OrderStatus.NEW : OrderStatus.SUBMITTED
  );

  const shouldHideActionButton =
    isLastStep && ((isEditMode && !nextStatusNewOrSubmitted) || isViewMode);

  return (
    <Footer
      actionButtonText={getNextButtonText()}
      actionButtonOnClick={handleNextButton}
      showBackButton={!isFirstStep}
      backButtonOnClick={onPrevButtonClick}
      shouldShowActionButton={!shouldHideActionButton}
    />
  );
};

export default ProposalFooter;
