import { StepperProgress } from "@components";
import {
  ProposalSummary,
  AssetDetails,
  ProposalDetails,
  CreditDocuments,
  FinanceDetails,
  ProposalFooter,
} from "@components/proposal";
import { useTheme } from "@mui/material";
import ProposalPageHeader from "@components/proposal/page-header/page-header.component";
import { useState, useEffect, useMemo } from "react";
import { Button, Icon } from "@ntpkunity/controls";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProposalSchema } from "@models";
import { TDocumentDetails, TProposalForm } from "@_types";
import {
  useForm,
  useFieldArray,
  FormProvider,
  DefaultValues,
} from "react-hook-form";
import { ProposalFieldArraysProvider } from "@contexts/proposal-form";
import { ProposalProps } from "./proposal.props";
import { useClientTypesSetup, useProposalStepItems } from "@hooks/proposal";

import {
  CalculationResults,
  CalculationsContextProvider,
  QuotationFormProvider,
  useCalculationsActionsContext,
  VatDetails,
} from "@ntpkunity/controls-common";
import { OrderStatus, ProposalMode, ProposalTabs } from "@helpers/enum";
import { useLocation, useParams } from "react-router-dom";
import { RouterState } from "@pages/proposal/proposal-page";
import { useWorkflowContext } from "@components/workflows";
import { useGetDealers, useGetDocumentTypes } from "@hooks/queries";
import {
  useGetClientTypeName,
  useGetFinanceTypeName,
} from "@hooks/useSetupNames";
import { getEntityTypeDealer, isDealerRole } from "@helpers/utils";
import { useFilteredFinanceTypes } from "@hooks";
export const Proposal = ({ proposalData }: ProposalProps) => {
  const isEditMode = !!proposalData;
  const financeType = isEditMode
    ? proposalData.proposal.proposalDetails.financeType
    : undefined;

  const clientTypes = useClientTypesSetup();
  const { financeTypes } = useFilteredFinanceTypes(financeType);
  const { data: dealerAssociation } = useGetDealers(getEntityTypeDealer());
  const location = useLocation();
  const {
    meta_data: { tabs },
  } = useWorkflowContext();

  const routerState: RouterState = location.state;
  const defaultTab = routerState?.tab;
  const defaultIndex = tabs.findIndex((tab) => tab === defaultTab);
  const params = useParams<{ mode: ProposalMode }>();
  const isViewMode = params.mode == ProposalMode.VIEW;
  const PROPOSAL_FORM_INITIAL_STATE: DefaultValues<TProposalForm> = {
    status: OrderStatus.DRAFT,
    proposalDetails: {
      financeType: financeTypes[0].code,
      dealerName:
        isDealerRole() && dealerAssociation?.[0]
          ? dealerAssociation?.[0]?.dealer_name
          : undefined,
    },
    customerDetails: {
      clientType: clientTypes.length ? clientTypes[0].code : undefined,
      directorDetails: [],
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        county: "",
      },
    },
  };

  const defaultValues = useMemo(
    () => (isEditMode ? proposalData.proposal : PROPOSAL_FORM_INITIAL_STATE),
    [clientTypes.length]
  );
  const formMethods = useForm<TProposalForm>({
    defaultValues: {
      ...defaultValues,
      isViewMode: isViewMode,
    },
    resolver: zodResolver(ProposalSchema),
  });
  const directorFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "customerDetails.directorDetails",
  });
  const assetFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "assets",
  });
  const documentsFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "documents",
  });

  const {
    activeIndex,
    setActiveIndex,
    stepItems,
    goToNextTab,
    goToPrevTab,
    getNextButtonText,
    activateTab,
  } = useProposalStepItems({
    watch: formMethods.watch,
    defaultIndex: Math.max(defaultIndex, 0),
  });
  const financeCode: string = formMethods.watch("proposalDetails.financeType");
  const clientType = formMethods.watch("customerDetails.clientType");
  const { data: documentTypes } = useGetDocumentTypes();
  const clientTypeName = useGetClientTypeName(clientType);
  const financeTypeName = useGetFinanceTypeName(financeCode);

  const documentSetupData = documentTypes?.filter(
    (document) =>
      document?.customer_type?.includes(clientTypeName) &&
      document?.finance_type?.includes(financeTypeName)
  );

  useEffect(() => {
    if (
      Array.isArray(documentSetupData) &&
      stepItems.some((step) => step.title === ProposalTabs.CREDIT_DOCUMENTS)
    ) {
      const currentDocsTypes = documentsFieldArrayMethods.fields?.map(
        (doc: TDocumentDetails) => doc?.type
      );
      const newDocTypes = documentSetupData.map((doc) => doc.description);
      documentSetupData.forEach((doc) => {
        if (!currentDocsTypes.includes(doc.description)) {
          documentsFieldArrayMethods.append({
            type: doc.description,
            isMandatory: doc.state,
            id: doc.id,
            documents: [],
            name: "",
          });
        }
      });
      const indexesToRemove = [];
      documentsFieldArrayMethods.fields.forEach((cur) => {
        if (!newDocTypes.includes(cur.type)) {
          indexesToRemove.push(
            documentsFieldArrayMethods.fields.findIndex(
              (doc) => doc.type === cur.type
            )
          );
        }
      });

      if (indexesToRemove.length) {
        documentsFieldArrayMethods.remove(indexesToRemove);
      }
    }
  }, [clientTypeName, financeTypeName]);

  const [proposalSubmitted, setProposalSubmitted] = useState(false);

  const { fields: assets } = assetFieldArrayMethods;
  const sumOfAssetCost = assets.reduce(
    (total, asset) => total + Number(asset.totalCost),
    0
  );
  const sumOfRvBalloonAmount = assets.reduce(
    (total, asset) => total + Number(asset.totalRvBalloonAmount),
    0
  );

  const handlePostSubmission = () => {
    setProposalSubmitted(true);
    activateTab(ProposalTabs.SUMMARY);
  };

  return (
    <CalculationsContextProvider>
      <QuotationFormProvider
        isViewMode={isViewMode}
        defaultValues={proposalData?.quotation?.formValues}
        retainValues={{
          assetCost: sumOfAssetCost > 0,
          balloonPayment: sumOfRvBalloonAmount > 0,
        }}
      >
        <FormProvider {...formMethods}>
          <ProposalFieldArraysProvider
            value={{
              directorMethods: directorFieldArrayMethods,
              assetMethods: assetFieldArrayMethods,
              documentMethods: documentsFieldArrayMethods,
            }}
          >
            <ProposalPageHeader
              isLastStep={activeIndex + 1 == tabs.length}
              showActionBtn={!proposalSubmitted}
            />
            <ProposalStepperProgress
              corporateTaxAmount={proposalData?.quotation?.corporateTaxAmount}
              vatDetails={proposalData?.quotation?.vatDetails}
              calculations={proposalData?.quotation?.calculations}
              proposalSubmitted={proposalSubmitted}
              stepItems={stepItems}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              goToPrevTab={goToPrevTab}
              getNextButtonText={getNextButtonText}
            />

            {!proposalSubmitted && (
              <ProposalFooter
                activeIndex={activeIndex}
                onNextButtonClick={goToNextTab}
                onPrevButtonClick={goToPrevTab}
                handlePostSubmission={handlePostSubmission}
                getNextButtonText={getNextButtonText}
              />
            )}
          </ProposalFieldArraysProvider>
        </FormProvider>
      </QuotationFormProvider>
    </CalculationsContextProvider>
  );
};

const ProposalStepperProgress = ({
  corporateTaxAmount,
  vatDetails,
  calculations,
  proposalSubmitted,
  stepItems,
  setActiveIndex,
  activeIndex,
  goToPrevTab,
  getNextButtonText,
}: {
  corporateTaxAmount: number;
  vatDetails: VatDetails;
  calculations: CalculationResults;
  proposalSubmitted: boolean;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  stepItems: {
    title: string;
    disabled: boolean;
  }[];
  goToPrevTab: () => void;
  getNextButtonText: () => string;
}) => {
  const theme = useTheme();

  const TAB_TO_COMPONENT_MAP = {
    [ProposalTabs.PROPOSAL_DETAILS]: <ProposalDetails key="proposal-details" />,
    [ProposalTabs.ASSET_DETAILS]: <AssetDetails key="asset-details" />,
    [ProposalTabs.FINANCE_DETAILS]: <FinanceDetails key="finance-details" />,
    [ProposalTabs.CREDIT_DOCUMENTS]: <CreditDocuments key="credit-documents" />,
    [ProposalTabs.SUMMARY]: (
      <ProposalSummary proposalSubmitted={proposalSubmitted} key="summary" />
    ),
  };

  const {
    meta_data: { tabs },
  } = useWorkflowContext();

  const stepperChildren = tabs.map(
    (tab: ProposalTabs) => TAB_TO_COMPONENT_MAP[tab]
  );

  const { setCalculationResults, setCorporateTax, setVat } =
    useCalculationsActionsContext();
  useEffect(() => {
    if (calculations) {
      setCalculationResults(calculations);
      setCorporateTax(corporateTaxAmount);
      setVat(vatDetails);
    }
  }, []);

  return (
    <>
      <StepperProgress
        stepItems={stepItems}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        onPrevButtonClick={goToPrevTab}
        stepTitle="Proposal Details"
        nextStepTitle={getNextButtonText()}
        hideStepItems={proposalSubmitted}
        actionArea={
          <>
            <Button
              defaultBtn
              theme={theme}
              onClick={() => {}}
              iconText={<Icon name="SaveDiskIcon" />}
            />
          </>
        }
      >
        {stepperChildren.map((component) => component)}
      </StepperProgress>
    </>
  );
};
