import { hexToRgb, styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const InventoryControlWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.inventory-control-wrap": {
        '.stack-wrap': {
            padding: '24px 24px 24px 8px'
        },
        '.asset-list-view-wrap': {
            padding: '0 0 16px',
            '.vehicle-card': {
                border: '1px solid' + theme.palette.grey[100]
            }
        },
        '.brand-wrap': {
            display: 'none'
        },
        '.desktop-tab': {
            marginTop: 0
        },
        '.brand-search-tabs-filter': {
            '.scroll': {
                borderBottom: '1px solid' + theme.palette.grey[100],
                height: 'calc(100vh - 235px)',
                maxHeight: 'calc(100vh - 235px)',
                '.filter-item': {
                    '.u-accordian-wrap': {
                        backgroundColor: 'transparent',
                        '.u-accordian-body': {
                            padding: '0px 24px 16px',
                            '.checks-wrap': {
                                '.u-form-group': {
                                    '.MuiInputLabel-root': {
                                        backgroundColor: theme.palette.common.white
                                    }
                                }
                            }
                        }
                    }
                }
            },
            '.filters-cta': {
                marginTop: 24
            }
        },
        '.filters-control': {
            paddingBottom: 16,
            paddingTop: 8,
            borderBottom: '1px solid' + theme.palette.grey[100],
            '.select': {
                '.u-form-group': {
                    minWidth: 'auto',
                    '.u-form-control': {
                        '&.u-select': {
                            height: 'auto !important'
                        }
                    }
                }
            }
        },
        '.cj-tab-wrap': {
            ".u-scrollable-tabs": {
                ".tab-list-underline": {
                    borderBottom: "none",
                    backgroundColor: "#F6F7F9",
                    borderRadius: theme.shape.borderRadius,
                    minHeight: 40,
                },
                ".MuiTabs-flexContainer": {
                    padding: 2,
                },
                ".u-tab-btn": {
                    padding: "8px 20px",
                    color: theme.palette.grey[700],
                    borderRadius: 6,
                    fontSize: theme.typography.body2.fontSize,
                    lineHeight: theme.typography.body2.lineHeight,
                    fontFamily: theme.typography.fontFamily,
                    whiteSpace: "nowrap",
                    position: "relative",
                    marginRight: 0,
                    flexDirection: "row",
                    gap: 8,
                    minHeight: 40,
                    flexGrow: 1,
                    maxWidth: "100%",
                    "&.Mui-selected": {
                        backgroundColor: theme.palette.common.white,
                        fontWeight: theme.typography.fontWeightBold,
                        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
                        color: theme.palette.grey[900],
                    },
                },
                ".MuiTabs-indicator": {
                    display: "none",
                },
            },
        }
    },
}));

export const FullHeightDialogWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.full-height-dialog-wrap": {
      '.u-dialog': {
        '&.full-window-dialog': {
            [theme.breakpoints.down("md")]: {
                maxHeight: 'calc(100vh - 80px)',
                top: 80
            }
        }
      }
    },
}));
