import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, Button, DataTable, Typography } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { PaginationWrap } from "@styles/pagination-wrap-style";
import { ProposalTableProps } from "./proposal-table.props";
import { useGetDealers, useGetOrders } from "@hooks/queries";
import {
  ManualStatusChangeData,
  TProposalSearchParams,
  TSortParams,
  StatusConfirmationData,
  TProposalWorkQueueEntry,
  DiaryDialogData,
} from "@_types";
import { Roles, OrderStage, SortOrder, WorkQueueTabs } from "@helpers/enum";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  convertIsoDateStrToddmmyyyy,
  getEntityTypeDealer,
  getUserDataFromToken,
  getUserSettings,
} from "@helpers/utils";
import { useSnackbarContext } from "@contexts/snackbar";
import ProposalTableRow from "./proposal-table-row/proposal-table-row.component";
import ProposalFilter from "./proposal-filter/proposal-filter.component";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import {
  DRAFT_QUEUE_STATUSES,
  TRACK_QUEUE_STATUSES,
  QUEUE_TO_STATUS_MAP,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "@helpers/const";
import { ManualStatusChangeDialog } from "../manual-status-change-dialog/manual-status-change-dialog.component";
import { ViewConditionDialog } from "../view-condition-dialog/view-condition-dialog.component";
import { useDialogState } from "@hooks";
import TablePagination from "@mui/material/TablePagination";
import { StatusConfirmationDialog } from "../status-confirmation-dialog/status-confirmation-dialog.component";
import { DiaryDialog } from "@components/diary/diary-dialog/diary-dialog";

const sortInitialState: TSortParams<keyof TProposalSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

export const ProposalTable = ({
  hasCreateNew = true,
  queue,
}: ProposalTableProps) => {
  const searchInitialState: TProposalSearchParams = {
    name: "",
    finance_type: "",
    finance_amount: null,
    updated_at: "",
    order_stage: OrderStage.APPLICATION,
    customer_name: "",
    date_submitted: "",
    start_date: "",
    end_date: "",
    introducer_name: "",
    lender_name: "",
    dealer_name: "",
    updated_at_from: "",
    updated_at_to: "",
    dealer_id: null,
    broker_id: null,
    statuses: QUEUE_TO_STATUS_MAP[queue],
    page_number: DEFAULT_PAGE_NUMBER,
    page_size: DEFAULT_PAGE_SIZE,
  };
  const { data: dealerAssociation } = useGetDealers(getEntityTypeDealer());
  const userSettings = getUserSettings();
  const brokerId =
    userSettings?.role?.name == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : null;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] =
    useState<TProposalSearchParams>(searchInitialState);
  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TProposalSearchParams>>(sortInitialState);
  const {
    dialogOpen: statusDialogOpened,
    dialogData: statusDialogData,
    openDialog: openStatusDialog,
    closeDialog: closeStatusDialog,
  } = useDialogState<ManualStatusChangeData>();

  const {
    dialogOpen: confirmDialogOpened,
    dialogData: confirmDialogData,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialogState<StatusConfirmationData>();

  const _closeConfirmationDialog = () => {
    closeConfirmDialog();
    closeStatusDialog();
  };

  const {
    dialogOpen: conditionDialogOpened,
    openDialog: openConditionDialog,
    closeDialog: closeConditionDialog,
  } = useDialogState();

  const {
    dialogOpen: diaryDialogOpened,
    dialogData: diaryDialogData,
    openDialog: openDiaryDialog,
    closeDialog: closeDiaryDialog,
  } = useDialogState<DiaryDialogData>();

  const theme = useTheme();
  const [debouncedSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...searchParams,
        dealer_id: dealerAssociation?.[0]?.id || null,
        broker_id: brokerId,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          searchParams.date_submitted
        ),
        start_date: convertIsoDateStrToddmmyyyy(searchParams.start_date),
        end_date: convertIsoDateStrToddmmyyyy(searchParams.end_date),
      };
    }, [searchParams, dealerAssociation]),
    500
  );

  const { setSnackbar } = useSnackbarContext();
  const {
    isLoading,
    isError,
    data: proposals,
  } = useGetOrders<TProposalSearchParams, TProposalWorkQueueEntry>(
    debouncedSearchParams,
    sortParams
  );
  const isTrackProposal = queue === WorkQueueTabs.TRACK_PROPOSALS;
  const isNotSuccess = isLoading || isError;
  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    if (isError) {
      setSnackbar({
        open: true,
        message: "Error while fetching proposals.",
        variant: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    setSearchParams(searchInitialState);
    setSortParams(sortInitialState);
  }, [queue]);

  const handleChangePage = (event, newPage) => {
    setSearchParams({
      ...searchParams,
      page_number: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams({
      ...searchParams,
      page_number: DEFAULT_PAGE_NUMBER,
      page_size: event.target.value,
    });
  };

  return (
    <DataTableWrap theme={theme} className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Proposal Name</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Finance Amount</TableCell>
              <TableCell>Last Updated</TableCell>
              {isTrackProposal && (
                <>
                  <TableCell>Date Submitted</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </>
              )}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <ProposalFilter
                  {...commonFilterParams}
                  searchKey="statuses"
                  statuses={
                    isTrackProposal
                      ? TRACK_QUEUE_STATUSES
                      : DRAFT_QUEUE_STATUSES
                  }
                />
              </TableCell>
              <TableCell>
                <ProposalFilter {...commonFilterParams} searchKey="name" />
              </TableCell>
              <TableCell>
                <ProposalFilter
                  {...commonFilterParams}
                  searchKey="finance_type"
                />
              </TableCell>
              <TableCell>
                <ProposalFilter
                  {...commonFilterParams}
                  searchKey="customer_name"
                />
              </TableCell>
              <TableCell>
                <ProposalFilter
                  {...commonFilterParams}
                  searchKey="finance_amount"
                  type="number"
                />
              </TableCell>
              <TableCell>
                <ProposalFilter
                  {...commonFilterParams}
                  searchKey="updated_at"
                  placeholder="DD/MM/YYYY"
                />
              </TableCell>
              {isTrackProposal && (
                <>
                  <TableCell>
                    <ProposalFilter
                      {...commonFilterParams}
                      searchKey="date_submitted"
                      placeholder="DD/MM/YYYY"
                    />
                  </TableCell>
                  <TableCell>
                    <ProposalFilter
                      {...commonFilterParams}
                      searchKey="start_date"
                      placeholder="DD/MM/YYYY"
                    />
                  </TableCell>
                  <TableCell>
                    <ProposalFilter
                      {...commonFilterParams}
                      searchKey="end_date"
                      placeholder="DD/MM/YYYY"
                    />
                  </TableCell>
                </>
              )}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {isNotSuccess || !proposals?.results.length ? (
              <TableRow>
                <TableCell colSpan={isTrackProposal ? 10 : 7}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    display="block"
                    textAlign="center"
                  >
                    {isLoading ? "Loading Proposals..." : "No Proposals found."}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              proposals?.results.map((proposal) => (
                <ProposalTableRow
                  key={proposal.identifier}
                  proposal={proposal}
                  queue={queue}
                  openConfirmDialog={openConfirmDialog}
                  openStatusDialog={openStatusDialog}
                  openConditionDialog={openConditionDialog}
                  openDiaryDialog={openDiaryDialog}
                />
              ))
            )}
          </>
        }
      />
      {proposals && (
        <PaginationWrap theme={theme} className="pagination-wrap">
          <TablePagination
            component="div"
            count={proposals?.total_results}
            page={parseInt(searchParams.page_number.toString())}
            onPageChange={handleChangePage}
            rowsPerPage={searchParams.page_size}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationWrap>
      )}
      {hasCreateNew && (
        <Button
          theme={theme}
          secondary
          fullWidth
          className="btn-table"
          text="Create New Proposal"
          onClick={() => navigate(APP_ROUTES.PROPOSAL)}
        />
      )}
      <ManualStatusChangeDialog
        open={statusDialogOpened}
        closeDialog={closeStatusDialog}
        dialogData={statusDialogData}
        openConfirmDialog={openConfirmDialog}
      />
      <ViewConditionDialog
        open={conditionDialogOpened}
        closeDialog={closeConditionDialog}
      />
      <StatusConfirmationDialog
        dialogData={confirmDialogData}
        open={confirmDialogOpened}
        closeDialog={closeConfirmDialog}
        closeStatusDialog={closeStatusDialog}
      />
      <DiaryDialog
        dialogData={diaryDialogData}
        open={diaryDialogOpened}
        closeDialog={closeDiaryDialog}
      />
    </DataTableWrap>
  );
};
