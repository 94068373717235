import { UserPermissions } from "@helpers/enum";
import { Box, Typography } from "@ntpkunity/controls";
import { useHasPermissions } from "@ntpkunity/controls-ums";
import { useTheme } from "@mui/material";

export type AssetSelectionProp = {
  setOpen: () => void;
};

export const AssetSelectionTitle = ({ setOpen }: AssetSelectionProp) => {
  const theme = useTheme();
  return (
    <>
      {useHasPermissions(UserPermissions.ASSET_SELECTION_FROM_INVENTORY) && (
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          className="text-primary fw-medium"
          display={"block"}
        >
          <a
            href="javascript:void(0)"
            className="link text-primary decoration-none"
            onClick={() => {
              setOpen();
            }}
          >
            Choose Asset From Inventory
          </a>
        </Typography>
      )}
    </>
  );
};
