import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { queryKeyFactory } from "@helpers/query";
import { getComparison } from "@services";
import {
  BrokerSpecifcFormFields,
  CurrencyResponse,
  QuotationComparisonRes,
  SingleQuotation,
} from "@_types";
import { UseQueryOptions } from "react-query";
import {
  BALLOON_COLLECTION_ARREAR,
  CalculationResults,
  getRentalTaxTxId,
  QuotationForm,
  SolveFor,
  StepType,
  VatDetails,
} from "@ntpkunity/controls-common";
import { useGetCurrency } from "./useGetCurrency";
import { getCurrencySymbolForEdit } from "@helpers/utils";

type GetComparisonOptions = Omit<
  UseQueryOptions<undefined, AxiosError, SelectQuotationData[]>,
  "queryKey" | "queryFn"
> & {
  referenceNumber: string;
};

export type SelectQuotationData = {
  formValues: QuotationForm;
  brokerSpecificFormValues: BrokerSpecifcFormFields;
  calculations: CalculationResults;
  corporateTaxAmount: number;
  vatDetails: VatDetails;
  isDefaultQuote: boolean;
};

export const useGetComparison = ({
  referenceNumber,
  ...options
}: GetComparisonOptions) => {
  const { data: currency } = useGetCurrency();
  return useQuery({
    queryKey: queryKeyFactory.getComparison(referenceNumber),
    queryFn: () => getComparison(referenceNumber),
    select: (data) => mapComparisonData(data, currency),
    ...options,
  });
};

export const mapComparisonData = (
  data: QuotationComparisonRes,
  currency: CurrencyResponse
) => {
  return data.map((quotation) => mapSingleQuotationData(quotation, currency));
};

export const mapSingleQuotationData = (
  data: SingleQuotation,
  currency: CurrencyResponse
): SelectQuotationData => {
  const shouldPopulateVatTax = !!data.order_payments?.[0]?.tax_amount;
  const currencySymbol = getCurrencySymbolForEdit(
    currency,
    data.order_currency
  );

  const asset = data.asset;
  const assetStr = [asset.category, asset.asset_type, asset.asset_sub_type]
    .filter(Boolean)
    .join("-");

  return {
    formValues: {
      identifier: data.identifier,
      currency: data.order_currency ?? currency?.id,
      solveFor: data.calculations_type,
      financeType: data.finance_type,
      assetCost: data.selling_price,
      deposit: data.down_payment,
      depositType: currencySymbol,
      commission: data.commission_amount,
      commissionType: currencySymbol,
      nonVatableAmount: data.non_vatable_amount || 0,
      marginPercentage: 0,
      baseRate: 0,
      rate: data.margin,
      rateType: data.rate_type,
      noOfAdvancePayments: data.no_of_advance_payments,
      noOfRegularPayments: data.no_of_regular_payments,
      balloonPayment: data.rv_balloon_value,
      balloonCollection: BALLOON_COLLECTION_ARREAR.find(
        (collection) => collection.text === data.balloon_collection
      ).value,
      balloonExtensionTerms: data.balloon_extension_terms,
      vatType: data.vat_treatment,
      vatAmount: data.deferral_amount,
      vatNumber: data.tax_amount_number || 0,
      vatDeferredType: data.deferred_type,
      paymentFrequency: data.rental_frequency,
      startDate: data.start_date,
      periodicPaymentAmount:
        data.calculations_type !== SolveFor.PERIODIC_PAYMENT
          ? data.estimated_monthly_payment
          : undefined,
      structureRentalsApplied: false,
      structureStepPaymentsApplied: false,
      structureRentals: [],
      stepPayments: {
        applied: false,
        stepInterval: 0,
        stepPercentage: 0,
        stepType: StepType.STEP_UP,
      },
      fees: data.order_fees.map((fee) => {
        return {
          identifier: fee.identifier,
          amount: fee.applied_price,
          type: fee.fee_frequency,
          name: fee.dealer_fee_name,
          frequency: fee.fee_occurance,
          isEditable: true,
          firstFeePaymentDueAtStartDate: false,
        };
      }),
    },
    brokerSpecificFormValues: {
      brokerName: "",
      clientType: data.customer?.customer_type,
      identifier: data.customer?.identifier,
      email: data.customer?.email,
      asset: {
        identifier: asset?.identifier,
        type: asset?.type,
        concatenations: assetStr,
        msrp: asset?.msrp,
        vin: asset?.vin,
        year: asset?.year,
        modelCode: asset?.model_code,
        exteriorColorDescription: asset?.exterior_color_description,
        engineCylinders: asset?.engine_cylinders,
        fuelType: asset?.fuel_type,
        model: asset?.model,
        trimDescription: asset?.trim_description,
        interiorColorDescription: asset?.interior_color_description,
        engineDescription: asset?.engine_description,
        vehicleDisplayName: asset?.vehicle_display_name,
        driveTrain: asset?.drive_train,
        price2: asset?.price_2,
        transmissionType: asset?.transmission_type,
        enginePower: asset?.engine_power,
        horsePower: asset?.horse_power,
        stockNumber: asset?.stock_number,
        bodyType: asset?.body_type,
        photoUrls: asset?.photo_urls,
        make: asset?.make,
        bodyStyle: asset?.body_style,
        transmissionSpeed: asset?.transmission_speed,
        engineDisplacement: asset?.engine_displacement,
        mileage: asset?.mileage,
      },
      name: data.name,
      createdAt: data.created_at,
      creditTier: data.customer.credit_rating,
    },
    calculations: {
      endDate: data.end_date,
      commissionAmount: data.commission_amount,
      financeAmount: data.finance_amount,
      rates: {
        grossYield: data.meta_data?.gross_yield,
        netYield: data.meta_data?.net_yield,
        flatRateInclCommission: data.meta_data.flat_rate_commission_inc,
        flatRateExclCommission: data.meta_data.flat_rate_commission_exc,
        apr: data.meta_data.apr,
      },
      sumOfFees: data.sum_of_fees,
      sumOfPeriodicInterest: data.sum_of_periodic_interest,
      totalPayables: data.total_payables,
      sumOfRentals: data.sum_of_rentals,
      sumOfAdvanceRentals: data.sum_of_advance_rentals,
      assetCost: data.selling_price,
      depositAmount: data.down_payment,
      rentalSummary: data.order_payments
        .map((payment) => {
          return {
            identifier: payment.identifier,
            startTerm: payment.payment_number_from,
            endTerm: payment.payment_number_to,
            rentalAmount: payment.amount,
            rentalType: payment.payment_type,
          };
        })
        .sort((a, b) => {
          return a.startTerm - b.startTerm;
        }),
      repaymentPlan: data.order_repayment_plan?.map((planItem) => ({
        identifier: planItem.identifier,
        charges: planItem.charges,
        closingPrincipal: planItem.closing_principal,
        compoundInterest: planItem.compound_interest,
        openingPrincipal: planItem.opening_principal,
        periodicInterest: planItem.periodic_interest,
        rentalAmount: planItem.rental_amount,
        rentalDueDate: planItem.rental_due_date,
        rentalInterest: planItem.rental_interest,
        rentalNumber: planItem.rental_number,
        rentalPrincipal: planItem.rental_principal,
        rentalType: planItem.rental_type,
      })),
    },
    corporateTaxAmount: data.corporate_tax,
    vatDetails: shouldPopulateVatTax
      ? data.order_payments.reduce((acc, payment, index) => {
          const transactionId = getRentalTaxTxId(index);
          acc[transactionId] = {
            identifier: payment.identifier,
            vatAmount: payment.tax_amount,
            vatPercentage: payment.tax_percentage,
            vatExclusiveAmount: payment.amount,
            vatInclusiveAmount:
              Number(payment.amount) + Number(payment.tax_amount),
          };
          return acc;
        }, {})
      : [],
    isDefaultQuote: data.is_default_quote,
  };
};

export default useGetComparison;
