import {
  LayoutWithSideNavComponent,
  ComparisonTool,
  Snackbar,
  ConfigurationWrapper as ConfigurationContextProvider,
  QuotationComparisonPageHeader,
} from "@components";
import { useTheme } from "@mui/material";
import ComparisonPageWrap from "./quote-comparison.style";
import { useState } from "react";
import { EvaluationExternalParams } from "@ntpkunity/controls-common";
import { useGetCompanyByTenantId, useGetDealers } from "@hooks";

const QuoteComparisonPage = () => {
  const theme = useTheme();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: dealerAssociation } = useGetDealers();
  const [evaluationParams, setEvaluationParams] =
    useState<EvaluationExternalParams>({
      asset_category: "",
      asset_type: "",
      company_code: companyInfo.company_code,
      customer_type: "",
      dealer_code: dealerAssociation?.dealer_code || null,
    });

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ComparisonPageWrap
        theme={theme}
        className="comparison-page-content-wrap"
      >
        <ConfigurationContextProvider>
          <ComparisonTool
            renderHeader={({ selectedQuotationRef, disableBtns }) => (
              <QuotationComparisonPageHeader
                selectedQuotationRef={selectedQuotationRef}
                disableBtns={disableBtns}
                setEvaluationParams={setEvaluationParams}
              />
            )}
            evaluationParams={evaluationParams}
          />
          <Snackbar />
        </ConfigurationContextProvider>
      </ComparisonPageWrap>
    </LayoutWithSideNavComponent>
  );
};

export default QuoteComparisonPage;
