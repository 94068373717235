import { useTheme } from "@mui/material";
import { Avatar, Box, Typography } from "@ntpkunity/controls";
import { useEffect } from "react";
import { getUserNameInitials, utcToLocalDateTime } from "@helpers/utils";
import { TOrderStatusHistory } from "@_types";
import { useGetOrderStatusHistory } from "@hooks";

export const AuditTrailContent = ({ proposalId }: { proposalId?: string }) => {
  const theme = useTheme();
  const { mutate: fetchOrderStatusHistory, data: orderStatusHistory = [] } =
    useGetOrderStatusHistory();

  useEffect(() => {
    fetchOrderStatusHistory({ order_reference_number: proposalId });
  }, [proposalId, fetchOrderStatusHistory]);

  return (
    <Box theme={theme} className="audit-trail-list-wrap">
      {orderStatusHistory?.map((item: TOrderStatusHistory, index: number) => {
        const userNameInitials = getUserNameInitials(
          item.updated_by || item.created_by
        );
        const statusDateTime = item.created_at;
        const { dateStr, timeStr } = utcToLocalDateTime(statusDateTime);
        const statusMessage =
          item.previous_status && item.new_status
            ? `Updated the status from ${item.previous_status} to ${item.new_status}.`
            : `Updated the status to ${item.new_status}.`;
        return (
          <Box key={index} theme={theme} className="list-item">
            <Box theme={theme} className="list-img">
              <Avatar theme={theme}>{userNameInitials}</Avatar>
            </Box>
            <Box theme={theme} className="list-content">
              <Typography
                theme={theme}
                variant="body2"
                component={"span"}
                display={"block"}
              >
                <span className="fw-sbold">
                  <b>{item.updated_by || item.created_by} </b>
                </span>{" "}
                {statusMessage}
                <Typography
                  theme={theme}
                  variant="body2"
                  component={"span"}
                  display={"block"}
                >
                  {proposalId}
                </Typography>
              </Typography>
              <Typography
                theme={theme}
                variant="caption"
                component={"small"}
                display={"flex"}
                mt={0.5}
                className="text-muted"
                alignItems={"center"}
                gap={1}
              >
                <span>{dateStr}</span> <span className="seperator">|</span>{" "}
                <span>{timeStr}</span>
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
