import { useMutation, useQueryClient } from "react-query";
import { changeProposalStatus } from "@services";
import { QUERY_KEYS } from "@helpers/query";

const useChangeProposalStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: changeProposalStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ORDER_WORKQUEUE],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DASHBOARD_PROPOSALS],
      });
    },
  });
};

export default useChangeProposalStatus;
