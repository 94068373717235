import { Button, Icon, Menu } from "@ntpkunity/controls";
import {
  DownloadQuotationDialog,
  PageHeader,
  QuotationHandleRef,
} from "@components";
import { useTheme } from "@mui/material";
import { BrokerFields } from "./broker-fields.component";
import { FormProvider, useForm } from "react-hook-form";
import {
  BrokerSpecifcFormFields,
  CreateSingleQuotation,
  DownloadQuoteAdditionalParams,
  QuotationComparisonRes,
  QuotationDownloadDialogData,
  SingleQuotation,
} from "@_types";
import { useSnackbarContext } from "@contexts/snackbar";
import { APP_ROUTES } from "@helpers";
import { useNavigate, useParams } from "react-router-dom";
import {
  DownloadDocument,
  Events,
  ProposalMode,
  TableRowOptions,
} from "@helpers/enum";
import {
  errorInQuotationDetails,
  getBrokerQuotationPayload,
  getDownloadDocumentPayload,
} from "@helpers/utils";
import { useEffect, useState } from "react";
import {
  useGetCompanyByTenantId,
  useGetDealers,
  useDialogState,
  useSetupsSelector,
  useConvertQuotationToProposal,
  useDownloadDocs,
  useSaveQuotation,
  useSendEmailNotification,
  useGetCurrency,
  useSaveComparison,
  useUpdateComparison,
  useGetQuotationPayload,
} from "@hooks";
import { EvaluationExternalParams } from "@ntpkunity/controls-common";
import { SNACKBAR_MESSAGES } from "@helpers/const";

type PageHeaderProps = {
  selectedQuotationRef: QuotationHandleRef;
  quotationRefs: QuotationHandleRef[];
  disableBtns: boolean;
  setEvaluationParams: React.Dispatch<
    React.SetStateAction<EvaluationExternalParams>
  >;
  defaultFormValues?: BrokerSpecifcFormFields;
};

export const QuotationComparisonPageHeader = ({
  selectedQuotationRef,
  quotationRefs,
  disableBtns,
  setEvaluationParams,
  defaultFormValues,
}: PageHeaderProps) => {
  const navigate = useNavigate();
  const params = useParams<{ comparisonId?: string }>();
  const comparisonId = params.comparisonId;
  const isEditMode = !!comparisonId;
  const theme = useTheme();
  const formMethods = useForm<BrokerSpecifcFormFields>({
    defaultValues: defaultFormValues,
  });
  const [quotationIdentifier, setQuotationIdentifier] = useState<string>();
  const { setSnackbar } = useSnackbarContext();
  const { mutateAsync: saveQuotation } = useSaveQuotation();
  const { mutateAsync: convertToProposal } = useConvertQuotationToProposal();
  const { mutateAsync: sendQuotationEmail } = useSendEmailNotification();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: currency } = useGetCurrency();
  const { mutateAsync: downloadDocs } = useDownloadDocs();
  const { financeTypes, clientTypes, creditTiers } = useSetupsSelector(
    (state) => ({
      financeTypes: state.financeTypes.data,
      clientTypes: state.clientTypes.data,
      creditTiers: state.creditTiers.data,
    })
  );
  const { data: dealerAssociation } = useGetDealers();
  const { mutateAsync: saveComparison } = useSaveComparison();
  const { mutateAsync: updateComparison } = useUpdateComparison();
  const getQuotationPayload = useGetQuotationPayload(isEditMode);

  const {
    dialogOpen: downloadDialogOpened,
    closeDialog: closeDownloadDialog,
    openDialog: openDownloadDialog,
    dialogData: downloadDialogData,
  } = useDialogState<QuotationDownloadDialogData>();

  const assetTypeStr = formMethods.watch("asset.type");

  const clientTypeCode = formMethods.watch("clientType");
  const clientTypeName = clientTypes.find(
    (ct) => ct.code === clientTypeCode
  )?.description;

  const creditTier = formMethods.watch("creditTier");

  const handleSaveQuotation = async () => {
    const error = errorInQuotationDetails(formMethods.getValues());
    if (error.isError) {
      setSnackbar({ message: error.message, open: true, variant: "error" });
      return false;
    }
    const refHandle = selectedQuotationRef.ref.current;
    try {
      setSnackbar({
        open: true,
        message: "Saving Quotation...",
      });
      const brokerSpecificValues = formMethods.getValues();
      const payload = refHandle.getSaveQuotationPayload();
      const response = await saveQuotation(
        getBrokerQuotationPayload(
          payload,
          brokerSpecificValues,
          dealerAssociation,
          creditTiers
        )
      );
      setQuotationIdentifier(response.data.identifier);
      setSnackbar(
        {
          open: true,
          message: "Quotation Saved Successfully",
        },
        2000
      );
      return response;
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error Occurred while saving Quotation",
        variant: "error",
      });
    }
  };

  const handleConvertToProposal = async () => {
    try {
      let identifier = undefined;
      if (!quotationIdentifier) {
        if (isEditMode) {
          const response = await handleSaveComparison();
          identifier = response.find(
            (quote) => quote.is_default_quote
          ).identifier;
        } else {
          const response = await handleSaveQuotation();
          if (!response) {
            return;
          }
          identifier = response.data.identifier;
        }
      }

      setSnackbar({ open: true, message: "Converting To Proposal..." });
      await convertToProposal(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${ProposalMode.EDIT}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error occurred while converting to proposal",
        variant: "error",
      });
    }
  };

  const getDocumentGenerationData = (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const refHandle = selectedQuotationRef.ref.current;
    const { getValues: getQuotationValues } =
      refHandle.getQuotationFormContext();
    const quotationPayload = getQuotationValues();
    const calculations = refHandle.getCalculationsContext();
    const brokerSpecificValues = formMethods.getValues();

    return getDownloadDocumentPayload(
      {
        documentType: DownloadDocument.QUOTATION,
        additionalParams: { ...brokerSpecificValues, ...additionalParams },
        financialCalculations: calculations.data,
        financialValues: quotationPayload,
        setups: {
          clientTypes: clientTypes,
          financeTypes: financeTypes,
        },
        companyId: companyInfo.id,
      },
      currency
    );
  };

  const validateEmail = (customerEmail: string) => {
    if (!companyInfo.email) {
      throw new Error(SNACKBAR_MESSAGES.COMPANY_EMAIL_INVALID);
    }
    if (!customerEmail) {
      throw new Error(SNACKBAR_MESSAGES.INVALID_CUSTOMER_EMAIL);
    }
  };

  const handleEmailQuotation = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const downloadQuotationPayload =
      getDocumentGenerationData(additionalParams);
    const { email_address: customerEmail } = downloadQuotationPayload;
    validateEmail(customerEmail);

    setSnackbar({ open: true, message: SNACKBAR_MESSAGES.SENDING_EMAIL });
    await sendQuotationEmail({
      eventName: Events.EMAIL_QUOTATION,
      payload: {
        source_email: companyInfo.email,
        bcc_emails: [],
        cc_emails: [],
        data: {
          ...downloadQuotationPayload,
          company_id: companyInfo?.id,
          reference_number: "",
          customer_reference_id: "",
        },
        email_subject: `Finance Quotation - ${downloadQuotationPayload.name}`,
        email: downloadQuotationPayload.email_address,
        email_attachment_keys: [],
      },
    });
    setSnackbar({ open: true, message: SNACKBAR_MESSAGES.EMAIL_SENT }, 2000);
  };

  const handleDownloadQuotation = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    const downloadQuotationPayload =
      getDocumentGenerationData(additionalParams);

    setSnackbar({ open: true, message: "Downloading quotation..." });

    await downloadDocs({
      payload: downloadQuotationPayload,
      eventName: Events.DOWNLOAD_QUOTATION,
    });

    setSnackbar({ open: true, message: "Quotation Downloaded!" }, 2000);
  };

  const handleGenerateDocuments = async (
    additionalParams: DownloadQuoteAdditionalParams
  ) => {
    try {
      if (downloadDialogData.rowOptions === TableRowOptions.DOWNLOAD) {
        await handleDownloadQuotation(additionalParams);
      } else {
        await handleEmailQuotation(additionalParams);
      }
      closeDownloadDialog();
    } catch (err) {
      const errorMessage =
        err.message || SNACKBAR_MESSAGES.UNEXPECTED_ERROR_OCCURRED;
      setSnackbar({
        open: true,
        message: errorMessage,
        variant: "error",
      });
    }
  };

  const handleSaveComparison = async () => {
    const payload = quotationRefs.map((qR) => {
      const ref = qR.ref.current;
      const formValues = ref.getQuotationFormContext().getValues();
      const calculationsContext = ref.getCalculationsContext();
      const brokerFormValues = formMethods.getValues();
      return getQuotationPayload({
        brokerFormValues,
        formValues,
        calculationsContext,
        isDefaultQuote: qR.id === selectedQuotationRef.id,
      });
    });

    try {
      setSnackbar({
        open: true,
        message: "Saving Comparison...",
      });
      const promise: Promise<QuotationComparisonRes> = isEditMode
        ? updateComparison({
            referenceNumber: comparisonId,
            payload: { quotations: payload as SingleQuotation[] },
          })
        : saveComparison({ quotations: payload as CreateSingleQuotation[] });

      const response = await promise;
      setSnackbar({
        open: true,
        message: "Comparison Saved Successfully",
      });
      const identifier = response.find(
        (quote) => quote.is_default_quote
      ).identifier;
      setQuotationIdentifier(identifier);
      return response;
    } catch (err) {
      const errorMessage =
        err.message || SNACKBAR_MESSAGES.UNEXPECTED_ERROR_OCCURRED;
      setSnackbar({
        open: true,
        message: errorMessage,
        variant: "error",
      });
    }
  };

  const handleOptionClick = async (_, key: TableRowOptions) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        handleConvertToProposal();
        break;
      case TableRowOptions.DOWNLOAD:
        openDownloadDialog({ clientType: clientTypeName, rowOptions: key });
        break;
      case TableRowOptions.EMAIL_QUOTATION:
        openDownloadDialog({ clientType: clientTypeName, rowOptions: key });
      case TableRowOptions.SAVE_SELECTED_QUOTATION:
        await handleSaveQuotation();
      case TableRowOptions.SAVE_COMPARISON:
        await handleSaveComparison();
    }
  };

  // Sync external param's asset category & asset type
  useEffect(() => {
    if (!assetTypeStr) return;
    const assetSplit = assetTypeStr.split(" - ");
    const assetCategory = assetSplit[0];
    const assetType = assetSplit[1];
    setEvaluationParams?.((prevState) => ({
      ...prevState,
      asset_type: assetType,
      asset_category: assetCategory,
    }));
  }, [assetTypeStr]);

  // Sync external param's client type
  useEffect(() => {
    if (!clientTypeName) return;
    setEvaluationParams?.((prevState) => ({
      ...prevState,
      customer_type: clientTypeName,
    }));
  }, [clientTypeName]);

  useEffect(() => {
    if (!creditTier) return;
    setEvaluationParams?.((prevState) => ({
      ...prevState,
      credit_rating: creditTier,
    }));
  }, [creditTier]);

  const saveOptions = [
    ...(isEditMode
      ? []
      : [
          {
            optionText: "Save Selected Quotation",
            optionkey: TableRowOptions.SAVE_SELECTED_QUOTATION,
            disabled: disableBtns,
          },
        ]),
    {
      optionText: "Save Comparison",
      optionkey: TableRowOptions.SAVE_COMPARISON,
      disabled: disableBtns,
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <PageHeader
        title={false}
        hasInput
        inputName="name"
        inputPlaceholder="Enter Quotation Name..."
        shouldUseForm={true}
        actionArea={
          <>
            <BrokerFields />
            <Menu
              theme={theme}
              options={[
                {
                  optionText: "Convert to proposal",
                  optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
                  disabled: disableBtns,
                },
                {
                  optionText: "Download Quotation",
                  optionkey: TableRowOptions.DOWNLOAD,
                  disabled: disableBtns,
                },
                {
                  optionText: "Email Quotation",
                  optionkey: TableRowOptions.EMAIL_QUOTATION,
                  disabled: disableBtns,
                },
              ]}
              handleOptionClick={handleOptionClick}
              render={(cb) => (
                <Button
                  secondary
                  theme={theme}
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                ></Button>
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
            <Menu
              theme={theme}
              options={saveOptions}
              handleOptionClick={handleOptionClick}
              render={(cb) => (
                <Button
                  theme={theme}
                  type="button"
                  primary
                  disabled={disableBtns}
                  text="Save Quotation"
                  onClick={cb}
                />
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </>
        }
      />
      {downloadDialogOpened && (
        <DownloadQuotationDialog
          dialogOpen={downloadDialogOpened}
          downloadQuotation={handleGenerateDocuments}
          closeDialog={closeDownloadDialog}
          dialogData={downloadDialogData}
        />
      )}
    </FormProvider>
  );
};
