import { Grid } from "@ntpkunity/controls";
import { ProposalFieldView } from "./proposal-field-view.component";
import { type Theme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { toCapitalizedWords } from "@helpers/utils";
import { useTheme } from "@mui/material";

export const AddressViewDetailsList = () => {
  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();

  const { city, zipCode, county, addressLine1, addressLine2 } = watch(
    "customerDetails.address"
  );
  const addressDetails = Object.entries({
    city: city,
    post_code: zipCode,
    county: county,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
  }).map(([key, value]) => ({
    label: toCapitalizedWords(key.replace(/_/g, " ")),
    value,
  }));

  const addressDetail = addressDetails.map((field, key) => (
    <ProposalFieldView
      key={key}
      label={field.label}
      value={field.value || "-"}
    />
  ));

  return (
    <Grid container item columnSpacing={{ xs: 4, md: 8 }} theme={theme}>
      {addressDetail}
    </Grid>
  );
};
