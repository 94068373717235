import React, { createRef, RefObject, useState } from "react";
import {
  Stack,
  Footer,
  ShowInputFieldsButton,
  SingleQuotation,
} from "@components";
import {
  CalculationResponse,
  CalculationsContextProvider,
  convertCalculationResponse,
  QuotationControlMode,
  QuotationForm,
  QuotationFormProvider,
  useCalculateQuote,
  ControlModeContextProvider,
  ExternalDataContextProvider,
  usePerformChartEvaluation,
  CommissionChartTypes,
  FEE_CHART_TYPE_TO_FORM_MAP,
  FEE_CHART_FREQUENCY_TO_FORM_MAP,
  EvaluationExternalParams,
  useCalculateTax,
  useConfigurationContext,
  CalculateTaxResSuccess,
  TaxType,
  TConfiguration,
  isCorporateTaxResInvalid,
  isCorporateTaxResSuccess,
  CalculateTaxResponse,
  VatDetails,
} from "@ntpkunity/controls-common";
import { Box, Button, Grid, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import {
  AmountType,
  ComparisonViewMode,
  PromiseStates,
  SessionStorageKeys,
} from "@helpers/enum";
import { createId } from "@paralleldrive/cuid2";
import {
  DEFAULT_NUMBER_OF_QUOTES,
  MAX_NUMBER_OF_QUOTATIONS,
  MIN_NUMBER_OF_QUOTATIONS,
} from "@helpers/const";
import { QuotationHandle } from "@_types";
import { ComparisonPageContentWrap } from "@pages/quote-comparison/quote-comparison.style";
import { useSnackbarContext } from "@contexts/snackbar";
import { useNavigate } from "react-router-dom";
import {
  getSessionStorageItem,
  isDealerRole,
  setSessionStorageItem,
} from "@helpers/utils";
import { useGetCurrency, useSetupsSelector, SelectQuotationData } from "@hooks";

export type QuotationHandleRef = {
  id: string;
  ref: RefObject<QuotationHandle>;
  errorMessage?: string;
};

export type SaveSelectionCallbackProps = {
  selectedQuotationRef: QuotationHandleRef;
  disableBtns: boolean;
  quotationRefs: QuotationHandleRef[];
};

type ComparisonToolProps = {
  renderHeader?: (props: SaveSelectionCallbackProps) => React.ReactNode;
  useDialogMode?: boolean;
  saveSelectionCallback?: (
    props: Omit<SaveSelectionCallbackProps, "quotationRefs">
  ) => () => void;
  evaluationParams: EvaluationExternalParams;
  currencyId?: string;
  comparisonData?: SelectQuotationData[];
};

export const ComparisonTool = ({
  renderHeader,
  useDialogMode = false,
  saveSelectionCallback,
  evaluationParams,
  currencyId,
  comparisonData,
}: ComparisonToolProps) => {
  const theme = useTheme();
  const isEditMode = !!comparisonData;
  const navigate = useNavigate();
  const { financeTypes } = useSetupsSelector((state) =>
    state.getFilteredFinanceTypes()
  );
  const { data: currency } = useGetCurrency(currencyId);
  const _isDealerRole = isDealerRole();

  const defaultViewMode = isEditMode
    ? ComparisonViewMode.RESULT
    : ComparisonViewMode.INPUT;

  const [viewMode, setViewMode] = useState<ComparisonViewMode>(defaultViewMode);
  const [quotationCalculated, setQuotationCalculated] = useState(isEditMode);

  const selectedQuotationIndex = comparisonData?.findIndex(
    (data) => data.isDefaultQuote
  );
  const noOfQuotationsToInitialize = isEditMode
    ? comparisonData.length
    : DEFAULT_NUMBER_OF_QUOTES;

  const [quotationRefs, setQuotationRefs] = useState<QuotationHandleRef[]>(
    [...Array(noOfQuotationsToInitialize)].map(() => ({
      id: createId(),
      ref: createRef<QuotationHandle>(),
    }))
  );

  // In case of edit this will be the index of the selected quotation
  const [selectedQuotationRef, setSelectedQuotationRef] =
    useState<QuotationHandleRef>(
      quotationRefs[isEditMode ? selectedQuotationIndex : 0]
    );

  const { data: configurations } = useConfigurationContext();

  const { mutateAsync: calculateQuotation } = useCalculateQuote({});
  const { mutateAsync: calculateVat } = useCalculateTax({});
  const { mutateAsync: performChartEvaluation } = usePerformChartEvaluation({});

  const { setSnackbar } = useSnackbarContext();

  const isInputMode = viewMode === ComparisonViewMode.INPUT;
  const isResultMode = viewMode === ComparisonViewMode.RESULT;
  const setInputMode = () => setViewMode(ComparisonViewMode.INPUT);
  const setResultsMode = () => setViewMode(ComparisonViewMode.RESULT);

  const handleDeleteQuotation = (index: number) => {
    if (quotationRefs.length > MIN_NUMBER_OF_QUOTATIONS)
      setQuotationRefs(quotationRefs.filter((_, i) => i !== index));
  };

  const onErrorClose = (refId: string) => {
    setQuotationRefs(
      quotationRefs.map((qR) =>
        qR.id === refId ? { ...qR, errorMessage: undefined } : qR
      )
    );
  };

  const pushLastQuotationDataToSS = () => {
    const lastQuotation = quotationRefs[quotationRefs.length - 1];
    const lastQuotationRefHandle = lastQuotation.ref.current;
    setSessionStorageItem<QuotationForm>(SessionStorageKeys.QUOTATION_INPUTS, {
      ...lastQuotationRefHandle.getQuotationFormContext().getValues(),
      identifier: undefined,
    });
  };

  const handleAddQuotation = () => {
    pushLastQuotationDataToSS();
    if (quotationRefs.length < MAX_NUMBER_OF_QUOTATIONS)
      setQuotationRefs([
        ...quotationRefs,
        { id: createId(), ref: createRef<QuotationHandle>() },
      ]);
  };

  const handleSingleEvaluation = async (quotationRef: QuotationHandleRef) => {
    const refHandle = quotationRef.ref.current;
    const evaluationPayload = refHandle.getChartEvaluationPayload();
    const evaluation = await performChartEvaluation(evaluationPayload);
    const { setValue } = refHandle.getQuotationFormContext();

    const payload = evaluation[0];
    const interestChart = payload.interest_chart?.[0];
    const feesChart = payload.fee_chart;
    const commissionChart = payload.commission_chart?.[0];

    setValue("rate", interestChart?.margin || 0);
    setValue("rateType", interestChart?.rate_type);
    setValue("commission", commissionChart?.default_commission || 0);
    setValue(
      "commissionType",
      commissionChart?.commission_type === CommissionChartTypes.PERCENTAGE
        ? AmountType.PERCENTAGE
        : AmountType.POUND
    );
    setValue(
      "fees",
      feesChart.map((chart) => ({
        amount: chart.fee_amount,
        type: FEE_CHART_TYPE_TO_FORM_MAP[chart.fee_type],
        name: chart.fee_name,
        frequency: FEE_CHART_FREQUENCY_TO_FORM_MAP[chart.fee_frequency],
        isEditable: chart.override_allowed,
        firstFeePaymentDueAtStartDate: chart.due_at_start_date,
      })) || []
    );
  };

  const shouldCalculateRentalTax = (configuration: TConfiguration) =>
    configuration.taxType === TaxType.RENTAL && configuration.isVATApplicable;
  const shouldCalculateCorporateTax = (configuration: TConfiguration) =>
    shouldCalculateRentalTax(configuration) &&
    configuration.calculateCorporateTax;

  const handleTaxCalculation = async (
    quotationRef: QuotationHandleRef,
    response: CalculationResponse
  ) => {
    const refHandle = quotationRef.ref.current;
    const { watch } = refHandle.getQuotationFormContext();
    const { setVat, setCorporateTax, setCorporateTaxFailed } =
      refHandle.getCalculationActions();

    const financeType = watch("financeType");
    const configuration = configurations[financeType];
    const taxPayload = refHandle.getTaxPayload(
      convertCalculationResponse(response)
    );
    if (shouldCalculateRentalTax(configuration)) {
      const taxPromises = [calculateVat(taxPayload.vatPayload)];
      const _shouldCalcCorporateTax =
        shouldCalculateCorporateTax(configuration);
      if (_shouldCalcCorporateTax) {
        taxPromises.push(calculateVat(taxPayload.corporateTaxPayload));
      }
      const taxResponses: CalculateTaxResponse[] = await Promise.all(
        taxPromises
      );
      const [vatResponse, corporateTaxRes] = taxResponses;
      if (_shouldCalcCorporateTax) {
        const res = corporateTaxRes.response;
        if (isCorporateTaxResInvalid(corporateTaxRes)) {
          setCorporateTaxFailed();
        }
        if (isCorporateTaxResSuccess(corporateTaxRes)) {
          setCorporateTax(res[0].tax_amount);
        }
      }

      if (vatResponse.response.length) {
        const vatDetails = vatResponse.response.reduce(
          (acc: VatDetails, responseRental: CalculateTaxResSuccess) => {
            acc[responseRental.transaction_id] = {
              vatAmount: responseRental.tax_amount,
              vatInclusiveAmount: responseRental.inclusive_amount,
              vatExclusiveAmount: responseRental.exclusive_amount,
              vatPercentage: responseRental.tax_rate,
            };
            return acc;
          },
          {}
        );
        setVat(vatDetails);
      }
    }
  };

  const handleSingleCalculation = async (quotationRef: QuotationHandleRef) => {
    const refHandle = quotationRef.ref.current;
    const payload = refHandle.getCalculateQuotePayload();
    const { watch } = refHandle.getQuotationFormContext();
    const {
      setCalculationCallInProgress,
      setCalculationResults,
      setCorporateTaxLoading,
      resetCorporateTax,
    } = refHandle.getCalculationActions();

    const financeType = watch("financeType");
    const configuration = configurations[financeType];
    resetCorporateTax();
    setCalculationCallInProgress();
    if (shouldCalculateCorporateTax(configuration)) {
      setCorporateTaxLoading();
    }

    if (_isDealerRole) {
      await handleSingleEvaluation(quotationRef);
    }
    const response: CalculationResponse = await calculateQuotation(payload);
    setCalculationResults(convertCalculationResponse(response));
    handleTaxCalculation(quotationRef, response);
    return response;
  };

  const errorInFieldValidation = (quotationRefs: QuotationHandleRef[]) => {
    quotationRefs.forEach(({ ref }) => {
      ref.current?.validatePayload();
    });

    let hasValidationError = false;
    quotationRefs.forEach(({ ref }) => {
      const {
        formState: { errors },
      } = ref.current?.getQuotationFormContext();
      if (Object.keys(errors)?.length) {
        hasValidationError = true;
      }
    });
    return hasValidationError;
  };

  const handleCalculateComparison = async () => {
    setQuotationRefs(quotationRefs.map((qR) => ({ id: qR.id, ref: qR.ref })));
    if (errorInFieldValidation(quotationRefs)) {
      return;
    }
    setSnackbar({ open: true, message: "Calculating Comparisons" });
    try {
      const responses = await Promise.allSettled(
        quotationRefs.map((quotationRef) =>
          handleSingleCalculation(quotationRef)
        )
      );

      const errorInCalculation = responses.some(
        (res) => res.status === PromiseStates.REJECTED
      );

      if (errorInCalculation) {
        setQuotationRefs(
          quotationRefs.map((qR, i) => {
            const res = responses[i];
            const isRejected = res.status === PromiseStates.REJECTED;
            if (isRejected) {
              const { setCorporateTaxFailed } =
                qR.ref.current.getCalculationActions();
              setCorporateTaxFailed();
            }
            return {
              id: qR.id,
              ref: qR.ref,
              errorMessage: isRejected
                ? res.reason.response.data?.Message
                : undefined,
            };
          })
        );
        setSnackbar(
          {
            open: true,
            message: "Error while Calculating Comparison",
            variant: "error",
          },
          2000
        );
      } else {
        quotationRefs.forEach((quotationRef) => {
          const refHandle = quotationRef.ref.current;
          const { reset, getValues } = refHandle.getQuotationFormContext();
          reset(getValues());
        });
        setSnackbar(
          {
            open: true,
            message: "Comparison Calculated Successfully",
          },
          2000
        );
        setResultsMode();
        setQuotationCalculated(true);
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error while Calculating Comparison",
        variant: "error",
      });
    }
  };

  const quotationInputsData = getSessionStorageItem<QuotationForm>(
    SessionStorageKeys.QUOTATION_INPUTS
  );

  const showSaveSelection =
    useDialogMode &&
    quotationCalculated &&
    viewMode === ComparisonViewMode.RESULT;

  const footerButtonText = showSaveSelection ? "Save Selection" : "Calculate";
  const quotationControlMode = _isDealerRole
    ? QuotationControlMode.DEALER
    : QuotationControlMode.BROKER;

  return (
    <>
      <ControlModeContextProvider mode={quotationControlMode}>
        <ExternalDataContextProvider
          financeTypes={financeTypes}
          evaluationParams={evaluationParams}
          currency={currency}
        >
          {renderHeader &&
            renderHeader({
              disableBtns: !quotationCalculated,
              selectedQuotationRef,
              quotationRefs,
            })}
          <ComparisonPageContentWrap
            theme={theme}
            className="page-content has-footer"
          >
            {isResultMode && <ShowInputFieldsButton onClick={setInputMode} />}
            <Box
              theme={theme}
              className="quotation-tool-container"
              m={{ xs: "24px 0 0 0", md: "16px -24px -8px -24px" }}
            >
              <Box
                theme={theme}
                className="comparison-container"
                mt={2}
                flexGrow={1}
              >
                <Grid
                  theme={theme}
                  container
                  item
                  spacing={2}
                  alignItems="stretch"
                >
                  {quotationRefs.map((quotationRef, index) => {
                    const headerText = `Quotation ${index + 1}`;
                    const handleSelectQuotation = () => {
                      setSelectedQuotationRef(
                        quotationRefs.find((ref) => ref.id === quotationRef.id)
                      );
                    };
                    return (
                      <CalculationsContextProvider key={quotationRef.id}>
                        <QuotationFormProvider
                          defaultValues={
                            isEditMode && comparisonData[index]
                              ? comparisonData[index]?.formValues
                              : quotationInputsData
                          }
                          currency={currency}
                        >
                          <SingleQuotation
                            headerText={headerText}
                            errorMessage={quotationRef.errorMessage}
                            totalQuotations={quotationRefs.length}
                            viewMode={viewMode}
                            handleDeleteQuotation={() =>
                              handleDeleteQuotation(index)
                            }
                            isSelected={
                              selectedQuotationRef.id === quotationRef.id
                            }
                            onErrorClose={() => onErrorClose(quotationRef.id)}
                            handleSelectQuotation={handleSelectQuotation}
                            ref={quotationRefs[index].ref}
                            quotationData={
                              isEditMode && comparisonData[index]
                                ? comparisonData[index]
                                : undefined
                            }
                          />
                        </QuotationFormProvider>
                      </CalculationsContextProvider>
                    );
                  })}
                </Grid>
              </Box>
              {quotationRefs.length < MAX_NUMBER_OF_QUOTATIONS &&
                viewMode !== ComparisonViewMode.RESULT && (
                  <Box theme={theme} className="add-new" flexShrink={0}>
                    <Button
                      type="button"
                      secondary
                      theme={theme}
                      iconText={<Icon name="AddIcon" />}
                      onClick={handleAddQuotation}
                    />
                  </Box>
                )}
            </Box>
            {quotationCalculated && isInputMode && (
              <Box
                theme={theme}
                margin={{ xs: "16px 0px 0px 0px", md: "24px -24px 0px -24px" }}
                className="results-btn"
              >
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Results"
                  onClick={setResultsMode}
                  actionArea={<Icon name="ChevronDown" />}
                />
              </Box>
            )}

            <Footer
              actionButtonText={footerButtonText}
              actionButtonOnClick={
                showSaveSelection
                  ? saveSelectionCallback({
                      selectedQuotationRef: selectedQuotationRef,
                      disableBtns: !quotationCalculated,
                    })
                  : handleCalculateComparison
              }
              showBackButton={!useDialogMode}
              backButtonOnClick={() => navigate(-1)}
            />
          </ComparisonPageContentWrap>
        </ExternalDataContextProvider>
      </ControlModeContextProvider>
    </>
  );
};
