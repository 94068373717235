import {
  BrokerSpecifcFormFields,
  CreateSingleQuotation,
  SingleQuotation,
} from "@_types";
import { getCreditTierScore } from "@helpers/utils";
import {
  CalculationsContextState,
  getBalloonCollectionValue,
  getPaymentMode,
  getRentalTaxTxId,
  QuotationForm,
  SolveFor,
  VatInstance,
} from "@ntpkunity/controls-common";
import { createId } from "@paralleldrive/cuid2";
import dayjs from "dayjs";
import { useSetupsSelector } from "./useGetSetups";

type GetQuotationPayloadParams = {
  brokerFormValues: BrokerSpecifcFormFields;
  calculationsContext: CalculationsContextState;
  formValues: QuotationForm;
  isDefaultQuote?: boolean;
};

type UseGetQuotationPayloadReturn<T> = (params: GetQuotationPayloadParams) => T;

export function useGetQuotationPayload(
  forUpdate: boolean
):
  | UseGetQuotationPayloadReturn<SingleQuotation>
  | UseGetQuotationPayloadReturn<CreateSingleQuotation> {
  const { creditTiers } = useSetupsSelector((state) => ({
    creditTiers: state.creditTiers.data,
  }));

  return ({
    brokerFormValues,
    calculationsContext,
    formValues,
    isDefaultQuote = true,
  }: GetQuotationPayloadParams) => {
    const {
      data: calculations,
      vat: { details: vatDetails },
      corporateTax: { taxAmount: corporateTaxAmount },
    } = calculationsContext;
    const assetDetails = brokerFormValues.asset.type;
    const split = assetDetails?.split("-");
    const assetCategory = split?.[0];
    const assetType = split?.[1];
    const assetSubType = split?.[2];

    let asset: any = {
      asset_sub_type: assetSubType,
      asset_type: assetType,
      category: assetCategory,
      quantity: 1,
      rv_balloon_amount: Number(formValues.balloonPayment),
      total: formValues.assetCost,
      unit_price: formValues.assetCost,
    };
    let customer: any = {
      customer_type: brokerFormValues.clientType,
      email: `${createId()}@test.com`,
      credit_rating: brokerFormValues.creditTier,
      credit_score: getCreditTierScore(
        creditTiers,
        brokerFormValues.creditTier
      ), // Add both fields on backend
    };
    if (forUpdate) {
      asset = { ...asset, identifier: brokerFormValues.asset.identifier };
      customer = { ...customer, identifier: brokerFormValues.identifier };
    }

    const singlePayload = {
      identifier: formValues.identifier,
      name: brokerFormValues.name,
      start_date: dayjs(formValues.startDate).format("YYYY-MM-DD"),
      end_date: calculations.endDate,
      selling_price: Number(formValues.assetCost),
      down_payment: calculations.depositAmount,
      sum_of_advance_rentals: calculations.sumOfAdvanceRentals,
      calculations_type: formValues.solveFor,
      no_of_advance_payments: Number(formValues.noOfAdvancePayments),
      no_of_regular_payments: Number(formValues.noOfRegularPayments),
      rental_frequency: formValues.paymentFrequency,
      deferral_amount: Number(formValues.vatAmount),
      rv_balloon_value: Number(formValues.balloonPayment),
      balloon_collection: getBalloonCollectionValue(
        Number(formValues.balloonCollection)
      ),
      balloon_extension_terms: formValues.balloonExtensionTerms || 0,
      finance_type: formValues.financeType,
      finance_amount: calculations.financeAmount,
      commission_amount: calculations.commissionAmount,
      due_at_signing:
        Number(calculations.depositAmount) +
        Number(calculations.sumOfAdvanceRentals),
      meta_data: {
        gross_yield: calculations.rates.grossYield,
        net_yield: calculations.rates.netYield,
        apr: calculations.rates.apr,
        flat_rate_commission_inc: calculations.rates.flatRateInclCommission,
        flat_rate_commission_exc: calculations.rates.flatRateExclCommission,
      },
      vat_treatment: formValues.vatType,
      tax_amount: Number(formValues.vatAmount),
      tax_amount_number: formValues.vatNumber,
      corporate_tax: corporateTaxAmount,
      deferred_type: formValues.vatDeferredType,
      rental_mode: getPaymentMode(Number(formValues.noOfAdvancePayments)),
      rate_type: formValues.rateType as any,
      margin: Number(formValues.rate),
      commission_type: formValues.commissionType,
      sum_of_fees: calculations.sumOfFees,
      sum_of_periodic_interest: calculations.sumOfPeriodicInterest,
      sum_of_rentals: calculations.sumOfRentals,
      total_payables: calculations.totalPayables,
      non_vatable_amount: Number(formValues.nonVatableAmount),
      order_currency: formValues.currency,
      estimated_monthly_payment:
        formValues.solveFor !== SolveFor.PERIODIC_PAYMENT
          ? Number(formValues.periodicPaymentAmount)
          : undefined,
      order_fees: formValues.fees.map((fee) => {
        const payload = {
          dealer_fee_name: fee.name,
          applied_price: fee.amount,
          fee_frequency: fee.type,
          fee_occurance: fee.frequency,
        };
        return {
          ...payload,
          identifier: fee.identifier,
        };
      }),
      order_payments: calculations.rentalSummary.map((rental, index) => {
        const taxInstance: VatInstance | undefined =
          vatDetails[getRentalTaxTxId(index)];
        const paymentPayload = {
          payment_number_from: rental.startTerm,
          payment_number_to: rental.endTerm,
          amount: rental.rentalAmount,
          payment_type: rental.rentalType,
          tax_type: "VAT",
          tax_amount: taxInstance?.vatAmount,
          tax_percentage: taxInstance?.vatPercentage,
        };

        if (forUpdate) {
          return { ...paymentPayload, identifier: rental.identifier };
        }
        return paymentPayload;
      }),
      asset: asset,
      order_repayment_plan: calculations.repaymentPlan?.map((planItem) => {
        const item = {
          rental_number: planItem.rentalNumber,
          opening_principal: planItem.openingPrincipal,
          rental_amount: planItem.rentalAmount,
          charges: planItem.charges,
          rental_principal: planItem.rentalPrincipal,
          rental_interest: planItem.rentalInterest,
          rental_due_date: planItem.rentalDueDate,
          periodic_interest: planItem.periodicInterest,
          compound_interest: planItem.compoundInterest,
          closing_principal: planItem.closingPrincipal,
          rental_type: planItem.rentalType,
        };
        if (forUpdate) {
          return { ...item, identifier: planItem.identifier };
        }
        return item;
      }),
      customer: customer,
      is_default_quote: isDefaultQuote,
    };
    return singlePayload;
  };
}

export default useGetQuotationPayload;
