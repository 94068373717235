import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Accordion, Grid, Typography } from "@ntpkunity/controls";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import {
  useCustomerDetailsInputDefs,
  useCustomerFields,
  useGetClientTypeName,
  useDialogState,
} from "@hooks";
import { DirectorsDetails } from "./director-details/director-details.component";
import { useFormContext } from "react-hook-form";
import { ClientTypes } from "@helpers/enum";
import { TProposalForm } from "@_types";
import { CompanyLookupDialog } from "@components/proposal";

export const CustomerDetail = () => {
  const inputDefs = useCustomerDetailsInputDefs();
  const customerFields = useCustomerFields();
  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);
  const { dialogOpen, openDialog, closeDialog } = useDialogState();

  const showDirectorDetails = clientTypeName !== ClientTypes.INDIVIDUAL;

  return (
    <>
      <Stack
        paddingXs={3}
        paddingMd={3}
        marginTopMd={2}
        marginTopXs={2}
        className="collapse-wrap desktop-disable"
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <Grid
                    theme={theme}
                    container
                    item
                    columnSpacing={2}
                    rowSpacing={3}
                  >
                    {customerFields.map((field) => {
                      const item = inputDefs.find(
                        (item) => item.name === field
                      );
                      return (
                        <Grid
                          key={item.name}
                          theme={theme}
                          item
                          xs={12}
                          md={4}
                          lg={3}
                        >
                          <ControlledFormElement {...item} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              ),
              title: (
                <>
                  Customer Details
                  {clientTypeName !== ClientTypes.INDIVIDUAL && (
                    <Typography
                      theme={theme}
                      variant="caption"
                      component="small"
                      className="text-primary fw-medium"
                      display={"block"}
                    >
                      <a
                        href="javascript:void(0)"
                        className="link text-primary decoration-none"
                        onClick={() => openDialog()}
                      >
                        Lookup from companies house
                      </a>
                    </Typography>
                  )}
                </>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      {showDirectorDetails && <DirectorsDetails />}
      <CompanyLookupDialog closeDialog={closeDialog} open={dialogOpen} />
    </>
  );
};
