import { Grid, Typography } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";

type ProposalFieldViewProps = {
  label: string;
  value: string;
};

export const ProposalFieldView = ({ label, value }: ProposalFieldViewProps) => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} item md={3} sm={5} xs={6}>
        <Typography
          theme={theme}
          component="span"
          variant="body2"
          className="label text-muted"
          children={label}
        />
      </Grid>
      <Grid theme={theme} item md={9} sm={7} xs={6}>
        <Typography
          theme={theme}
          component="span"
          variant="body2"
          className="value fw-medium"
          children={value}
        />
      </Grid>
    </>
  );
};
