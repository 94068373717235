import { AuthLayout, SignInForm } from "@components";
import { useTheme } from "@mui/material";
import LoginSideBarImage from "../../../public/assets/images/virgin-money-side-bar-img.svg";
import AppLogo from "../../../public/assets/images/virgin-money-full-logo.svg";

const SignInPageVirginMoney = () => {
  const theme = useTheme();

  return (
    <AuthLayout logoImage={AppLogo} sideBarImage={LoginSideBarImage}>
      <SignInForm title="Virgin Money" />
    </AuthLayout>
  );
};

export default SignInPageVirginMoney;
