import { AssetSelectionTitle, Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Grid } from "@ntpkunity/controls";
import { useFormContext } from "react-hook-form";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import { AssetDetailsFormProps } from "./asset-details-form.props";
import { useEffect } from "react";
import { TAssetDetails } from "@_types";
import { useAssetInputDefs, useDialogState } from "@hooks";
import { HardAssetConditions } from "@helpers/enum";
import ChooseAssetDialog from "../choose-asset-dialog/choose-asset-dialog";
import { Vehicle } from "@ntpkunity/controls-common";
import { convertVehicleToCamelCase } from "@helpers/utils";

const AssetDetailsForm = (props: AssetDetailsFormProps) => {
  const {
    dialogOpen: assetDialogOpen,
    openDialog: openAssetDialog,
    closeDialog: closeAssetDialog,
  } = useDialogState();
  const { watch, setValue, resetField, reset, getValues } =
    useFormContext<TAssetDetails>();
  const theme = useTheme();
  const assetCategory = watch("category");
  const assetCost = watch("cost");
  const assetQuantity = watch("quantity");
  const assetCondition = watch("condition");
  const assetType = watch("assetType");
  const rvBalloonAmount = watch("rvBalloonAmount");
  useEffect(() => {
    if (isNaN(Number(assetCost)) || isNaN(Number(assetQuantity))) return;
    setValue("totalCost", Number(assetCost) * Number(assetQuantity));
  }, [assetCost, assetQuantity]);

  useEffect(() => {
    if (isNaN(Number(rvBalloonAmount)) || isNaN(Number(assetQuantity))) return;
    setValue(
      "totalRvBalloonAmount",
      Number(rvBalloonAmount) * Number(assetQuantity)
    );
  }, [rvBalloonAmount, assetQuantity]);

  useEffect(() => {
    resetField("assetType");
    resetField("subType");
    resetField("condition");
  }, [assetCategory]);

  useEffect(() => {
    resetField("subType");
    resetField("condition");
  }, [assetType]);

  useEffect(() => {
    if (assetCondition === HardAssetConditions.NEW) {
      resetField("age");
    }
  }, [assetCondition]);

  const inputDefs = useAssetInputDefs(props.proposalCurrency);

  const onListViewClick = (vehicle: Vehicle) => {
    setValue("cost", vehicle.msrp);
    reset({
      ...getValues(),
      ...convertVehicleToCamelCase(vehicle),
      cost: vehicle.msrp,
    });
    closeAssetDialog();
  };

  return (
    <>
      <Stack
        paddingXs={3}
        paddingMd={3}
        title={
          <>
            Asset Details
            <AssetSelectionTitle setOpen={openAssetDialog} />
          </>
        }
      >
        <Box theme={theme} mt={3}>
          <Grid theme={theme} container item spacing={3}>
            {inputDefs.map((item, index) => (
              <Grid
                key={`${index} - ${item.name}`}
                theme={theme}
                item
                xs={12}
                md={4}
                lg={3}
              >
                <ControlledFormElement {...item} />
              </Grid>
            ))}
          </Grid>
          <Box theme={theme} mt={3} display="flex" gap={2}>
            {props.isEditMode && (
              <Button
                theme={theme}
                secondary
                text="Cancel"
                type="button"
                onClick={() => props.setEditAssetId(null)}
              />
            )}

            <Button
              theme={theme}
              primary
              text={`${props.isEditMode ? "Update" : "Save"} Asset`}
              type="button"
              onClick={props.handleSaveAsset}
            />
          </Box>
        </Box>
      </Stack>
      <ChooseAssetDialog
        open={assetDialogOpen}
        setOpen={openAssetDialog}
        close={closeAssetDialog}
        onListViewClick={onListViewClick}
      />
    </>
  );
};

export default AssetDetailsForm;
