import { removeDeletedEntities } from "@helpers/utils";
import { TInputDef } from "@components/proposal/proposal-details/types";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { useSetupsSelector } from "@hooks";

const useCustomerDetailsInputDefs = (): TInputDef[] => {
  const clientTypes = useSetupsSelector((state) => state.clientTypes);
  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  return [
    {
      name: "customerDetails.clientType",
      formElement: "Select",
      label: "Client Type",
      items: removeDeletedEntities(clientType, clientTypes.data)?.map(
        (clientType) => ({
          text: clientType.description,
          value: clientType.code,
        })
      ),
    },
    {
      name: "customerDetails.companyRegNum",
      formElement: "Input",
      placeHolder: "Company Registration Number",
      label: "Company Registration Number",
      type: "text",
    },
    {
      name: "customerDetails.companyName",
      formElement: "Input",
      placeHolder: "Company Name",
      label: "Company Name",
      type: "text",
    },
    {
      name: "customerDetails.tradingAs",
      formElement: "Input",
      placeHolder: "Trading As (Optional)",
      label: "Trading As (Optional)",
      type: "text",
    },
    {
      name: "customerDetails.natureOfBusiness",
      formElement: "Input",
      placeHolder: "Nature of Business (Optional)",
      label: "Nature of Business (Optional)",
      type: "text",
    },
    {
      name: "customerDetails.yearsInBusiness",
      formElement: "Input",
      placeHolder: "Years Business Established (Optional)",
      label: "Years Business Established (Optional)",
      type: "text",
    },
    {
      name: "customerDetails.sicCode",
      formElement: "Input",
      placeHolder: "SIC Code",
      label: "SIC Code",
      type: "text",
    },
    {
      name: "customerDetails.firstName",
      formElement: "Input",
      placeHolder: "First Name",
      label: "First Name",
      type: "text",
    },
    {
      name: "customerDetails.lastName",
      formElement: "Input",
      placeHolder: "Last Name",
      label: "Last Name",
      type: "text",
    },
    {
      name: "customerDetails.idNumber",
      formElement: "Input",
      placeHolder: "ID Number",
      label: "ID Number",
      type: "text",
    },
    {
      name: "customerDetails.emailAddress",
      formElement: "Input",
      placeHolder: "Email Address",
      label: "Email Address",
      type: "text",
    },
    {
      name: "customerDetails.contactNumber",
      formElement: "Input",
      placeHolder: "Contact Number (Optional)",
      label: "Contact Number (Optional)",
      type: "text",
    },
  ];
};

export default useCustomerDetailsInputDefs;
