import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getGeneratedDocuments } from "@services";

const useGetGeneratedDocuments = (referenceId, proposalId) => {
  return useQuery<number, unknown, any>({
    queryKey: [QUERY_KEYS.GET_GENERATED_DOCUMENTS, referenceId],
    queryFn: () => getGeneratedDocuments(referenceId, proposalId),
    enabled: !!referenceId,
  });
};

export default useGetGeneratedDocuments;
