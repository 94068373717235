import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import CelebrationIcon from "../../../public/assets/images/celebration-icon.gif";
import { BannerWrap } from "./banner.style";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { getDownloadDocumentPayload, isDealerRole } from "@helpers/utils";
import {
  QuotationForm,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import {
  CustomError,
  useSetupsSelector,
  useDownloadDocs,
  useGetAllLenders,
  useGetCompanyByTenantId,
  useGetCurrency,
} from "@hooks";
import { DownloadDocument, Events } from "@helpers/enum";
import { useSnackbarContext } from "@contexts/snackbar";
import { SNACKBAR_MESSAGES } from "@helpers/const";

export const Banner = () => {
  const theme = useTheme();
  const { watch, getValues: getProposalValues } =
    useFormContext<TProposalForm>();
  const { getValues: getFinanceDetailsValues } =
    useQuotationFormContext<QuotationForm>();
  const { data: calculations } = useCalculationsContext();
  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: lenders } = useGetAllLenders();
  const setups = useSetupsSelector((state) => state);
  const clientTypesQuery = setups.clientTypes;
  const financeTypesQuery = setups.financeTypes;
  const proposalCurrency = watch("proposalDetails.currency");
  const { data: currency } = useGetCurrency(proposalCurrency);
  const disableDownload =
    clientTypesQuery.isLoading ||
    clientTypesQuery.isError ||
    financeTypesQuery.isLoading ||
    financeTypesQuery.isError;

  const referenceNumber = watch("identifier");
  const name = watch("proposalDetails.name");

  const handleDownloadProposal = async () => {
    try {
      const dataPlaceholders = getDownloadDocumentPayload(
        {
          documentType: DownloadDocument.PROPOSAL,
          proposalValues: getProposalValues(),
          financialCalculations: calculations,
          financialValues: getFinanceDetailsValues(),
          setups: {
            financeTypes: financeTypesQuery.data,
            productTypes: setups.productTypes.data,
            clientTypes: clientTypesQuery.data,
            assetSubTypes: setups.assetSubTypes.data,
            assetCategories: setups.assetCategories.data,
            assetConditions: setups.assetConditions.data,
            assetTypes: setups.assetTypes.data,
            lenders: lenders,
          },
          companyId: companyInfo.id,
        },
        currency
      );

      await downloadDocument({
        payload: dataPlaceholders,
        eventName: Events.DOWNLOAD_PROPOSAL,
      });
    } catch (err) {
      let errorMessage = SNACKBAR_MESSAGES.ERROR_WHILE_DOWNLOADING_PROPOSAL;
      if (err instanceof CustomError) errorMessage = err.message;

      setSnackbar(
        {
          open: true,
          message: errorMessage,
          variant: "error",
        },
        5000
      );
    }
  };

  return (
    <Stack paddingXs={3} paddingMd={5}>
      <BannerWrap theme={theme} className="banner-wrap">
        <img src={CelebrationIcon} alt="CelebrationIcon" />
        <Box theme={theme}>
          <Typography theme={theme} component="h2" variant="h2">
            Congratulations
          </Typography>
          <Typography
            mt={2}
            theme={theme}
            component="span"
            variant="body2"
            className="text-muted"
            display={"block"}
          >
            Your proposal <span className="fw-medium text-dark">{name}</span>{" "}
            reference number{" "}
            <span className="fw-medium text-dark">{referenceNumber}</span> has
            been submitted to the {isDealerRole() ? "broker" : "lender"} for the
            review. Our representative will be in touch shortly to update you on
            progress.
          </Typography>
          <Box theme={theme} className="actions" mt={3}>
            <Button
              theme={theme}
              primary
              text="Download Proposal"
              disabled={disableDownload}
              onClick={handleDownloadProposal}
            />
          </Box>
        </Box>
      </BannerWrap>
    </Stack>
  );
};
