import { CompanySearchReqParams } from "@_types";
import { QUERY_KEYS } from "@helpers/query";

export const queryKeyFactory = {
  searchCompanies: (params: CompanySearchReqParams) => {
    return [QUERY_KEYS.SEARCH_COMPANIES, params];
  },
  saveFinanceConfig: () => [QUERY_KEYS.SAVE_FINANCE_CONFIG],
  saveUmsPermissions: () => [QUERY_KEYS.SAVE_UMS_PERMISSIONS],
};
