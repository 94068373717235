import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

const DocumentPageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-wrap": {
    ".page-header-wrap": {
      ".action-area": {
        ".action-item": {
          [theme.breakpoints.down("md")]: {
            "&:last-child": {
              display: "none",
            },
            ".ref-num": {
              "&.seperator": {
                "&::after": {
                  display: "none",
                },
              },
            },
          },
        },
      },
    },
    ".stepper-progress-wrap": {
      [theme.breakpoints.down("md")]: {
        top: 206,
      },
    },
    ".page-content": {
      [theme.breakpoints.down("md")]: {
        paddingTop: 220,
      },
    },
  },
}));

export default DocumentPageWrap;
