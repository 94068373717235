import { QuotationTool } from "@components/new-quotation";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { useTheme } from "@mui/material";
import { Box } from "@ntpkunity/controls";
import { useGetEvaluationParams } from "@hooks/proposal";

const FinanceDetails = () => {
  const theme = useTheme();

  const { setValue } = useFormContext<TProposalForm>();
  const { watch } = useQuotationFormContext<QuotationForm>();
  const financeType: string = watch("financeType");

  const evaluationParams = useGetEvaluationParams();

  useEffect(() => {
    setValue("proposalDetails.financeType", financeType);
  }, [financeType]);

  return (
    <Box
      theme={theme}
      className="quote-container"
      m={{ xs: 0, md: "-16px -40px -16px -40px" }}
    >
      <QuotationTool useComparisonDialog evaluationParams={evaluationParams} />
    </Box>
  );
};

export default FinanceDetails;
