import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Grid } from "@ntpkunity/controls";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { TProposalForm } from "@_types";
import { useProposalDetailsDefs } from "@hooks";
export const ProposalDetailsForm = () => {
  const theme = useTheme();
  const inputsDefs = useProposalDetailsDefs();
  const { setValue } = useQuotationFormContext<QuotationForm>();
  const { watch } = useFormContext<TProposalForm>();
  const financeType = watch("proposalDetails.financeType");
  useEffect(() => {
    if (financeType) setValue("financeType", financeType);
  }, [financeType]);

  return (
    <Stack paddingXs={3} paddingMd={3}>
      <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
        {inputsDefs.map((value, index) => {
          return (
            <Grid
              key={`${index} - ${value.name}`}
              theme={theme}
              item
              xs={12}
              md={4}
              lg={3}
            >
              <ControlledFormElement {...value} />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
