import { sendEmailNotification } from "@services";
import { useMutation } from "react-query";

const useSendEmailNotification = () => {
  return useMutation({
    mutationFn: sendEmailNotification,
  });
};

export default useSendEmailNotification;
