import { useMutation, UseMutationOptions } from "react-query";
import { saveComparison } from "@services";
import { CreateQuotationComparison, QuotationComparisonRes } from "@_types";
import { AxiosError } from "axios";

type UseSaveComparisonOptions = Omit<
  UseMutationOptions<
    QuotationComparisonRes,
    AxiosError,
    CreateQuotationComparison
  >,
  "mutationFn" | "mutationKey"
>;

const useSaveComparison = (options: UseSaveComparisonOptions = {}) => {
  return useMutation({
    mutationFn: saveComparison,
    ...options,
  });
};

export default useSaveComparison;
