import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu, Tooltip } from "@ntpkunity/controls";
import { ProposalTableRowProps } from "./proposal-table-row.props";
import {
  pounds,
  getProposalRowOptions,
  getDownloadDocumentPayload,
  getFormattedDate,
} from "@helpers/utils";
import {
  DownloadDocument,
  Events,
  OrderStatus,
  ProposalTabs,
  TableRowOptions,
  WorkQueueTabs,
  ProposalMode,
} from "@helpers/enum";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import ProposalStatus from "./proposal-status/proposal-status.component";
import { TProposalWorkQueueEntry } from "@_types/workQueues";
import { getProposalByReferenceNumber } from "@services/dms.service";
import {
  mapProposalPayload,
  useGetAllLenders,
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetSubTypes,
  useGetAssetTypes,
  useGetClientTypes,
  useGetCompanyByTenantId,
  useGetFinanceTypes,
  useGetProductTypes,
} from "@hooks/queries";
import { useDownloadDocs } from "@hooks/mutations";
import { useSnackbarContext } from "@contexts/snackbar";
import { FinanceTypeCell } from "@components/work-queue";
import { SNACKBAR_MESSAGES } from "@helpers/const";

const ProposalTableRow = ({
  proposal,
  queue,
  openConfirmDialog,
  openStatusDialog,
  openConditionDialog,
  openDiaryDialog,
}: ProposalTableRowProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const {
    isLoading: clientTypesLoading,
    isError: isErrorInClientTypes,
    data: clientTypes,
  } = useGetClientTypes();
  const {
    isLoading: financeTypesLoading,
    isError: isErrorInFinanceTypes,
    data: financeTypes,
  } = useGetFinanceTypes();
  const { data: productTypes } = useGetProductTypes();
  const { data: assetSubTypes } = useGetAssetSubTypes();
  const { data: assetCategories } = useGetAssetCategories();
  const { data: assetConditions } = useGetAssetConditions();
  const { data: assetTypes } = useGetAssetTypes();
  const { data: lenders } = useGetAllLenders();

  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();
  const updatedAt = getFormattedDate(proposal.updated_at, "DD/MM/YYYY");
  const dateSubmitted = getFormattedDate(proposal.date_submitted, "DD/MM/YYYY");
  const startDate = getFormattedDate(proposal.start_date, "DD/MM/YYYY");
  const endDate = getFormattedDate(proposal.end_date, "DD/MM/YYYY");
  const isTrackProposal = queue === WorkQueueTabs.TRACK_PROPOSALS;
  const disableDownload =
    clientTypesLoading ||
    isErrorInClientTypes ||
    financeTypesLoading ||
    isErrorInFinanceTypes;

  const handleDownloadProposal = async (identifier: string) => {
    try {
      const response = await getProposalByReferenceNumber(identifier);
      const {
        proposal,
        quotation: { formValues, calculations },
      } = mapProposalPayload(response);

      const dataPlaceholders = getDownloadDocumentPayload({
        documentType: DownloadDocument.PROPOSAL,
        proposalValues: proposal,
        financialCalculations: calculations,
        financialValues: formValues,
        setups: {
          financeTypes: financeTypes,
          productTypes: productTypes,
          clientTypes: clientTypes,
          assetSubTypes: assetSubTypes,
          assetCategories: assetCategories,
          assetTypes: assetTypes,
          assetConditions: assetConditions,
          lenders: lenders,
        },
        companyId: companyInfo.id,
      });

      await downloadDocument({
        payload: dataPlaceholders,
        eventName: Events.DOWNLOAD_PROPOSAL,
      });
    } catch (err) {
      setSnackbar(
        {
          open: true,
          message: SNACKBAR_MESSAGES.ERROR_WHILE_DOWNLOADING_PROPOSAL,
          variant: "error",
        },
        5000
      );
    }
  };

  const handleOptionClick = async (
    _,
    key: TableRowOptions,
    proposal: TProposalWorkQueueEntry
  ) => {
    if (key === TableRowOptions.OPEN_DIARY) {
      openDiaryDialog({
        proposalId: proposal.identifier,
      });
      return;
    }
    if (key === TableRowOptions.GENERATE_DOCUMENTS) {
      navigate(APP_ROUTES.DOCUMENT);
      return;
    }
    if (key === TableRowOptions.SEND_FOR_PAYOUT) {
      navigate(APP_ROUTES.SEND_FOR_PAYOUT);
      return;
    }
    if (key === TableRowOptions.CLONE) {
      navigate(APP_ROUTES.PROPOSAL, {
        state: { proposalId: proposal.identifier },
      });
      return;
    }
    if (key === TableRowOptions.CHECK_SIGNATORY_STATUS) {
      navigate(APP_ROUTES.SIGNATORY_STATUS);
      return;
    }
    if (key === TableRowOptions.VIEW_CONDITIONS) {
      openConditionDialog();
      return;
    }
    if (key === TableRowOptions.EDIT) {
      navigate(
        `${APP_ROUTES.PROPOSAL}/${ProposalMode.EDIT}/${proposal.identifier}`
      );
      return;
    }
    if (key === TableRowOptions.VIEW) {
      navigate(
        `${APP_ROUTES.PROPOSAL}/${ProposalMode.VIEW}/${proposal.identifier}`
      );
      return;
    }
    if (key === TableRowOptions.PROPOSAL_SUMMARY) {
      navigate(
        `${APP_ROUTES.PROPOSAL}/${ProposalMode.VIEW}/${proposal.identifier}`,
        {
          state: { tab: ProposalTabs.SUMMARY },
        }
      );
      return;
    }
    if (key === TableRowOptions.UPLOAD) {
      navigate(
        `${APP_ROUTES.PROPOSAL}/${ProposalMode.VIEW}/${proposal.identifier}`,
        {
          state: {
            tab: ProposalTabs.CREDIT_DOCUMENTS,
          },
        }
      );
      return;
    }
    if (key === TableRowOptions.GENERATE_COMPLIANCE_DOCUMENTS) {
      navigate(`${APP_ROUTES.COMPLIANCE_DOCUMENT}/${proposal.identifier}`);
      return;
    }
    if (key === TableRowOptions.VIEW_COMPLIANCE_DOCUMENTS) {
      navigate(`${APP_ROUTES.COMPLIANCE_DOCUMENT}/${proposal.identifier}`);
      return;
    }
    if (key === TableRowOptions.MANUAL_STATUS_CHANGE) {
      openStatusDialog({
        identifier: proposal.identifier,
        status: proposal.status,
        name: proposal.name,
      });
      return;
    }
    if (key === TableRowOptions.WITHDRAW) {
      openConfirmDialog({
        identifier: proposal.identifier,
        status: proposal.status,
        referenceNumber: proposal.reference_number,
        name: proposal.name,
        nextStatus: OrderStatus.WITHDRAWN,
      });
      return;
    }
    if (key === TableRowOptions.DOWNLOAD) {
      await handleDownloadProposal(proposal.identifier);
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>
        <ProposalStatus status={proposal.status} />
      </TableCell>
      <TableCell>
        <Tooltip title={proposal.name} placement="top" theme={theme}>
          <>{proposal.name}</>
        </Tooltip>
      </TableCell>
      <TableCell>
        <FinanceTypeCell financeTypeCode={proposal.finance_type} />
      </TableCell>
      <TableCell>{proposal.customer_name}</TableCell>
      <TableCell className="text-right">
        {pounds.format(proposal.finance_amount)}
      </TableCell>
      <TableCell>{updatedAt}</TableCell>
      {isTrackProposal && (
        <>
          <TableCell>{dateSubmitted}</TableCell>
          <TableCell>{startDate}</TableCell>
          <TableCell>{endDate}</TableCell>
        </>
      )}

      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={getProposalRowOptions(proposal, disableDownload)}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ProposalTableRow;
