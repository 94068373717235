import { TAssetDetails, TProposalForm } from "@_types";
import {
  useGetClientTypeName,
  useGetProductTypeName,
  useGetAssetCategoryName,
  useGetAssetTypeName,
  useGetAssetConditionName,
  useGetCompanyByTenantId,
  useGetDealers,
} from "@hooks";
import { EvaluationExternalParams } from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";

const getHighestPricedAsset = (assets: TAssetDetails[]) => {
  const maxPrice = assets.reduce(
    (prev, cur) => Math.max(prev, cur.totalCost),
    -Infinity
  );
  return assets.find((asset) => asset.totalCost === maxPrice);
};

const useGetEvaluationParams = (): EvaluationExternalParams => {
  const { watch } = useFormContext<TProposalForm>();
  const productType = watch("proposalDetails.productType");
  const productTypeName = useGetProductTypeName(productType);
  const clientType = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientType);
  const yearsInBusiness = watch("customerDetails.yearsInBusiness");
  const creditTier = watch("customerDetails.creditTier");
  const { data: companyInfo } = useGetCompanyByTenantId();
  const assets = watch("assets");
  const maxPricedAsset = getHighestPricedAsset(assets);
  const assetCategoryName = useGetAssetCategoryName(maxPricedAsset?.category);
  const assetConditionName = useGetAssetConditionName(
    maxPricedAsset?.condition
  );
  const assetTypeName = useGetAssetTypeName(maxPricedAsset?.type);
  const { data: dealerAssociation } = useGetDealers();

  return {
    asset_type: assetTypeName,
    asset_category: assetCategoryName,
    asset_condition: assetConditionName,
    business_trading_age: yearsInBusiness,
    company_code: companyInfo.company_code,
    customer_type: clientTypeName,
    product_type: productTypeName,
    dealer_code: dealerAssociation?.dealer_code || null,
    credit_rating: creditTier,
  };
};

export default useGetEvaluationParams;
