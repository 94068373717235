import { useQuery } from "react-query";
import { queryKeyFactory } from "@helpers/query";
import { searchCompanies } from "@services";
import {
  CompaniesByNameResponse,
  CompaniesByRegNumResponse,
  CompanySearchReqParams,
} from "@_types";
import { UseQueryOptions } from "react-query";

type FnParams = CompanySearchReqParams & { xServiceProvider: string };
type QueryParams = Omit<
  UseQueryOptions<CompaniesByNameResponse[] | CompaniesByRegNumResponse[]>,
  "queryKey" | "queryFn"
>;

const useSearchCompanies = ({
  params: { xServiceProvider, ...params },
  enabled,
  ...queryParams
}: QueryParams & {
  params: FnParams;
}) => {
  const useExternalEnabled = typeof enabled !== "undefined";
  const isQueryEnabled = useExternalEnabled
    ? enabled
    : !!xServiceProvider &&
      (!!params.company_name || !!params.registration_number);

  return useQuery({
    queryKey: queryKeyFactory.searchCompanies(params),
    queryFn: () => searchCompanies(xServiceProvider, params),
    enabled: isQueryEnabled,
    ...queryParams,
  });
};

export default useSearchCompanies;
