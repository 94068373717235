import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getWorkflowTaskByRunningId } from "@services";

const useGetWorkflowTask = (runningId: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_WORKFLOW_TASK_BY_RUNNING_ID, runningId],
    queryFn: async () => getWorkflowTaskByRunningId(runningId),
    enabled: !!runningId,
  });
};

export default useGetWorkflowTask;
