import { UseFormReturn } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  Box,
  Grid,
  Input,
  Select,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { formatPermissionName, PERMISSION_OPTIONS } from "@helpers/const";
import { useMemo } from "react";
import { getPermissionKey as getRolePermissionKey } from "@hooks";
import { FieldPermissions, getPermissionKey } from "@ntpkunity/controls-common";
import { PermissionsForm } from "@_types";
import { PermissionType } from "@helpers/enum";

export const PermissionsRow = ({
  formMethods,
  financeType,
  permissionName,
}: {
  formMethods: UseFormReturn<PermissionsForm>;
  financeType: string;
  permissionName: FieldPermissions;
}) => {
  const theme = useTheme();
  const { control, watch, setValue } = formMethods;

  const selectedRoleId = watch("selectedRoleId");
  const rolePermKey = getRolePermissionKey(financeType, selectedRoleId);

  const permPathBase = useMemo(
    (): `permissions.${string}.${string}` =>
      `permissions.${rolePermKey}.${getPermissionKey(
        financeType,
        permissionName as FieldPermissions
      )}`,
    [rolePermKey, financeType]
  );

  const permission = watch(permPathBase);

  const isViewType = permission.permission_type === PermissionType.VIEW;
  const isTextType = permission.permission_type === PermissionType.TEXT;

  const switchDefs = useMemo(
    () => [
      {
        name: "is_enabled",
        switchEnabled: permission.is_enabled,
        label: "Enabled",
        disabled: permission.is_mandatory || isViewType,
      },
      {
        name: "is_view",
        switchEnabled: permission.is_view,
        label: "Show",
        disabled: permission.is_mandatory,
      },
      {
        name: "is_mandatory",
        switchEnabled: permission.is_mandatory,
        label: "Mandatory",
        disabled: isViewType,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            setValue(`${permPathBase}.is_enabled`, true);
            setValue(`${permPathBase}.is_view`, true);
          }
        },
      },
    ],
    [permission.is_enabled, permission.is_mandatory, permission.is_view]
  );

  return (
    <Box theme={theme} className="setting-item">
      <Grid
        theme={theme}
        container
        item
        columnSpacing={{ xs: 2, md: 5 }}
        rowSpacing={2}
        alignItems={"center"}
      >
        <Grid theme={theme} item xs={12} md={3} lg={3} xl={3}>
          <Typography theme={theme} component={"p"} variant="subtitle1">
            {formatPermissionName(permission?.name)}
          </Typography>
        </Grid>
        <Grid theme={theme} item xs={12} md={4} lg={5}>
          <Controller
            name={`${permPathBase}.default_value`}
            control={control}
            render={({ field }) => {
              if (isViewType || isTextType) {
                return (
                  <Input
                    theme={theme}
                    fullWidth
                    type="text"
                    placeholder={isViewType ? "Output" : "Type here..."}
                    readOnly={isViewType}
                    label="Default Value"
                    {...field}
                    value={isViewType ? "Output" : field.value}
                  />
                );
              }
              return (
                <Select
                  theme={theme}
                  fullWidth
                  placeholder="Select an option..."
                  label="Options"
                  items={
                    PERMISSION_OPTIONS[formatPermissionName(permission?.name)]
                  }
                  {...field}
                />
              );
            }}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={5} lg={4}>
          <Grid
            theme={theme}
            container
            item
            columnSpacing={{ xs: 3, md: 5 }}
            rowSpacing={2}
            alignItems={"center"}
          >
            {switchDefs.map(
              ({ name, switchEnabled, label, disabled, ...props }) => {
                return (
                  <Grid
                    theme={theme}
                    item
                    xs={6}
                    sm={4}
                    key={`${permPathBase}.${name}`}
                  >
                    <Controller
                      name={`${permPathBase}.${name}`}
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          theme={theme}
                          varient="basic"
                          switchEnabled={switchEnabled}
                          label={label}
                          disabled={disabled}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            props.onChange?.(e);
                          }}
                        />
                      )}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
