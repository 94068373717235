import {
  AssetTypesSetupRes,
  AssetSubTypesSetupRes,
  AssetCategoriesSetupRes,
} from "@_types";
import { useSetupsSelector } from "@hooks";

type GroupedData<T> = { [key: string]: T[] };

function getGroupedData<K extends { id: number; code: string }, D>(
  key: keyof D,
  keyData: K[],
  data: D[]
): GroupedData<D> {
  const idToCodeMap = keyData.reduce((prev, cur) => {
    prev[cur.id] = cur.code;
    return prev;
  }, {});

  const groupedData = data.reduce((prev: GroupedData<D>, cur: D) => {
    const groupKey = String(idToCodeMap[cur[key] as number]);
    if (!prev[groupKey]) {
      prev[groupKey] = [cur];
    } else {
      prev[groupKey].push(cur);
    }
    return prev;
  }, {});

  return groupedData;
}

const getAssetTypes = (
  categories: AssetCategoriesSetupRes,
  data: AssetTypesSetupRes
) => getGroupedData("asset_category_id", categories, data);

const getAssetSubTypes = (
  types: AssetTypesSetupRes,
  data: AssetSubTypesSetupRes
) => getGroupedData("asset_type_id", types, data);

const getConcatenatedAssets = (
  categories: AssetCategoriesSetupRes,
  types: {
    [key: string]: AssetTypesSetupRes;
  },
  subTypes: {
    [key: string]: AssetSubTypesSetupRes;
  }
) => {
  const result: { text: string; value: string }[] = [];
  for (const code in categories) {
    const category = categories[code];
    const categoryName = category.description;
    const categoryCode = category.code;
    const categoryTypes = types[categoryCode];

    if (categoryTypes) {
      for (const code in categoryTypes) {
        const type = categoryTypes[code];
        const typeName = type.name;
        const typeCode = type.code;
        const typeSubTypes = subTypes[typeCode];

        if (typeSubTypes) {
          for (const code in typeSubTypes) {
            const subType = typeSubTypes[code];
            const subTypeName = subType.name;
            const subTypeCode = subType.code;

            result.push({
              text: `${categoryName} - ${typeName} - ${subTypeName}`,
              value: `${categoryCode}-${typeCode}-${subTypeCode}`,
            });
          }
        } else {
          result.push({
            text: `${categoryName} - ${typeName}`,
            value: `${categoryCode}-${typeCode}`,
          });
        }
      }
    } else {
      result.push({ text: categoryName, value: categoryCode });
    }
  }

  return result;
};

const useAssetSetups = (retainData?: { category?: string; type?: string }) => {
  const setups = useSetupsSelector((state) => state);
  const { assetCategories, assetTypes: assetTypeList } = useSetupsSelector(
    (state) => ({
      assetCategories: state.getFilteredAssetCategories(retainData?.category)
        .assetCategories,
      assetTypes: state.getFilteredAssetTypes(retainData?.type).assetTypes,
    })
  );

  const subTypesQuery = setups.assetSubTypes;
  const conditionQuery = setups.assetConditions;
  const categoriesLoaded = assetCategories && assetCategories.length;
  const assetTypesLoaded = assetTypeList && assetTypeList.length;
  const assetSubTypesLoaded =
    subTypesQuery.isSuccess && subTypesQuery.data.length;
  const assetTypes =
    categoriesLoaded && assetTypesLoaded
      ? getAssetTypes(assetCategories, assetTypeList)
      : {};
  const assetSubTypes =
    assetTypesLoaded && assetSubTypesLoaded
      ? getAssetSubTypes(assetTypeList, subTypesQuery.data)
      : {};

  const assetConditions =
    conditionQuery.isSuccess && conditionQuery.data.length
      ? conditionQuery.data
      : [];

  const concatenations =
    categoriesLoaded && assetTypesLoaded && assetSubTypesLoaded
      ? getConcatenatedAssets(assetCategories, assetTypes, assetSubTypes)
      : [];

  return {
    assetCategories,
    assetTypes,
    assetSubTypes,
    assetConditions,
    concatenations,
  };
};

export default useAssetSetups;
