import { useTheme } from "@mui/material";
import { AdditionalDetailsWrap } from "./additional-detail.style";
import AdditionalDetailList from "./additional-detail-list.component";

export const AdditionalDetail = () => {
  const theme = useTheme();
  return (
    <AdditionalDetailsWrap theme={theme} className="additional-details-wrap">
      <AdditionalDetailList />
    </AdditionalDetailsWrap>
  );
};
