import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getQuotationByIdentifier } from "@services";
import { GetQuotationResponse, BrokerSpecifcFormFields } from "@_types";
import {
  CalculationResults,
  QuotationForm,
  BALLOON_COLLECTION_ARREAR,
  AmountType,
  SolveFor,
  StepType,
  VatDetails,
  getRentalTaxTxId,
} from "@ntpkunity/controls-common";

type SelectQuotationData = {
  formValues: QuotationForm;
  brokerSpecificFormValues: BrokerSpecifcFormFields;
  calculations: CalculationResults;
  corporateTaxAmount: number;
  vatDetails: VatDetails;
};

export const useGetQuotationByIdentifier = (
  identifier: string,
  onSuccess?: (data: SelectQuotationData) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.QUOTATION_BY_IDENTIFIER, identifier],
    queryFn: async () => getQuotationByIdentifier(identifier),
    enabled: !!identifier,
    onSuccess: onSuccess,
    cacheTime: 0,
    select: mapQuotationData,
  });
};

export const mapQuotationData = (
  data: GetQuotationResponse
): SelectQuotationData => {
  const shouldPopulateVatTax = !!data.order_payments?.[0]?.tax_amount;
  return {
    formValues: {
      solveFor: data.calculations_type,
      financeType: data.finance_type,
      assetCost: data.selling_price,
      deposit: data.down_payment,
      depositType: AmountType.POUND,
      commission: data.commission_amount,
      commissionType: AmountType.POUND,
      nonVatableAmount: data.non_vatable_amount || 0,
      marginPercentage: 0,
      baseRate: 0,
      rate: data.margin,
      rateType: data.rate_type,
      noOfAdvancePayments: data.no_of_advance_payments,
      noOfRegularPayments: data.no_of_regular_payments,
      balloonPayment: data.rv_balloon_value,
      balloonCollection: BALLOON_COLLECTION_ARREAR.find(
        (collection) => collection.text === data.balloon_collection
      ).value,
      balloonExtensionTerms: data.balloon_extension_terms,
      vatType: data.vat_treatment,
      vatAmount: data.deferral_amount,
      vatNumber: data.tax_amount_number || 0,
      vatDeferredType: data.deferred_type,
      paymentFrequency: data.rental_frequency,
      startDate: data.start_date,
      periodicPaymentAmount:
        data.calculations_type !== SolveFor.PERIODIC_PAYMENT
          ? data.estimated_monthly_payment
          : undefined,
      structureRentalsApplied: false,
      structureStepPaymentsApplied: false,
      structureRentals: [],
      stepPayments: {
        applied: false,
        stepInterval: 0,
        stepPercentage: 0,
        stepType: StepType.STEP_UP,
      },
      fees: data.order_fees.map((fee) => {
        return {
          identifier: fee.identifier,
          amount: fee.applied_price,
          type: fee.fee_frequency,
          name: fee.dealer_fee_name,
          frequency: fee.fee_occurance,
          isEditable: true,
          firstFeePaymentDueAtStartDate: false,
        };
      }),
    },
    brokerSpecificFormValues: {
      brokerName: data.introducer_name,
      clientType: data.customer?.customer_type,
      identifier: data.customer?.identifier,
      email: data.customer?.email,
      asset: {
        identifier: data.assets[0].identifier,
        type: data.assets[0].asset_type,
      },
      name: data.name,
      createdAt: data.created_at,
    },
    calculations: {
      endDate: data.end_date,
      commissionAmount: data.commission_amount,
      financeAmount: data.finance_amount,
      rates: {
        grossYield: data.meta_data?.gross_yield,
        netYield: data.meta_data?.net_yield,
        flatRateInclCommission: data.meta_data.flat_rate_commission_inc,
        flatRateExclCommission: data.meta_data.flat_rate_commission_exc,
        apr: data.meta_data.apr,
      },
      sumOfFees: data.sum_of_fees,
      sumOfPeriodicInterest: data.sum_of_periodic_interest,
      totalPayables: data.total_payables,
      sumOfRentals: data.sum_of_rentals,
      sumOfAdvanceRentals: data.sum_of_advance_rentals,
      assetCost: data.selling_price,
      depositAmount: data.down_payment,
      rentalSummary: data.order_payments
        .map((payment) => {
          return {
            startTerm: payment.payment_number_from,
            endTerm: payment.payment_number_to,
            rentalAmount: payment.amount,
            rentalType: payment.payment_type,
          };
        })
        .sort((a, b) => {
          return a.startTerm - b.startTerm;
        }),
      repaymentPlan: data.order_repayment_plan?.map((planItem) => ({
        charges: planItem.charges,
        closingPrincipal: planItem.closing_principal,
        compoundInterest: planItem.compound_interest,
        identifier: planItem.identifier,
        openingPrincipal: planItem.opening_principal,
        periodicInterest: planItem.periodic_interest,
        rentalAmount: planItem.rental_amount,
        rentalDueDate: planItem.rental_due_date,
        rentalInterest: planItem.rental_interest,
        rentalNumber: planItem.rental_number,
        rentalPrincipal: planItem.rental_principal,
        rentalType: planItem.rental_type,
      })),
    },
    corporateTaxAmount: data.corporate_tax,
    vatDetails: shouldPopulateVatTax
      ? data.order_payments.reduce((acc, payment, index) => {
          const transactionId = getRentalTaxTxId(index);
          acc[transactionId] = {
            vatAmount: payment.tax_amount,
            vatPercentage: payment.tax_percentage,
            vatExclusiveAmount: payment.amount,
            vatInclusiveAmount:
              Number(payment.amount) + Number(payment.tax_amount),
          };
          return acc;
        }, {})
      : [],
  };
};
