import { useEffect } from "react";
import { useGetCompanyByTenantId, useGetThemebyEmail } from "./queries";
import { useGetDealers } from "./queries";
import { isDealerRole } from "@helpers/utils";
import { setFavicon, setLogo, setStyleSheet } from "@helpers/theme";

export const useSetUserTheme = () => {
  const { data: companyInfo, isSuccess: companySuccess } =
    useGetCompanyByTenantId();
  const { data: dealerAssociation, isSuccess: dealerAssociationSuccess } =
    useGetDealers();

  const _isDealerRole = isDealerRole();

  const email =
    !_isDealerRole && companySuccess
      ? companyInfo?.email
      : _isDealerRole && dealerAssociationSuccess && dealerAssociation
      ? dealerAssociation.email
      : undefined;

  const { data: theme, isSuccess: themeSuccess } = useGetThemebyEmail({
    email: email,
    enabled: !!email,
  });

  useEffect(() => {
    document.title = "Link";
    if (theme && themeSuccess) {
      setStyleSheet(theme.stylesheet);
      setFavicon(theme.favicon?.location);
      setLogo(theme.logo?.location);
    }
  }, [theme, themeSuccess]);
};
