import { LOCAL_STORAGE_KEYS } from "@helpers/const";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { handleRefreshToken, logout } from "@ntpkunity/controls-ums";

export const requestIntercepter = (config: InternalAxiosRequestConfig<any>) => {
  switch (config.baseURL) {
    default: {
      config.headers["Authorization"] = `Bearer ${
        (JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS))
          ?.access_token as string) ?? ""
      }`;
      break;
    }
  }
  return config;
};

export const successResponseInterceptor = (
  response: AxiosResponse<any, any>
) => {
  return response;
};

export const errorResponseInterceptor = async (error: AxiosError) => {
  const response = await handleRefreshToken(error, axios, logout);
  return response;
};
