import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Router } from "@router";
import { BROKER_APP_THEME, BROKER_APP_THEME_WRAP } from "styles";
import { SnackbarContextProvider } from "@contexts/snackbar";
import {
  LoginValidatorComponent,
  UMSConfigurationComponent,
} from "@ntpkunity/controls-ums";
import {
  useGetCompanyByTenantId,
  useGetDealers,
  SetupsContextProvider,
  useSetUserTheme,
} from "@hooks";
import { Button, CircleLoader, Icon, PageAlert } from "@ntpkunity/controls";
import { isDealerRole } from "@helpers/utils";

export default function Root() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: false,
      },
    },
  });
  const url = process.env.UNITY_FE_BASE_URL;
  return (
    <UMSConfigurationComponent>
      <LoginValidatorComponent theme={BROKER_APP_THEME} url={url}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </LoginValidatorComponent>
    </UMSConfigurationComponent>
  );
}

const App = () => {
  useSetUserTheme();
  const _isDealerRole = isDealerRole();

  const { isLoading: companyInfoLoading, isError: isErrorInCompanyInfo } =
    useGetCompanyByTenantId();
  const { isLoading: dealerLoading, isError: isDealerFetchingFailed } =
    useGetDealers();

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={BROKER_APP_THEME}>
        <BROKER_APP_THEME_WRAP
          className="global-theme-wrap"
          theme={BROKER_APP_THEME}
        >
          {/* 
            SetupsContextProvider is responsible for 
            managing the data fetching for all the setups and 
            the children can use useSetupsSelector to consume
            these setups
          */}
          <SetupsContextProvider>
            <SnackbarContextProvider>
              {companyInfoLoading || (_isDealerRole && dealerLoading) ? (
                <CircleLoader theme={BROKER_APP_THEME} />
              ) : isErrorInCompanyInfo ||
                (_isDealerRole && isDealerFetchingFailed) ? (
                <PageAlert
                  theme={BROKER_APP_THEME}
                  icon="DialogAlertIcon"
                  title="Oops! Unexpected Error occurred."
                  variant="full-page"
                  message="We encountered an unexpected error. Please refresh the page."
                  actionArea={
                    <>
                      <Button
                        theme={BROKER_APP_THEME}
                        fullWidth
                        danger
                        startIcon={<Icon name="ResetIcon" />}
                        text="Refresh Page"
                        onClick={() => window.location.reload()}
                      />
                    </>
                  }
                />
              ) : (
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              )}
            </SnackbarContextProvider>
          </SetupsContextProvider>
        </BROKER_APP_THEME_WRAP>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
