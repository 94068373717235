import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { UserListingComponent, UMSStoreContext } from "@ntpkunity/controls-ums";
import {
  LayoutWithSideNavComponent,
  PageHeader,
  Snackbar,
  Stack,
} from "@components";
import { useSnackbarContext } from "@contexts/snackbar";
import UserManagementPageWrap, {
  UmsDrawerWrap,
} from "./user-management-page.style";
import { DataTableWrap } from "@styles/data-table-wrap";
import { useGetDealers } from "@hooks";
import { isDealerRole } from "@helpers/utils";
import { CircleLoader } from "@ntpkunity/controls";

const ManageUsersPage = () => {
  const { setSnackbar } = useSnackbarContext();
  const { data: dealerAssociation, isLoading: dealerAssociationLoading } =
    useGetDealers();
  const [response, setResponse] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [tenantId, setTenantId] = useState<number>(null);
  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      setTenantId(+access_token_local?.tenant_id);
    }
  }, []);
  useEffect(() => {
    if (response?.status == 200) {
      setSnackbar({ open: true, message: response?.data?.message }, 2000);
    } else if (response?.status) {
      setSnackbar(
        { open: true, message: response?.message, variant: "error" },
        2000
      );
    }
  }, [response]);
  const {
    states: { userRole: userRole, productId: productId },
  } = useContext(UMSStoreContext) as any;
  const theme = useTheme();

  const _isDealerRole = isDealerRole();
  const shouldRenderUM = tenantId && (!_isDealerRole || !!dealerAssociation);

  return (
    <UmsDrawerWrap open={open}>
      <LayoutWithSideNavComponent theme={theme}>
        <PageHeader title={"User Management"}></PageHeader>
        <UserManagementPageWrap theme={theme} className="page-content" pt={3}>
          {_isDealerRole && dealerAssociationLoading ? (
            <CircleLoader theme={theme} />
          ) : shouldRenderUM ? (
            <>
              <Stack paddingXs={3} paddingMd={3}>
                <DataTableWrap theme={theme}>
                  <UserListingComponent
                    theme={theme}
                    setResponse={setResponse}
                    tenantId={tenantId}
                    value={""}
                    selectedEntityId={dealerAssociation?.id}
                    entities={
                      dealerAssociation
                        ? [
                            {
                              value: dealerAssociation?.id,
                              text:
                                dealerAssociation?.dealer_name ||
                                `Dealer ${dealerAssociation?.id}`,
                            },
                          ]
                        : []
                    }
                    entityType={"Dealer"}
                    userRole={userRole}
                    productId={productId?.productId}
                    setopenAddComponent={setOpen}
                    openAddComponent={open}
                    actionType={"add"}
                    componentBehavior={"drawer"}
                    nextScreenUrl={`/market-place/app-user-profile`}
                    openPopup={false}
                    close={() => {}}
                    layoutText={{
                      nameLabel: "First/Last Name",
                      emailAddressLabel: "Email Address",
                      productsLabel: "Product",
                      rolesLabel: "Role",
                      contactNumLabel: "Contact Number",
                      statusLabel: "Status",
                    }}
                  ></UserListingComponent>
                </DataTableWrap>
              </Stack>
              <Box theme={theme} mt={2}>
                <Button
                  theme={theme}
                  secondary
                  fullWidth
                  type="submit"
                  text={"Add New User"}
                  onClick={(e) => {
                    setOpen(true);
                  }}
                />
              </Box>
            </>
          ) : undefined}
        </UserManagementPageWrap>
      </LayoutWithSideNavComponent>
      <Snackbar />
    </UmsDrawerWrap>
  );
};
export default ManageUsersPage;
