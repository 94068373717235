import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Accordion } from "@ntpkunity/controls";
import { DirectorDetailsWrap } from "./director-details.style";
import { DirectorTable } from "./director-table/director-table.component";
import { DirectorDialog } from "./director-dialog/director-dialog.component";
import { useState } from "react";
import { DirectorDetailsProps } from "./director-details.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { useGetClientTypeName } from "@hooks";
import { getClientTypeTitle } from "@helpers/utils";

export const DirectorsDetails = ({
  showAddBtn = true,
}: DirectorDetailsProps) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [editDirectorId, setEditDirectorId] = useState<string | null>(null);

  const { watch } = useFormContext<TProposalForm>();
  const isViewMode = watch("isViewMode");
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);

  return (
    <>
      <DirectorDetailsWrap className="director-details-wrap" theme={theme}>
        <Stack
          paddingXs={3}
          paddingMd={3}
          marginTopMd={2}
          marginTopXs={2}
          className="collapse-wrap"
        >
          <Accordion
            theme={theme}
            items={[
              {
                content: (
                  <DirectorTable
                    setOpenDialog={setOpenDialog}
                    setEditDirectorId={setEditDirectorId}
                    showAddBtn={showAddBtn}
                  />
                ),
                title: getClientTypeTitle(clientTypeName, "", "Details", true),
                key: "string",
                isExpanded: true,
                id: "string",
                ariaControls: "string",
              },
            ]}
          />
        </Stack>
      </DirectorDetailsWrap>
      <DirectorDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        editDirectorId={editDirectorId}
        setEditDirectorId={setEditDirectorId}
      />
    </>
  );
};
