import { Accordion, Button, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AdditionalDetailsListWrap } from "./additional-detail.style";
import { Stack } from "@components/stack";
import AdditionalDetailContent from "./additional-detail-content.component";

const AdditionalDetailList = () => {
  const theme = useTheme();
  return (
    <AdditionalDetailsListWrap
      theme={theme}
      className="additional-details-list-wrap"
    >
      <Stack
        className="collapse-wrap list-collapse"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <AdditionalDetailContent />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Hire purchase regulated - advance over £60,260 only
                </>
              ),
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="IcView" />}
                  />
                </>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      <Stack
        className="collapse-wrap list-collapse"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <AdditionalDetailContent />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Hire Corporate Guarantee - Agreement Specific (At credit's
                  request)purchase regulated - advance over £60,260 only
                </>
              ),
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="IcView" />}
                  />
                </>
              ),
              key: "string",
              isExpanded: false,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
    </AdditionalDetailsListWrap>
  );
};

export default AdditionalDetailList;
