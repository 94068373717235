import { PageHeader } from "@components/page-header";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

export const ComplianceDocumentPageHeader = ({
  proposalName,
}: {
  proposalName: string;
}) => {
  const params = useParams<{ proposalId?: string }>();

  return (
    <PageHeader
      title={proposalName ?? "-"}
      referenceNumber={params?.proposalId ?? "-"}
      hasSeperator
    />
  );
};
