import { useMutation } from "react-query";
import { uploadBase64 } from "@services";

const useUploadDocs = () => {
  return useMutation(uploadDocs);
};

const uploadDocs = async (payload: any) => {
  const response = await uploadBase64(payload);
  return response;
};

export default useUploadDocs;
