import { dmsAPI } from "./instances";
import {
  CHANGE_PROPOSAL_STATUS,
  CONVERT_QUOTATION_TO_PROPOSAL,
  DASHBOARD_ANALYTICS,
  DMS_GET_DEALERS,
  GET_DIARY_COMMENTS,
  GET_ORDER_STATUS_HISTORY,
  GET_ORDER_WORKQUEUE,
  GET_PROPOSAL_BY_REFERENCE_NUMBER,
  GET_QUOTATION_BY_IDENTIFIER,
  SAVE_DIARY_COMMENT,
  SAVE_UPDATE_PROPOSAL,
  UPDATE_QUOTATION,
  GENERATE_DOCUMENTS,
  SIGNATORIES,
  SEND_EVENT_NOTIFICATION,
  GET_GENERATED_DOCUMENTS,
  DOWNLOAD_DOCUMENT_BY_EVENT,
  SUBMIT_PROPOPSAL,
} from "@helpers/endpoints";
import { getEntityTypeDealer, getQueryParamsString } from "@helpers/utils";
import {
  ChangeProposalStatusReq,
  DownloadParams,
  GetQuotationResponse,
  TProposalPayloadReq,
  SubmitProposalPayload,
  TOrderStatusHistoryReq,
  TCommentsDiaryDetails,
  TSignatoryForm,
  EmailNotificationPayload,
  SignatoryResponse,
  DealerProfile,
} from "@_types";
import { DocumentTypes, Events, ProposalStakeholders } from "@helpers/enum";

export const getQuotationByIdentifier = async (
  identifier: string
): Promise<GetQuotationResponse> => {
  return dmsAPI
    .get(GET_QUOTATION_BY_IDENTIFIER.replace(":identifier", identifier))
    .then((response) => {
      return response.data;
    });
};

export const getOrderWorkqueue = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = GET_ORDER_WORKQUEUE;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }

  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const getProposalByReferenceNumber = async (referenceNumber: string) => {
  return dmsAPI
    .get(
      GET_PROPOSAL_BY_REFERENCE_NUMBER.replace(
        ":referenceNumber",
        referenceNumber
      )
    )
    .then((response) => {
      return response.data;
    });
};

export const getOrderStatusHistory = async (
  payload: TOrderStatusHistoryReq
) => {
  return dmsAPI
    .post(GET_ORDER_STATUS_HISTORY, payload)
    .then((response) => response.data);
};

export const convertQuotationToProposal = async (identifier: string) => {
  const response = await dmsAPI.patch(
    CONVERT_QUOTATION_TO_PROPOSAL.replace(":identifier", identifier),
    { identifier: identifier }
  );
  if (response.status === 200) {
    const entityType = getEntityTypeDealer();
    if (entityType === ProposalStakeholders.DEALER) {
      const dealerAssociationResponse = await getDmsDealers(
        getEntityTypeDealer()
      );
      if (dealerAssociationResponse) {
        const dealer = dealerAssociationResponse?.[0];
        let payload = {
          email: dealer?.email,
          position: dealer?.position,
          name: dealer?.dealer_name,
          order_identifier: response.data.identifier,
          contact_number: dealer?.contact_number,
          role: ProposalStakeholders.DEALER,
          stakeholder_reference_id: [dealer?.id],
        };
        await saveSignatory(payload);
      }
    }
  }
  return response;
};

export const saveProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .post(SAVE_UPDATE_PROPOSAL, payload)
    .then((response) => response.data);
};

export const submitProposal = async ({
  payload,
  identifier,
}: SubmitProposalPayload) => {
  let endpoint = SUBMIT_PROPOPSAL;
  if (identifier) {
    endpoint = endpoint + `?identifier=${identifier}`;
  }
  return dmsAPI.post(endpoint, payload).then((response) => response.data);
};

export const updateProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .patch(`${SAVE_UPDATE_PROPOSAL}?identifier=${payload.identifier}`, payload)
    .then((response) => response.data);
};

export const saveQuotation = async (payload: any) => {
  return dmsAPI.post(`${"order/quick_quotation"}`, payload);
};

export const updateQuotationTwo = async (payload: any) => {
  const queryParamsStr = getQueryParamsString({
    identifier: payload.identifier,
  });

  return dmsAPI.patch(UPDATE_QUOTATION + queryParamsStr, payload);
};

export const getProposalForDashboard = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = DASHBOARD_ANALYTICS;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const getSignatories = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
): Promise<SignatoryResponse[]> => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = SIGNATORIES;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const changeProposalStatus = async (
  requestBody: ChangeProposalStatusReq
) => {
  return dmsAPI.patch(CHANGE_PROPOSAL_STATUS, requestBody);
};

export const saveDiaryComment = async (payload: TCommentsDiaryDetails) => {
  return dmsAPI
    .post(SAVE_DIARY_COMMENT, payload)
    .then((response) => response.data);
};

export const saveSignatory = async (payload: TSignatoryForm) => {
  return dmsAPI.post(SIGNATORIES, payload).then((response) => response.data);
};

export const editSignatory = async (
  identifier: string,
  payload: TSignatoryForm
) => {
  return dmsAPI
    .patch(`${SIGNATORIES}?identifier=${identifier}`, payload)
    .then((response) => response.data);
};

export const deleteSignatory = async (identifier: string) => {
  return dmsAPI
    .delete(`${SIGNATORIES}?identifier=${identifier}`)
    .then((response) => response.data);
};

export const getDiaryComments = async (
  orderReferenceId: string
): Promise<TCommentsDiaryDetails[]> => {
  let endpointUrl = GET_DIARY_COMMENTS;
  const searchParams = { order_reference_id: orderReferenceId };
  const queryParamsStr = getQueryParamsString(searchParams);
  if (!!queryParamsStr) {
    endpointUrl += queryParamsStr;
  }

  return dmsAPI.get(endpointUrl).then((response) => {
    return response.data.result;
  });
};

export const generateDocuments = async ({
  payload,
  customerType,
  financeType,
}: {
  payload: any;
  customerType?: string;
  financeType?: string;
}) => {
  const params = new URLSearchParams();
  if (customerType) {
    params.append("customer_type", customerType);
  }
  if (financeType) {
    params.append("finance_type", financeType);
  }
  return dmsAPI.post(
    `${GENERATE_DOCUMENTS}/${
      Events.GENERATE_COMPLIANCE_DOCUMENTS
    }?${params.toString()}`,
    payload
  );
};

export const sendEmailNotification = async ({
  eventName,
  payload,
}: {
  eventName: Events;
  payload: EmailNotificationPayload;
}) => {
  return dmsAPI
    .post(SEND_EVENT_NOTIFICATION.replace(":eventName", eventName), payload)
    .then((response) => response.data);
};

export const getDmsDealers = async (
  entityType: string
): Promise<DealerProfile[]> => {
  return dmsAPI
    .get(`${DMS_GET_DEALERS}?entity_type=${entityType}`)
    .then((response) => {
      return response.data;
    });
};

export const getGeneratedDocuments = async (
  referenceId: string,
  proposalId: string
): Promise<any> => {
  return await dmsAPI
    .get(
      `${GET_GENERATED_DOCUMENTS}?reference_id=${referenceId}&external_order_id=${proposalId}&document_type=${DocumentTypes.COMPLIANCE}`
    )
    .then((res) => res.data);
};

export const downloadDocumentByEvent = async (params: {
  eventName: Events;
  payload: DownloadParams;
}): Promise<string[]> => {
  const response = await dmsAPI.post(
    DOWNLOAD_DOCUMENT_BY_EVENT.replace(":eventName", params.eventName),
    params.payload
  );
  return response.data;
};
