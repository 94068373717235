import axios from "axios";
import {
  errorResponseInterceptor,
  requestIntercepter,
  successResponseInterceptor,
} from "./interceptors";

const hubexAPI = axios.create({ baseURL: process.env.HUBEX_BASE_URL });

hubexAPI.interceptors.request.use(requestIntercepter);
hubexAPI.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
export default hubexAPI;
