import { useQuery, UseQueryResult } from "react-query";
import { queryKeyFactory } from "@helpers/query";
import { getOrderWorkqueue } from "@services";
import { TSortParams, TWorkQueueResponse } from "@_types";
import { getParamsWithoutPagination } from "@helpers/utils";

const useGetOrders = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>,
  exportAllData: boolean = false
): UseQueryResult<TWorkQueueResponse<ReturnType>> => {
  return useQuery<TWorkQueueResponse<ReturnType>>({
    queryKey: queryKeyFactory.getOrders(
      searchParams,
      sortParams,
      exportAllData
    ),
    queryFn: async () => {
      const params = getParamsWithoutPagination(searchParams, exportAllData);
      return await getOrderWorkqueue(params, sortParams);
    },
  });
};

export default useGetOrders;
