import { Accordion, Box, Button, Checkbox, Switch } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AddDocumentWrap } from "./add-document.style";
import { Stack } from "@components/stack";

export const AddDocument = () => {
  const theme = useTheme();
  return (
    <AddDocumentWrap theme={theme} className="add-document-wrap">
      <Stack
        className="collapse-wrap desktop-disable"
        paddingMd={3}
        paddingXs={3}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <Box theme={theme} className="checklist-wrap">
                    <Box
                      theme={theme}
                      className="checklist-item"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Box theme={theme} flexGrow={1}>
                        <Checkbox
                          theme={theme}
                          label={"Hire purchase - regulated fixed rate"}
                        />
                      </Box>
                    </Box>
                    <Box
                      theme={theme}
                      className="checklist-item"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Box theme={theme} flexGrow={1}>
                        <Checkbox
                          theme={theme}
                          checkBoxChecked
                          label={
                            "Hire purchase regulated - advance over £60,260 only"
                          }
                        />
                      </Box>
                      <Box theme={theme}>
                        <Switch
                          theme={theme}
                          label="E-Signature"
                          switchEnabled
                          varient="basic"
                        />
                      </Box>
                    </Box>
                    <Box
                      theme={theme}
                      className="checklist-item"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Box theme={theme} flexGrow={1}>
                        <Checkbox
                          theme={theme}
                          label={"Hire purchase - unregulated fixed rate"}
                        />
                      </Box>
                    </Box>
                    <Box
                      theme={theme}
                      className="checklist-item"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Box theme={theme} flexGrow={1}>
                        <Checkbox
                          theme={theme}
                          label={"Hire purchase - unregulated variable rate"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              ),
              title: "Mandatory Documents",
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      <Stack
        title="Optional Documents"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Box theme={theme} className="checklist-wrap" mt={3}>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={
                  "Director Personal Guarantee - Agreement Specific (At credits request)"
                }
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                checkBoxChecked
                label={
                  "Hire Corporate Guarantee - Agreement Specific (At credits request)purchase regulated - advance over £60,260 only"
                }
              />
            </Box>
            <Box theme={theme}>
              <Switch
                theme={theme}
                label="E-Signature"
                switchEnabled
                varient="basic"
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                checkBoxChecked
                theme={theme}
                label={"Sample Document Name"}
              />
            </Box>
            <Box theme={theme}>
              <Switch theme={theme} label="Wet Signature" varient="basic" />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={"Corporate Guarantee - Blanket (At credits request)"}
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={"Cross Company Guarantee - Blanket (At credits request)"}
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={"⁠Purchase Agreement for Sale HP back"}
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={
                  "Purchase Agreement for Sale HP back - Non Ltd Cust. Only"
                }
              />
            </Box>
          </Box>
          <Box
            theme={theme}
            className="checklist-item"
            display={"flex"}
            alignItems={"center"}
          >
            <Box theme={theme} flexGrow={1}>
              <Checkbox
                theme={theme}
                label={"⁠Hirer Declaration (At credits request)"}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </AddDocumentWrap>
  );
};
