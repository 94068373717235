import { useMutation, UseMutationOptions } from "react-query";
import { updateComparison } from "@services";
import { QuotationComparisonRes, UpdateQuotationComparison } from "@_types";
import { AxiosError } from "axios";

type UseUpdateComparisonOptions = Omit<
  UseMutationOptions<
    QuotationComparisonRes,
    AxiosError,
    {
      referenceNumber: string;
      payload: UpdateQuotationComparison;
    }
  >,
  "mutationFn" | "mutationKey"
>;

const useUpdateComparison = (options: UseUpdateComparisonOptions = {}) => {
  return useMutation({
    mutationFn: updateComparison,
    ...options,
  });
};

export default useUpdateComparison;
